import { useState, useEffect } from 'react'
import CreateContainer from "containers/create-container";
import FormInput from "components/form-input";
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { request } from 'utils/request';
import { toTitleCase } from 'utils/helper';

const DetailPerizinan = () => {
    const [loading, setLoading] = useState(false);
    const [suratIzin, setSuratIzin] = useState(null);
    const params = useParams()
    const { id } = params
    const navigate = useNavigate()

    const {
        control,
        register,
        formState: { errors },
        handleSubmit,
        setValue
      } = useForm();

    const getIzinData = async () => {
        setLoading(true)
        try {
            const { data: { data } } = await request.get(`/api/perizinan/${id}`)
            if (data) {
                const {
                    alasan,
                    jeniz_perizinan,
                    surat_izin,
                    tanggal_mulai,
                    tanggal_selesai,
                } = data
                if (data?.surat_izin) setSuratIzin(data?.surat_izin)
                setValue('nama', data?.karyawan?.nama)
                setValue('bidang', toTitleCase(data?.karyawan?.bidang))
                setValue('reason', alasan)
                setValue('status', jeniz_perizinan)
                setValue('suratIzin', surat_izin)
                setValue('date', `${tanggal_mulai} - ${tanggal_selesai}`)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (id) getIzinData()
    },[id])

    return (
        <CreateContainer
            loading={loading}
            title="Detail Izin"
            onSubmit={handleSubmit()}
            unsubmitable={true}
            loadingText="mengubah izin..."
            additionalButton={{
                title: 'Kembali',
                type: 'btn-danger',
                icon: 'bi bi-chevron-left',
                onClick: () => navigate(-1)
            }}
        >
            <FormInput
                required
                name="nama"
                errors={errors}
                control={control}
                register={register}
                label="Nama Karyawan"
                disabled={true}
            />

            <FormInput
                required
                name="bidang"
                errors={errors}
                control={control}
                register={register}
                label="Bidang"
                disabled={true}
            />

            <FormInput
                required
                name="date"
                errors={errors}
                control={control}
                register={register}
                label="Tanggal Izin"
                placeholder="Pilih tanggal izin"
                inputType="date"
                errorMessage="Tanggal izin harus dipilih"
                options={{
                    mode: 'range',
                    minDate: 'today',
                }}
                disabled={true}
            />

            <FormInput
                required
                name="reason"
                errors={errors}
                control={control}
                register={register}
                label="Alasan Izin"
                placeholder="Masukkan alasan izin"
                inputType="textarea"
                errorMessage="Alasan izin harus diisi"
                disabled={true}
            />

            <div style={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'space-between',
                flexDirection: 'column',
            }}>
                <label className="text-md text-dark" style={{ fontWeight: 500, marginBottom: '20px' }}>Bukti Surat Izin</label>
                <img
                    src={suratIzin}
                    alt="Bukti Surat Izin"
                />
            </div>
        </CreateContainer>
    )
}

export default DetailPerizinan;