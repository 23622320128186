import { useState } from "react";
import FormInput from "components/form-input";
import CreateContainer from "containers/create-container";
import { useForm } from "react-hook-form";
import { request } from "utils/request";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const CreatePeriodeGaji = () => {
    const [loading,setLoading] = useState(false)
    const navigate = useNavigate()
    const {
        control,
        formState: { errors },
        handleSubmit,
        register
      } = useForm();

    const handleCreatePeriodeGaji = (values) => {
        if (!values.periode) return
        const [start,end] = values.periode.split(' - ')
        setLoading(true)
        request.post(`/api/periode-gaji`,{
            nama: values.name,
            tanggal_mulai: start,
            tanggal_selesai: end
        })
        .then((res) => {
            toast.success('Berhasil membuat periode gaji')
            navigate('/penggajian')
        })
        .catch((err) => {
            toast.error('Gagal menambahkan periode gaji')
        })
        .finally(() => {
            setLoading(false)
        })
    }

    return (
        <CreateContainer
            loading={loading}
            title="Tambah Periode Gaji"
            onSubmit={handleSubmit(handleCreatePeriodeGaji)}
        >
            <FormInput
                required
                label="Periode Gaji"
                name="periode"
                inputType="date"
                errors={errors}
                control={control}
                placeholder="Pilih Periode"
                errorMessage="Waktu tidak boleh kosong!"
                options={{
                    mode: 'range'
                }}
            />
        </CreateContainer>
    )
}

export default CreatePeriodeGaji;