import { useState, useMemo } from "react";
import DynamicTable from "components/dynamic-table";
import PageHeader from "components/page-header";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { request } from "utils/request";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setTriggerTable } from "redux/actions";
import ModalDelete from "components/modal-delete";
import FilterPerizinan from "components/perizinan/filterPerizinan";
import { useNavigate } from "react-router-dom";
import { toTitleCase } from "utils/helper";
import format from "date-fns/format";
import useHasPermission from "hooks/useHasPermission";
import CheckPermission from "components/CheckPermission";

const ListIzin = () => {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataTablePick, setDataTablePick] = useState(null);
  const [filterParams, setFilterParams] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const newQueryParams = useMemo(() => {
    return Object.keys(filterParams).reduce((cur, acc) => {
      if (filterParams[acc]) {
        return cur + `&${acc}=${filterParams[acc]}`;
      }
      return cur;
    }, "");
  }, [filterParams]);

  const onDeleteData = () => {
    setLoading(true);
    request
      .delete(`/api/perizinan/${dataTablePick?.id}`)
      .then((res) => {
        toast.success(`${res.data.message}`, {
          duration: 5000,
        });
        dispatch(setTriggerTable(true));
        setIsModalOpen(false);
      })

      .finally(() => setLoading(false));
  };

  const tableColumns = [
    {
      Header: "NO",
      accessor: "nomor",
      width: "20px",
    },
    {
      Header: "Nama",
      accessor: "karyawan.nama",
      Cell: ({ row }) => {
        return toTitleCase(row.original.karyawan.nama);
      },
    },
    {
      Header: "Bidang",
      accessor: "karyawan.bidang",
      Cell: ({ row }) => toTitleCase(row.original.karyawan.bidang),
    },
    {
      Header: "Tanggal Izin",
      accessor: (row) => {
        return `${format(new Date(row.tanggal_mulai), "dd-MM-yyyy")} - ${format(
          new Date(row.tanggal_selesai),
          "dd-MM-yyyy"
        )}`;
      },
    },
    {
      Header: "Alasan",
      accessor: "alasan",
      Cell: ({ row }) => {
        return toTitleCase(row.original.alasan);
      },
    },
    {
      Header: "Aksi",
      Cell: ({ row }) => {
        return (
          <td className="text-end gap-3">
            <CheckPermission permission="view-perizinan">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip">Lihat</Tooltip>}
              >
                <button
                  type="button"
                  className="btn btn-sm btn-square btn-neutral text-warning-hover ms-2"
                  onClick={() => {
                    navigate(`/perizinan/edit/${row.original.id}`);
                  }}
                >
                  <i className="bi bi-eye"></i>
                </button>
              </OverlayTrigger>
            </CheckPermission>
            <CheckPermission permission="delete-perizinan">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip">Hapus</Tooltip>}
              >
                <button
                  type="button"
                  className="btn btn-sm btn-square btn-neutral text-danger-hover ms-2"
                  onClick={() => {
                    setIsModalOpen(true);
                    setDataTablePick(row.original);
                  }}
                >
                  <i className="bi bi-trash"></i>
                </button>
              </OverlayTrigger>
            </CheckPermission>
          </td>
        );
      },
    },
  ];

  const mayCreateIzin = useHasPermission("create-perizinan");

  return (
    <>
      <PageHeader
        title="Daftar Izin"
        {...(mayCreateIzin && {
          to: "/perizinan/add",
        })}
      />

      <FilterPerizinan setFilterParams={setFilterParams} />

      <DynamicTable
        title="Izin"
        columns={tableColumns}
        url="/api/perizinan"
        newQueryParams={newQueryParams}
      />

      <ModalDelete
        loading={loading}
        show={isModalOpen}
        onClick={onDeleteData}
        titleAction="Hapus"
        loadingText="menghapus Izin..."
        title={`Apakah anda yakin ingin menghapus izin ${dataTablePick?.karyawan?.nama}?`}
        onHide={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default ListIzin;
