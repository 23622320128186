import { useState, useEffect, useMemo } from "react";
import DynamicTable from "components/dynamic-table";
import PageHeaderBtns from "components/page-header-btns";
import { toast } from "react-hot-toast";
import { request } from "utils/request";
import { format } from "date-fns";
import { toRupiah } from "utils/helper";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ModalDelete from "components/modal-delete";
import { useDispatch } from "react-redux";
import { setTriggerTable } from "redux/actions";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import FormInput from "components/form-input";
import useHasPermission from "hooks/useHasPermission";
import CheckPermission from "components/CheckPermission";

const DateRangeFilter = ({ onDateChange = () => {} }) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useForm();
  const filterFields = watch();
  const { tanggal } = filterFields;

  const additionalQuery = useMemo(() => {
    const from = tanggal?.split(" - ")[0]
      ? `&from=${tanggal?.split(" - ")[0]}`
      : null;
    const to = tanggal?.split(" - ")[1]
      ? `&to=${tanggal?.split(" - ")[1]}`
      : null;
    if (!from && !to) return null;
    return `${from ? from : ""}${to ? to : ""}`;
  }, [tanggal]);

  useEffect(() => {
    onDateChange(additionalQuery);
  }, [additionalQuery, onDateChange]);

  return (
    <FormInput
      name="tanggal"
      inputType="date"
      label="Tanggal"
      placeholder="Pilih tanggal"
      errors={errors}
      control={control}
      errorMessage="Waktu tidak boleh kosong!"
      options={{
        mode: "range",
      }}
      isClearable={true}
      mb={0}
      containerStyle={{
        width: "402px",
      }}
    />
  );
};

const LaporanPendapatanToilet = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [availableToilet, setAvailableToilet] = useState([]);
  const [tablePick, setTablePick] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [lokasiToilet, setLokasiToilet] = useState([]);
  const [additionalQuery, setAdditionalQuery] = useState("");

  const mayAccessLokasiToilet = useHasPermission("access-lokasi-toilet");
  const mayCreateUsahaToilet = useHasPermission("create-hasil-usaha-toilet");

  const btns = useMemo(() => {
    return [
      {
        name: "Lokasi Toilet",
        path: "/hasil-usaha/toilet/lokasi",
        url: "",
        icon: "bi bi-badge-wc",
        newTab: false,
        visible: mayAccessLokasiToilet,
      },
      {
        name: "Tambah",
        path: "/hasil-usaha/toilet/tambah",
        url: "",
        icon: "bi bi-plus-square-dotted",
        newTab: false,
        visible: mayCreateUsahaToilet,
      },
    ];
  }, [mayAccessLokasiToilet, mayCreateUsahaToilet]);

  const lokasiToiletCols = useMemo(() => {
    if (availableToilet.length === 0 || lokasiToilet.length === 0) return [];
    return lokasiToilet
      .map((id) => {
        return availableToilet.find((toilet) => toilet.id === id);
      })
      .filter((item) => !!item);
  }, [availableToilet, lokasiToilet]);

  const columns = useMemo(() => {
    return [
      {
        Header: "No",
        accessor: "nomor",
      },
      {
        Header: "Tanggal",
        accessor: "tanggal",
        Cell: ({ row }) =>
          format(new Date(row.original.tanggal), "dd MMMM yyyy"),
      },
      {
        Header: "Lokasi Toilet",
        accessor: "location",
        columns: [
          ...lokasiToiletCols.map((toilet) => {
            return {
              Header: toilet.nama,
              accessor: toilet.nama,
              Cell: ({ row }) => {
                return toRupiah(
                  row?.original?.pendapatan?.find(
                    (item) => item.lokasi_toilet_id === toilet.id
                  )?.pendapatan ?? 0
                );
              },
            };
          }),
        ],
      },
      {
        Header: "Aksi",
        accessor: "aksi",
        Cell: ({ row }) => {
          return (
            <>
              <CheckPermission permission="edit-hasil-usaha-toilet">
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="button-tooltip">Edit</Tooltip>}
                >
                  <button
                    type="button"
                    className="btn btn-sm btn-square btn-neutral text-warning-hover ms-2"
                    onClick={() => {
                      navigate(`/hasil-usaha/toilet/${row.original.id}/edit`);
                    }}
                  >
                    <i className="bi bi-pencil"></i>
                  </button>
                </OverlayTrigger>
              </CheckPermission>
              <CheckPermission permission="delete-hasil-usaha-toilet">
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="button-tooltip">Hapus</Tooltip>}
                >
                  <button
                    type="button"
                    className="btn btn-sm btn-square btn-neutral text-warning-hover ms-2"
                    onClick={() => {
                      setDeleteModalVisible(true);
                      setTablePick(row.original);
                    }}
                  >
                    <i className="bi bi-trash"></i>
                  </button>
                </OverlayTrigger>
              </CheckPermission>
            </>
          );
        },
      },
    ];
  }, [lokasiToiletCols]);

  const onTableDataChange = (newTableData) => {
    const newToiletData = newTableData
      .map((row) => row.pendapatan)
      .reduce((acc, cur) => [...acc, ...cur], [])
      .reduce((acc, cur) => {
        if (!acc[cur.lokasi_toilet_id])
          return {
            ...acc,
            [cur.lokasi_toilet_id]: cur,
          };
        return acc;
      }, {});
    setLokasiToilet(
      Object.values(newToiletData).map((item) => item.lokasi_toilet_id)
    );
  };

  const onDeleteData = () => {
    setLoading(true);
    request
      .delete(`/api/usaha-toilet/${tablePick?.id}`)
      .then((res) => {
        toast.success(`${res.data.message}`, {
          duration: 5000,
        });
        dispatch(setTriggerTable(true));
        setDeleteModalVisible(false);
      })
      .finally(() => setLoading(false));
  };

  const fetchLokasiToilet = async () => {
    setLoading(true);
    request
      .get("/api/lokasi-toilet?per_page=100")
      .then((res) => {
        setAvailableToilet(res?.data?.data);
      })
      .catch((err) => {
        toast.danger("Gagal mengambil data toilet");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchLokasiToilet();
  }, []);

  return (
    <>
      <PageHeaderBtns title="Laporan Pendapatan Toilet per Hari" btns={btns} />
      <DateRangeFilter onDateChange={setAdditionalQuery} />
      <DynamicTable
        url="/api/usaha-toilet"
        columns={columns}
        onTableDataChange={onTableDataChange}
        withSearch={false}
        {...(additionalQuery && { newQueryParams: additionalQuery })}
      />
      <ModalDelete
        loading={loading}
        show={deleteModalVisible}
        onClick={onDeleteData}
        titleAction="Hapus"
        loadingText="Menghapus lokasi toilet..."
        title={`Apakah anda yakin ingin menghapus pendapatan toilet pada ${tablePick?.tanggal}?`}
        onHide={() => setDeleteModalVisible(false)}
      />
    </>
  );
};

export default LaporanPendapatanToilet;
