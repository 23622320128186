import FormInput from "components/form-input";
import CreateContainer from "containers/create-container";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { request } from "utils/request";

const pengunjungPricing = [
  {
    name: "pengunjung_domestik",
    label: "Domestik",
  },
  {
    name: "pengunjung_mancanegara",
    label: "Manca",
  },
  {
    name: "pengunjung_asuransi",
    label: "Asuransi",
  },
];

const kendaraanPricing = [
  {
    name: "parkir_motor",
    label: "Motor",
  },
  {
    name: "parkir_mobil",
    label: "Mobil",
  },
  {
    name: "parkir_minibus",
    label: "Minibus",
  },
  {
    name: "parkir_bus",
    label: "Bus",
  },
];

const SettingHargaTiket = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  const onSubmit = async (values) => {
    setLoading(true);
    const payload = Object.keys(values).reduce((acc, cur) => {
      acc[cur] = String(values[cur]).replace("Rp ", "").split(",").join("");
      return acc;
    }, {});
    request
      .post("/api/harga-tiket", payload)
      .then((res) => {
        toast.success("Berhasil menyimpan harga tiket");
        navigate("/hasil-usaha/tiketing/laporan-pendapatan");
      })
      .catch((err) => {
        toast.error("Gagal menyimpan harga tiket");
      });
  };

  const fetchDataTiket = async () => {
    setLoading(true);
    request
      .get("/api/harga-tiket")
      .then((res) => {
        const data = res.data.data[0];
        Object.keys(data).forEach((item) => {
          setValue(item, data[item]);
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchDataTiket();
  }, []);

  return (
    <CreateContainer
      title="Setting Harga Tiket"
      onSubmit={handleSubmit(onSubmit)}
      loading={loading}
      loadingText="Menyimpan harga tiket"
    >
      <div style={{ marginBottom: "30px" }}>
        <div
          style={{
            marginBottom: "11px",
            color: "#000000",
          }}
        >
          Pengunjung
        </div>
        <div
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "7px",
            border: "1px solid #DEDFE1",
            padding: "40px",
          }}
        >
          {pengunjungPricing.map((item) => {
            return (
              <FormInput
                required
                errors={errors}
                name={item.name}
                label={item.label}
                control={control}
                inputType="price"
                placeholder="Masukkan harga"
                errorMessage="Harga tidak boleh kosong!"
              />
            );
          })}
        </div>
      </div>
      <div style={{ marginBottom: "30px" }}>
        <div
          style={{
            marginBottom: "11px",
            color: "#000000",
          }}
        >
          Kendaraan
        </div>
        <div
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "7px",
            border: "1px solid #DEDFE1",
            padding: "40px",
          }}
        >
          {kendaraanPricing.map((item) => {
            return (
              <FormInput
                required
                errors={errors}
                name={item.name}
                label={item.label}
                control={control}
                inputType="price"
                placeholder="Masukkan harga"
                errorMessage="Harga tidak boleh kosong!"
              />
            );
          })}
        </div>
      </div>
    </CreateContainer>
  );
};

export default SettingHargaTiket;
