import { FormControl } from "react-bootstrap";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";

const MaskInput = ({
  mask,
  placeholder,
  id,
  onChange,
  defaultValue,
  disabled,
  value,
}) => (
  <MaskedInput
    mask={mask}
    placeholder={placeholder}
    id={id}
    onChange={onChange}
    value={value}
    disabled={disabled}
    defaultValue={defaultValue}
    render={(ref, props) => <FormControl ref={ref} {...props} />}
  />
);

MaskInput.propTypes = {
  mask: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default MaskInput;
