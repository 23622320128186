import CreateContainer from "containers/create-container";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import FormInput from "components/form-input";
import { request } from "utils/request";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

const EditBiayaPengeluaran = () => {
    const { id } = useParams()
    const [loading,setLoading] = useState(false)
    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        setValue
    } = useForm()

    const fetchDataPengeluaran = async () => {
        setLoading(true)
        request.get(`/api/pengeluaran-reservasi/${id}`)
        .then((res) => {
            setValue('nama', res?.data?.data?.nama)
            setValue('harga', res?.data?.data?.harga)
        })
        .catch((err) => {
            console.log(err)
        })
        .finally(() => setLoading(false))
    }

    const onSubmitBiaya = async (values) => {
        setLoading(true)
        request.patch(`/api/pengeluaran-reservasi/${id}`, {
            nama: values.nama,
            harga: String(values?.harga).replace('Rp ', '').split(',').join('')
        })
        .then(() => {
            toast.success('Berhasil menambahkan biaya')
            navigate('/reservasi/biaya-pengeluaran')
        })
        .catch(err => {
            console.log(err)
        })
        .finally(() => setLoading(false))
    }

    useEffect(() => {
        fetchDataPengeluaran()
    },[])

    return (
        <CreateContainer
            title="Ubah Biaya Pengeluaran"
            loading={loading}
            loadingText="Menambahkan"
            onSubmit={handleSubmit(onSubmitBiaya)}
        >
            <FormInput
                required
                label="Nama biaya"
                name="nama"
                type="text"
                errors={errors}
                register={register}
                placeholder="masukkan nama biaya"
                errorMessage="Nama biaya tidak boleh kosong!"
            />
            <FormInput
                required
                errors={errors}
                name="harga"
                label="Harga"
                control={control}
                inputType="price"
                placeholder="Masukkan harga"
                errorMessage="Harga tidak boleh kosong!"
            />
        </CreateContainer>
    )
}

export default EditBiayaPengeluaran;