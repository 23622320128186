import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { request } from "utils/request";
import { setTriggerTable } from "redux/actions";
import DynamicTable from "components/dynamic-table";
import PageHeader from "components/page-header";
import ModalDelete from "components/modal-delete";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ModalShow from "components/perizinan/modal-show";
import { toTitleCase } from "utils/helper";
import useHasPermission from "hooks/useHasPermission";
import CheckPermission from "components/CheckPermission";

const ListKaryawan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataTablePick, setDataTablePick] = useState(null);
  const [isModalShowOpen, setIsModalShowOpen] = useState(false);

  const onDeleteData = () => {
    setLoading(true);
    request
      .delete(`/api/karyawan/${dataTablePick?.id}`)
      .then((res) => {
        toast.success(`${res.data.message}`, {
          duration: 5000,
        });
        dispatch(setTriggerTable(true));
        setIsModalOpen(false);
      })

      .finally(() => setLoading(false));
  };

  const columnsTable = [
    {
      Header: "NO",
      accessor: "nomor",
      width: "20px",
    },
    {
      Header: "NAMA",
      accessor: "nama",
    },
    {
      Header: "BIDANG",
      accessor: "bidang",
      Cell: ({ row }) => {
        if (row?.original?.bidang?.length <= 3)
          return String(row?.original?.bidang).toUpperCase();
        return toTitleCase(row?.original?.bidang);
      },
    },
    {
      Header: "JABATAN",
      accessor: "jabatan",
      Cell: ({ row }) => {
        if (row?.original?.jabatan_label?.length <= 3)
          return String(row?.original?.jabatan_label).toUpperCase();
        return toTitleCase(row?.original?.jabatan_label);
      },
    },
    {
      Header: "EMAIL",
      accessor: "email",
    },
    {
      Header: "AKSI",
      accessor: "aksi",
      Cell: ({ row }) => (
        <td style={{ display: "flex", justifyContent: "center" }}>
          <CheckPermission permission="edit-karyawan">
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="button-tooltip">Ubah</Tooltip>}
            >
              <button
                type="button"
                className="btn btn-sm btn-square btn-neutral text-warning-hover ms-2"
                onClick={() => {
                  navigate(`/karyawan/ubah-karyawan/${row.original?.id}`);
                }}
              >
                <i className="bi bi-pencil"></i>
              </button>
            </OverlayTrigger>
          </CheckPermission>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="button-tooltip">Lihat</Tooltip>}
          >
            <button
              type="button"
              className="btn btn-sm btn-square btn-neutral text-warning-hover ms-2"
              onClick={() => {
                setIsModalShowOpen(true);
                setDataTablePick(row.original);
              }}
            >
              <i className="bi bi-exclamation-circle"></i>
            </button>
          </OverlayTrigger>

          {/* <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="button-tooltip">Reset Password</Tooltip>
            }
          >
            <button
              type="button"
              className="btn btn-sm btn-square btn-neutral text-danger-hover ms-2"
              onClick={() => {
                setIsModalOpen(true);
                setDataTablePick(row.original);
              }}
            >
              <i className="bi bi-key"></i>
            </button>
          </OverlayTrigger> */}
          <CheckPermission permission="delete-karyawan">
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="button-tooltip">Hapus</Tooltip>}
            >
              <button
                type="button"
                className="btn btn-sm btn-square btn-neutral text-danger-hover ms-2"
                onClick={() => {
                  setIsModalOpen(true);
                  setDataTablePick(row.original);
                }}
              >
                <i className="bi bi-trash"></i>
              </button>
            </OverlayTrigger>
          </CheckPermission>
        </td>
      ),
    },
  ];

  const mayCreateKaryawan = useHasPermission("create-karyawan");

  return (
    <>
      <PageHeader
        title="Daftar Karyawan"
        {...(mayCreateKaryawan && {
          to: "/karyawan/tambah-karyawan",
        })}
      />
      <DynamicTable
        title="Karyawan"
        columns={columnsTable}
        url="/api/karyawan"
      />

      <ModalDelete
        loading={loading}
        show={isModalOpen}
        onClick={onDeleteData}
        titleAction="Hapus"
        loadingText="menghapus karyawan..."
        title={`Apakah anda yakin ingin menghapus karyawan ${dataTablePick?.nama}?`}
        onHide={() => setIsModalOpen(false)}
      />

      <ModalShow
        loading={loading}
        show={isModalShowOpen}
        data={dataTablePick}
        onHide={() => setIsModalShowOpen(false)}
      />
    </>
  );
};

export default ListKaryawan;
