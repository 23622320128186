import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import ImageLoader from "./image-loader";

const ImageOverlay = ({
  image,
  title,
  withAction,
  editLink,
  onDelete,
  handleClickImage,
}) => {
  return (
    <div className="breksi-image-overlay-content">
      <div
        className="breksi-image-overlay-content-overlay"
        onClick={handleClickImage}
      ></div>
      <ImageLoader
        className="breksi-image-overlay-content-image"
        src={image}
        alt={title}
        width="100%"
        height="300px"
      />
      <div className="breksi-image-overlay-content-details fadeIn-top">
        <h3>{title}</h3>
        {withAction && (
          <div className="d-flex gap-3 justify-content-center align-items-center">
            <button
              type="button"
              onClick={onDelete}
              className="btn btn-sm btn-square btn-danger ms-2"
            >
              <i className="bi bi-trash"></i>
            </button>

            {editLink && (
              <Link
                to={editLink ?? "#"}
                className="btn btn-sm btn-square btn-warning ms-2"
              >
                <i className="bi bi-pencil"></i>
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

ImageOverlay.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  withAction: PropTypes.bool,
  editLink: PropTypes.string,
  onDelete: PropTypes.func,
};

export default ImageOverlay;
