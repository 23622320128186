import PageHeader from "components/page-header";
import { useRequest } from "hooks/useRequest";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import LightboxImage from "react-image-lightbox";
import toast from "react-hot-toast";
import { Navigate, useParams } from "react-router-dom";
import ImageOverlay from "components/image-overlay";
import { request } from "utils/request";
import ModalDelete from "components/modal-delete";
import NoDataIlustration from "assets/png/no-data.png";


const ListKatalogImage = () => {
    const { venueId, id } = useParams();
    const collection = "katalog";

    const [triggerRequest, setTriggerRequest] = useState(false);

    const {
        errors: errorsImages,
        loading: loadingImages,
        response: responseImages,
    } = useRequest(
        `/api/media?collection_name=${collection}&model=katalog&id=${id}`,
        {},
        collection || id,
        true,
        triggerRequest
    );
    
    const [dataPick, setDataPick] = useState(null);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
    const [galleryImage, setGalleryImage] = useState({
        photoIndex: 0,
        isOpen: false,
    });

    const isDetailVenueImages = responseImages?.data?.data?.media;
    const imagesGalleryDatas = isDetailVenueImages?.map((el) => el.original_url);

    const handleDeleteImage = () => {
        setLoadingDelete(true);
    
        request
            .post(`/api/media/${dataPick?.id}`)
            .then((res) => {
                toast.success(res.data.message);

                setIsOpenModalDelete(false);

                setTriggerRequest(true);

                setTimeout(() => {
                        setTriggerRequest(false);
                    }, 500);
                })
                .finally(() => {
                    setLoadingDelete(false);
                    setIsOpenModalDelete(false);
            });
        };
    
        if (errorsImages) {
            return <Navigate to="/data-master/daftar-venue" />;
        }

    return (
        <>
            <PageHeader
                backAction
                to={`/data-master/katalog-venue/${venueId}/images/${id}/tambah`}
                title={`Daftar Gambar Katalog`}
            />

            {loadingImages ? (
                <Spinner animation="border" className="d-block mt-3 mx-auto" />
            ) : isDetailVenueImages?.length > 0 ? (
                <>
                {galleryImage?.isOpen && (
                    <LightboxImage
                        mainSrc={imagesGalleryDatas[galleryImage.photoIndex]}
                        nextSrc={
                            imagesGalleryDatas[
                            (galleryImage.photoIndex + 1) % imagesGalleryDatas.length
                            ]
                        }
                        prevSrc={
                            imagesGalleryDatas[
                            (galleryImage.photoIndex + imagesGalleryDatas.length - 1) %
                                imagesGalleryDatas.length
                            ]
                        }
                        onCloseRequest={() =>
                            setGalleryImage((prevGalleryImage) => ({
                            ...prevGalleryImage,
                            isOpen: false,
                            }))
                        }
                        onMovePrevRequest={() =>
                            setGalleryImage((prevGalleryImage) => ({
                            ...prevGalleryImage,
                            photoIndex:
                                (galleryImage.photoIndex + imagesGalleryDatas.length - 1) %
                                imagesGalleryDatas.length,
                            }))
                        }
                        onMoveNextRequest={() =>
                            setGalleryImage((prevGalleryImage) => ({
                            ...prevGalleryImage,
                            photoIndex:
                                (galleryImage.photoIndex + 1) % imagesGalleryDatas.length,
                            }))
                        }
                    />
                )}

                <div className="row">
                    {isDetailVenueImages?.map((detailVenue, index) => (
                    <div className="col-xs-12 col-sm-6 col-md-4 mb-5" key={index}>
                        <ImageOverlay
                            withAction
                            title={detailVenue?.name}
                            image={detailVenue?.original_url}
                            handleClickImage={() =>
                                setGalleryImage((prevGalleryImage) => ({
                                photoIndex: index,
                                isOpen: true,
                                }))
                            }
                            onDelete={() => {
                                setDataPick(detailVenue);
                                setIsOpenModalDelete(true);
                            }}
                            />
                    </div>
                    ))}
                </div>
                </>
            ) : (
                <div className="mt-10">
                <img
                    width="200px"
                    className="d-block mx-auto"
                    src={NoDataIlustration}
                    alt="nodata"
                />
                </div>
            )}

            <ModalDelete
                titleAction="Hapus"
                loading={loadingDelete}
                show={isOpenModalDelete}
                onClick={handleDeleteImage}
                loadingText="menghapus gambar..."
                onHide={() => setIsOpenModalDelete(false)}
                title={`Apakah anda yakin ingin menghapus gambar ${dataPick?.name}`}
            />
        </>
    )
    
}

export default ListKatalogImage;