import DynamicTable from "components/dynamic-table";
import PageHeader from "components/page-header";
import { format } from "date-fns";
import { toRupiah } from "utils/helper";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { request } from "utils/request";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setTriggerTable } from "redux/actions";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMemo, useEffect, useState } from "react";
import FormInput from "components/form-input";
import useHasPermission from "hooks/useHasPermission";
import CheckPermission from "components/CheckPermission";
import ModalDelete from "components/modal-delete";

const DateRangeFilter = ({ onDateChange = () => {} }) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useForm();
  const filterFields = watch();
  const { tanggal } = filterFields;

  const additionalQuery = useMemo(() => {
    const from = tanggal?.split(" - ")[0]
      ? `&from=${tanggal?.split(" - ")[0]}`
      : null;
    const to = tanggal?.split(" - ")[1]
      ? `&to=${tanggal?.split(" - ")[1]}`
      : null;
    if (!from && !to) return null;
    return `${from ? from : ""}${to ? to : ""}`;
  }, [tanggal]);

  useEffect(() => {
    onDateChange(additionalQuery);
  }, [additionalQuery, onDateChange]);

  return (
    <FormInput
      name="tanggal"
      label="Periode"
      inputType="date"
      errors={errors}
      control={control}
      placeholder="Pilih periode"
      errorMessage="Waktu tidak boleh kosong!"
      options={{
        mode: "range",
      }}
      isClearable={true}
      mb={0}
      containerStyle={{
        width: "402px",
      }}
    />
  );
};

const RekapitulasiUsahaPenginapan = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [additionalQuery, setAdditionalQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [dataTablePick, setDataTablePick] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const confirmRekapitulasi = (data) => {
    request
      .patch(`api/periode-usaha-penginapan/${data.id}`, {
        konfirmasi: true,
      })
      .then((res) => {
        toast.success("Berhasil mengonfirmasi periode");
        dispatch(setTriggerTable(true));
      })
      .catch((err) => {
        console.log(err);
        toast.error("Gagal melakukan konfirmasi");
      });
  };

  const onDeleteData = () => {
    setLoading(true);
    request
      .delete(`/api/periode-usaha-penginapan/${dataTablePick?.id}`)
      .then((res) => {
        toast.success(`Periode usaha penginapan berhasil dihapus`, {
          duration: 5000,
        });
        dispatch(setTriggerTable(true));
        setIsModalOpen(false);
      })

      .finally(() => setLoading(false));
  };

  const columns = [
    {
      Header: "No",
      accessor: "nomor",
    },
    {
      Header: "Periode",
      accessor: "periode",
      Cell: ({ row }) => {
        return (
          <div style={{ textAlign: "center" }}>
            {`${format(
              new Date(row?.original?.tanggal_mulai),
              "dd MMM yyyy"
            )} - ${format(
              new Date(row?.original?.tanggal_selesai),
              "dd MMM yyyy"
            )}`}
          </div>
        );
      },
    },
    {
      Header: "Masuk",
      accessor: "masuk",
      Cell: ({ row }) => toRupiah(row?.original?.masuk),
    },
    {
      Header: "Keluar",
      accessor: "keluar",
      Cell: ({ row }) => toRupiah(row?.original?.keluar),
    },
    {
      Header: "Operasional (10%)",
      accessor: "operasional",
      Cell: ({ row }) => toRupiah(row?.original?.operasional),
    },
    {
      Header: "Total",
      accessor: "total",
      Cell: ({ row }) => toRupiah(row?.original?.total),
    },
    {
      Header: "Aksi",
      accessor: "aksi",
      Cell: ({ row }) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CheckPermission permission="konfirmasi-laporan">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip">Konfirmasi</Tooltip>}
              >
                <button
                  type="button"
                  className="btn btn-sm btn-square btn-neutral text-warning-hover ms-2"
                  onClick={() => {
                    if (row?.original?.konfirmasi) return;
                    confirmRekapitulasi(row?.original);
                  }}
                >
                  <i
                    className={`bi ${
                      !row?.original?.konfirmasi ? "bi-clock" : "bi-check2"
                    }`}
                  ></i>
                </button>
              </OverlayTrigger>
            </CheckPermission>
            {!row?.original?.konfirmasi && (
              <button
                type="button"
                className="btn btn-sm btn-square btn-neutral text-danger-hover ms-2"
                onClick={() => {
                  setIsModalOpen(true);
                  setDataTablePick(row.original);
                }}
              >
                <i className="bi bi-trash"></i>
              </button>
            )}
          </div>
        );
      },
    },
  ];

  const mayCreatePeriodePenginapan = useHasPermission(
    "create-periode-usaha-penginapan"
  );
  return (
    <>
      <PageHeader
        title="Rekapitulasi Pendapatan Mingguan"
        {...(mayCreatePeriodePenginapan && {
          to: "/hasil-usaha/penginapan/rekapitulasi/create",
        })}
      />
      <DateRangeFilter onDateChange={setAdditionalQuery} />
      <DynamicTable
        columns={columns}
        url="/api/periode-usaha-penginapan"
        withSearch={false}
        {...(additionalQuery && { newQueryParams: additionalQuery })}
      />
      <ModalDelete
        loading={loading}
        show={isModalOpen}
        onClick={onDeleteData}
        titleAction="Hapus"
        loadingText="menghapus periode usaha penginapan..."
        title={`Apakah anda yakin ingin menghapus periode usaha penginapan ${dataTablePick?.tanggal_mulai} - ${dataTablePick?.tanggal_selesai}?`}
        onHide={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default RekapitulasiUsahaPenginapan;
