import CreateContainer from "containers/create-container";
import { useForm } from "react-hook-form";
import FormInput from "components/form-input";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { request } from "utils/request";
import { toast } from "react-hot-toast";

const pricingKendaraan = [
  {
    name: "parkir_motor",
    label: "Motor",
  },
  {
    name: "parkir_mobil",
    label: "Mobil",
  },
  {
    name: "parkir_minibus",
    label: "Minibus",
  },
  {
    name: "parkir_bus",
    label: "Bus",
  },
];

const dispensasi = [
  {
    name: "dispensasi_sekolah",
    label: "Sekolah",
  },
  {
    name: "dispensasi_umum",
    label: "Umum",
  },
];

const EditLaporanPendapatanTiketing = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  const fetchData = async () => {
    setLoading(true);
    request
      .get(`/api/usaha-tiketing/${id}`)
      .then((res) => {
        const data = res.data.data;
        Object.keys(data).forEach((item) => {
          if (item === "harga_tiket") return;
          if (item === "tanggal") {
            const [tahun, bulan, tanggal] = String(data[item]).split("-");
            setValue(item, new Date(`${bulan}-${tanggal}-${tahun}`));
            return;
          }
          setValue(item, data[item]);
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmit = (values) => {
    // setLoading(true);
    const payload = Object.keys(values).reduce((acc, cur) => {
      if (cur === "tanggal") {
        const newTanggal = new Date(values[cur]);
        const [tahun, bulan, tanggal] = [
          newTanggal.getFullYear(),
          newTanggal.getMonth() + 1,
          newTanggal.getDate(),
        ];
        acc[cur] = `${tahun}-${bulan}-${tanggal}`;
        return acc;
      }
      acc[cur] = String(values[cur])
        .replace(" kendaraan", "")
        .replace(" orang", "")
        .split(",")
        .join("");
      return acc;
    }, {});
    request
      .patch(`/api/usaha-tiketing/${id}`, payload)
      .then((res) => {
        toast.success("Berhasil menambahkan data");
        navigate("/hasil-usaha/tiketing/laporan-pendapatan");
      })
      .catch((err) => {
        toast.error("Gagal menambahkan data");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <CreateContainer
      title="Ubah Laporan Pendapatan Tiketing per Hari"
      loading={loading}
      loadingText="Menambahkan data"
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormInput
        required
        label="Tanggal"
        name="tanggal"
        inputType="date"
        errors={errors}
        control={control}
        placeholder="Masukkan tanggal"
        errorMessage="Tanggal tidak boleh kosong!"
        isClearable={true}
      />
      <FormInput
        required
        maskOptions={{
          prefix: "",
          suffix: " orang",
        }}
        errors={errors}
        name="pengunjung_domestik"
        label="Domestik"
        control={control}
        inputType="prefix_suffix"
        placeholder="Masukkan pengunjung domestik"
        errorMessage="Jumlah pengunjung domestik tidak boleh kosong !"
      />
      <FormInput
        required
        maskOptions={{
          prefix: "",
          suffix: " orang",
        }}
        errors={errors}
        name="pengunjung_mancanegara"
        label="manca"
        control={control}
        inputType="prefix_suffix"
        placeholder="Masukkan pengunjung manca"
        errorMessage="Jumlah pengunjung manca tidak boleh kosong !"
      />
      <div style={{ marginBottom: "30px" }}>
        <div
          style={{
            marginBottom: "11px",
            color: "#000000",
          }}
        >
          Pengunjung
        </div>
        <div
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "7px",
            border: "1px solid #DEDFE1",
            padding: "40px",
          }}
        >
          {pricingKendaraan.map((item) => {
            return (
              <FormInput
                required
                errors={errors}
                name={item.name}
                label={item.label}
                control={control}
                inputType="prefix_suffix"
                maskOptions={{
                  prefix: "",
                  suffix: " kendaraan",
                }}
                placeholder={`Masukkan jumlah ${String(
                  item.label
                ).toLowerCase()}`}
                errorMessage="Kendaraan tidak boleh kosong!"
              />
            );
          })}
        </div>
      </div>
      <div style={{ marginBottom: "30px" }}>
        <div
          style={{
            marginBottom: "11px",
            color: "#000000",
          }}
        >
          Dispensasi
        </div>
        <div
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "7px",
            border: "1px solid #DEDFE1",
            padding: "40px",
          }}
        >
          {dispensasi.map((item) => {
            return (
              <FormInput
                required
                errors={errors}
                name={item.name}
                label={item.label}
                control={control}
                inputType="prefix_suffix"
                maskOptions={{
                  prefix: "",
                  suffix: " orang",
                }}
                placeholder="Masukkan dispensasi"
                errorMessage="Dispensasi tidak boleh kosong!"
              />
            );
          })}
        </div>
      </div>
    </CreateContainer>
  );
};

export default EditLaporanPendapatanTiketing;
