const KaryawanIcons = () => {
  return (
    <svg
      width="23"
      height="17"
      viewBox="0 0 23 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.16862 4.73446C3.16862 6.98216 4.98592 8.75163 7.1858 8.75163C9.4335 8.75163 11.203 6.93434 11.203 4.73446C11.203 2.48675 9.38568 0.717285 7.1858 0.717285C4.93809 0.717285 3.16862 2.53458 3.16862 4.73446ZM7.1858 1.57811C8.90744 1.57811 10.3421 3.01281 10.3421 4.73446C10.3421 6.4561 8.90744 7.89081 7.1858 7.89081C5.46415 7.89081 4.02945 6.4561 4.02945 4.73446C4.02945 3.01281 5.46415 1.57811 7.1858 1.57811Z"
        fill="#6B7BA1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.103 10.2821C9.72045 10.1864 9.29004 10.1386 8.90745 10.0908C8.57268 10.043 8.23792 10.043 7.90315 10.043C5.79892 10.043 3.79034 10.6647 2.35563 12.2907C1.6861 13.1037 0.96875 14.4427 0.96875 15.5427C0.96875 16.3557 1.30351 16.6904 2.11651 16.6904H7.75968C7.61621 16.3078 7.56839 15.9253 7.56839 15.5427C7.56839 13.9645 8.19009 12.4341 9.19439 11.1907C9.43351 10.856 9.72045 10.569 10.0552 10.3299L10.103 10.2821ZM8.04662 11.3342C7.23363 12.5776 6.75539 14.0123 6.75539 15.4948V15.7818H1.8774V15.4948C1.8774 14.634 2.4991 13.4863 3.02516 12.8167C4.22075 11.4299 5.99021 10.9038 7.75968 10.9038H8.28574L7.9988 11.3342H8.04662Z"
        fill="#6B7BA1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6199 4.01717C19.6199 1.76947 17.8026 0 15.6027 0C13.355 0 11.5856 1.81729 11.5856 4.01717C11.5856 6.26488 13.4028 8.03434 15.6027 8.03434C17.8504 8.03434 19.6199 6.21705 19.6199 4.01717ZM15.6027 7.17352C13.8811 7.17352 12.4464 5.73882 12.4464 4.01717C12.4464 2.29553 13.8811 0.860823 15.6027 0.860823C17.3244 0.860823 18.7591 2.29553 18.7591 4.01717C18.7591 5.73882 17.3244 7.17352 15.6027 7.17352Z"
        fill="#6B7BA1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3416 16.6904C22.0111 16.6904 22.4893 16.2122 22.4893 15.5427C22.4893 14.4427 21.8198 13.1037 21.1024 12.2907C19.7156 10.6647 17.6592 10.043 15.5549 10.043C13.4507 10.043 11.4421 10.6647 10.0074 12.2907C9.33787 13.1037 8.62051 14.4427 8.62051 15.5427C8.62051 16.2122 9.09875 16.6904 9.76828 16.6904H21.2459H21.3416ZM9.86392 15.8296C9.86392 15.8296 9.86392 15.8296 9.8161 15.8296L9.57698 15.7818V15.5427C9.57698 14.6818 10.1987 13.5341 10.7247 12.9124C11.9682 11.4777 13.7376 10.9994 15.6027 10.9994C17.42 10.9994 19.2373 11.4777 20.4807 12.9124C21.0068 13.5341 21.6285 14.6818 21.6285 15.5427V15.7818L21.3416 15.8296H9.86392Z"
        fill="#6B7BA1"
      />
    </svg>
  );
};

export default KaryawanIcons;
