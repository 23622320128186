import DynamicTable from "components/dynamic-table";
import ModalDelete from "components/modal-delete";
import PageHeader from "components/page-header";
import { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setTriggerTable } from "redux/actions";
import { toRupiah } from "utils/helper";
import { request } from "utils/request";
import { toExcel } from "utils/helper";
import useHasPermission from "hooks/useHasPermission";
import CheckPermission from "components/CheckPermission";

const ListKatalogVenue = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataTablePick, setDataTablePick] = useState(null);
  const [tableContent, setTableContent] = useState(null);

  const { venueId, id } = useParams();

  const onDeleteData = () => {
    setLoading(true);
    request
      .delete(`/api/venue/${venueId}/katalog/${dataTablePick?.id}`)
      .then((res) => {
        toast.success(`${res.data.message}`, {
          duration: 5000,
        });
        dispatch(setTriggerTable(true));
        setIsModalOpen(false);
      })

      .finally(() => setLoading(false));
  };

  const handleExportData = () => {
    // export to excel
    toExcel(
      "Data Katalog Venue",
      "Data Katalog Venue",
      tableContent.map((item, idx) => {
        return {
          No: idx + 1,
          Nama: item.nama,
          Keterangan: item.keterangan,
          Harga: toRupiah(item.harga),
          "Maksimal Peserta": item.maksimal_peserta,
          Tanggal: item.tanggal,
        };
      })
    );
  };

  const columnsTable = [
    {
      Header: "NO",
      accessor: "nomor",
      width: "20px",
    },
    {
      Header: "NAMA",
      accessor: "nama",
    },
    {
      Header: "JUMLAH PENONTON",
      accessor: "kapasitas_max",
      Cell: ({ row }) => <div>{row?.original?.maksimal_peserta} orang</div>,
    },
    {
      Header: "HARGA",
      accessor: "harga",
      Cell: ({ row }) => toRupiah(row?.original?.harga),
    },
    {
      Header: "AKSI",
      accessor: "aksi",
      Cell: ({ row }) => (
        <td
          className="text-end"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="button-tooltip">List Gambar</Tooltip>}
          >
            <button
              type="button"
              className="btn btn-sm btn-square btn-neutral text-success-hover ms-2"
              onClick={() => {
                navigate(
                  `/data-master/katalog-venue/${venueId}/images/${row.original?.id}`
                );
              }}
            >
              <i className="bi bi-images"></i>
            </button>
          </OverlayTrigger>

          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="button-tooltip">Lihat</Tooltip>}
          >
            <button
              type="button"
              className="btn btn-sm btn-square btn-neutral text-warning-hover ms-2"
              onClick={() => {
                navigate(
                  `/data-master/katalog-venue/${venueId}/detail/${row.original?.id}`
                );
              }}
            >
              <i className="bi bi-eye"></i>
            </button>
          </OverlayTrigger>

          <CheckPermission permission="edit-katalog">
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="button-tooltip">Edit</Tooltip>}
            >
              <button
                type="button"
                className="btn btn-sm btn-square btn-neutral text-warning-hover ms-2"
                onClick={() => {
                  navigate(
                    `/data-master/katalog-venue/${venueId}/ubah-katalog/${row.original?.id}`
                  );
                }}
              >
                <i className="bi bi-pencil"></i>
              </button>
            </OverlayTrigger>
          </CheckPermission>

          <CheckPermission permission="delete-katalog">
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="button-tooltip">Hapus</Tooltip>}
            >
              <button
                type="button"
                className="btn btn-sm btn-square btn-neutral text-danger-hover ms-2"
                onClick={() => {
                  setIsModalOpen(true);
                  setDataTablePick(row.original);
                }}
              >
                <i className="bi bi-trash"></i>
              </button>
            </OverlayTrigger>
          </CheckPermission>
        </td>
      ),
    },
  ];

  const mayCreateKatalog = useHasPermission("create-katalog");

  return (
    <>
      <PageHeader
        title={`Daftar Katalog`}
        {...(mayCreateKatalog && {
          to: `/data-master/katalog-venue/${venueId}/tambah`,
        })}
      />
      <DynamicTable
        title="Katalog Venue"
        columns={columnsTable}
        url={`/api/venue/${venueId}/katalog`}
        exportable={true}
        childLeft={
          <div className="btn btn-primary" onClick={handleExportData}>
            Export
          </div>
        }
        watchTableContent={(data) => {
          setTableContent(data);
        }}
      />

      <ModalDelete
        loading={loading}
        show={isModalOpen}
        onClick={onDeleteData}
        titleAction="Hapus"
        loadingText="menghapus katalog venue..."
        onHide={() => setIsModalOpen(false)}
        title={`Apakah anda yakin ingin menghapus katalog venue ${dataTablePick?.nama}?`}
      />
    </>
  );
};

export default ListKatalogVenue;
