import FormInput from "components/form-input";
import { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Spinner,  } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { request } from "utils/request";
import { useDispatch } from "react-redux";
import { setTriggerTable } from "redux/actions";

const ChangeUserRole = ({
    show,
    onHide,
    data
}) => {
    const [roleOptions,setRoleOptions] = useState([])
    const dispatch = useDispatch()
    const [loading,setLoading] = useState(false)
    const {
        control,
        register,
        formState: { errors },
        setValue,
        handleSubmit
    } = useForm()
    
    const handleChangeRole = (values) => {
        if (!values?.role || !data) return toast.error('Gagal mengubah role!')
        const { user_id } = data
        const { role } = values
        setLoading(true)
        request.put(`/api/user/${user_id}`,{
            role_id: role?.value
        })
        .then(() => {
            toast.success('Berhasil mengubah role!')
            dispatch(setTriggerTable(true))
            onHide()
        })
        .catch(() => {
            toast.error('Gagal mengubah role!')
        })
        .finally(() => {
            setLoading(false)
        })
    }

    const getAvailableRoles = async () => {
        setLoading(true)
        request.get('/api/role')
        .then((res) => {
            setRoleOptions(
                res?.data?.data?.map((role) => ({
                    label: role.name,
                    value: role.id,
                }))
            )
        })
        .catch(err => {
            toast.error(err?.response?.data?.message)
        })
        .finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        getAvailableRoles()
    },[])

    useEffect(() => {
        if (roleOptions?.length > 0 && data?.role) {
            setValue('role',roleOptions.find((role) => role.value === data?.role[0]?.id))
        }
    },[data, roleOptions])

    return (
        <Modal show={show} onHide={onHide} size="md" centered>
            <Modal.Header className="bg-primary">
                <Modal.Title className="text-white">
                    Ubah Role User
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Row>
                    <Col xs="12" sm="12" md="12">
                        {loading ? (
                             <Spinner className="d-block mx-auto" animation="border" />
                        ) : (
                            <div>
                                <FormInput
                                    required
                                    name="role"
                                    inputType="select"
                                    errors={errors}
                                    control={control}
                                    register={register}
                                    options={roleOptions}
                                    placeholder="Pilih Role"
                                />
                            </div>
                        )}
                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="neutral" onClick={onHide}>
                    Tutup
                </Button>
                <Button variant="primary" onClick={handleSubmit(handleChangeRole)}>
                    Simpan
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ChangeUserRole;