import { useEffect, useState } from "react";
import CreateContainer from "containers/create-container";
import { request } from "utils/request";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import FormInput from "components/form-input";
import { useNavigate } from "react-router-dom";

const CreateTransactionDua = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [jenisTransaksi, setJenisTransaksi] = useState([]);
  const [akun, setAkun] = useState([]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    watch,
    setValue,
  } = useForm();

  const watchJenisTransaksi = watch("jenisTransaksi");

  const fetchJenisTransaksi = async () => {
    setLoading(true);
    request
      .get("/api/jenis-transaksi")
      .then((res) => {
        const newJenisTransaksi = res.data.data;
        setJenisTransaksi(
          newJenisTransaksi.map((item) => ({
            value: item.id,
            label: item.nama,
            ...item,
          }))
        );
      })
      .catch((err) => {
        toast.error("Gagal memuat jenis transaksi");
      })
      .finally(() => setLoading(false));
  };

  const fetchAkun = async () => {
    setLoading(true);
    request
      .get("/api/akun")
      .then((res) => {
        const newAkun = res.data.data;
        setAkun(
          newAkun.map((item) => ({
            value: item.kode,
            label: item.nama,
            ...item,
          }))
        );
      })
      .catch((err) => {
        toast.error("Gagal memuat akun");
      })
      .finally(() => setLoading(false));
  };

  const onSubmitTransaksi = (values) => {
    const payload = {
      tanggal: values.tanggal,
      jenis_transaksi: values.jenisTransaksi.value,
      kode_akun: values.akun.value,
      nama_akun: values.akun.label,
      keterangan: values.keterangan,
      nominal:
        String(values?.jumlah)?.replace("Rp ", "")?.split(",")?.join("") ?? 0,
    };

    setLoading(true);
    request
      .post("/api/jurnal-umum-dua", payload)
      .then(() => {
        toast.success("Berhasil menambahkan transaksi");
        navigate("/keuangan/bendahara-2/jurnal-umum");
      })
      .catch((err) => {
        toast.error("Gagal menambahkan transaksi");
        console.log(err);
      });
  };

  useEffect(() => {
    fetchJenisTransaksi();
    fetchAkun();
  }, []);

  useEffect(() => {
    setAkun(
      watchJenisTransaksi?.akun?.map((item) => ({
        value: item.kode,
        label: item.nama ?? item.kode,
        ...item,
      })) ?? []
    );
    setValue("akun", null);
  }, [watchJenisTransaksi]);

  return (
    <CreateContainer
      title="Tambah Transaksi"
      loading={loading}
      loadingText="Menambahkan transaksi.."
      onSubmit={handleSubmit(onSubmitTransaksi)}
    >
      <FormInput
        required={true}
        label="Tanggal"
        name="tanggal"
        inputType="date"
        errors={errors}
        control={control}
        register={register}
        placeholder="Pilih tanggal"
        errorMessage="Waktu tidak boleh kosong!"
        isClearable={true}
      />

      <FormInput
        name="jenisTransaksi"
        inputType="select"
        label="Jenis Transaksi"
        errors={errors}
        control={control}
        register={register}
        options={jenisTransaksi}
        placeholder="Pilih jenis transaksi"
        errorMessage="Jenis transaksi tidak boleh kosong!"
      />

      <FormInput
        name="akun"
        inputType="select"
        label="Akun"
        errors={errors}
        control={control}
        register={register}
        options={akun}
        placeholder="Pilih akun"
        errorMessage="Akun tidak boleh kosong!"
      />

      <FormInput
        required
        label="Keterangan"
        name="keterangan"
        type="text"
        errors={errors}
        register={register}
        placeholder="Masukkan keterangan"
        errorMessage="Keterangan tidak boleh kosong!"
      />

      <FormInput
        required
        errors={errors}
        name="jumlah"
        label="Jumlah"
        control={control}
        inputType="price"
        placeholder="Masukkan jumlah"
        errorMessage="Jumlah tidak boleh kosong!"
      />
    </CreateContainer>
  );
};

export default CreateTransactionDua;
