import Topbar from "layouts/topbar";
import { useEffect, useState } from "react";
import { GeoJSON, LayersControl, TileLayer } from "react-leaflet";
import { MapContainer } from "react-leaflet/MapContainer";
import geoJson from "../../../data/venue_breksi.json";
import * as ReactDOMServer from 'react-dom/server';
import L from 'leaflet';


const center = [-7.7807914,110.5047138];

const popupStyle = {
    width: "100%"
}

const fontStyle = {
    fontFamily: "Poppins"
}

const TablePopup = ({feature}) => {
    return (
        <div style={{fontSize:"14px"}}>
            <div className="d-flex py-1" style={fontStyle}>
                <span className="" style={{width: "35%"}}>Dimensi Area</span>
                <span style={{width: "65%"}}>: {feature.properties.DIMENSI_PG}</span>
            </div>
            <div className="d-flex py-1" style={fontStyle}>
                <span className="" style={{width: "35%"}}>Luas Area</span>
                <span style={{width: "65%"}}>: {feature.properties.LUAS_AREA}</span>
            </div>
            <div className="d-flex py-1" style={fontStyle}>
                <span className="" style={{width: "35%"}}>Kapasitas Max</span>
                <span style={{width: "65%"}}>: {feature.properties.KAPASITAS}</span>
            </div>
            <div className="d-flex py-1" style={fontStyle}>
                <span className="" style={{width: "35%"}}>Harga</span>
                <span style={{width: "65%"}}>: {feature.properties.HARGA}</span>
            </div>
        </div>
    )
}

const CustomPopup = ({feature}) => {

    const [imgLoaded, setImgLoaded] = useState(false)

    const imgStyle = imgLoaded ? {
        width: "100%",
        height: "200px",
        objectFit: "cover"
    } : {
        width: "100%",
        height: "200px",
        objectFit: "cover",
        background: "#f3f3f3"
    }

    return (
        <div style={popupStyle}>
            <h3 style={fontStyle}>{feature.properties.NAMA}</h3>
            <div className="mt-3">
                
                <img 
                    className="rounded" 
                    style={imgStyle} 
                    src={feature.properties.FOTO} 
                    alt={`Foto ${feature.properties.NAMA}`} 
                    onLoad={() => setImgLoaded(true)}
                />
                
                <div className="mt-3">
                    <TablePopup 
                        feature={feature}
                    />
                </div>
            </div>
        </div>
    )
}


function onEachFeature(feature, layer) {
    const poupOption = {
        minWidth: "350",
    }
    const popupContent = ReactDOMServer.renderToString(
        <CustomPopup feature={feature} />
    );
    layer.bindPopup(popupContent, poupOption);

    layer.on('mouseover', function(e) {
        this.setStyle({
            fillOpacity: 0.7,
        })
    })

    layer.on('mouseout', function(e) {
        this.setStyle({
            fillOpacity: 0.2,
        })
    })
}

const Map2d = () => {

    const [map, setMap] = useState(null);

    // const tileLayer = {
        // attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        // url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    // }

    const citraUrl = `${process.env.REACT_APP_API_URL}/media/{z}/{x}/{y}.png`;

    useEffect(() => {
        if (!map) return;

        const legend = L.control({ position: "bottomleft" });

        legend.onAdd = () => {

        const div = L.DomUtil.create("div", "legend");
        div.innerHTML = `click on polygon`;
        return div;
        };

        legend.addTo(map);

    }, [map]);


    return (
        <div>
            <Topbar />
            <div style={{marginTop: "8vh"}}>
                <MapContainer 
                    style={{height: "92vh"}}
                    whenCreated={setMap}
                    center={center}
                    zoom={20}
                    scrollWheelZoom={false}
                    maxZoom={18}
                    zoomControl={true}
                >   
                    {/* <TileLayer {...tileLayer} /> */}
                    <LayersControl position="topright">

                        <LayersControl.BaseLayer name="Google Satelite" checked={true}>
                            <TileLayer
                            url={"http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"}
                            subdomains={["mt0", "mt1", "mt2", "mt3"]}
                            />
                        </LayersControl.BaseLayer>

                        <LayersControl.BaseLayer name="Google Map">
                            <TileLayer
                            url={"http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"}
                            subdomains={["mt0", "mt1", "mt2", "mt3"]}
                            />
                        </LayersControl.BaseLayer>
                
                        <LayersControl.Overlay name="Citra" checked>
                            <TileLayer url={citraUrl} />
                        </LayersControl.Overlay>
                        
                    </LayersControl>

                    <GeoJSON data={geoJson} onEachFeature={onEachFeature} />
                </MapContainer>
            </div>
        </div>
    );
}

export default Map2d;