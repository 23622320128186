import PageHeader from "components/page-header";
import { format } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { request } from "utils/request";
import localeId from "date-fns/locale/id";
import { toRupiah } from "utils/helper";
import PageHeaderBtns from "components/page-header-btns";

const DetailPeriodeTiketing = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [periodeData, setPeriodeData] = useState(null);

  const title = useMemo(() => {
    if (periodeData) {
      const [tanggalMulai, bulanMulai, tahunMulai] = String(
        periodeData.tanggal_mulai
      ).split("-");
      const [tanggalSelesai, bulanSelesai, tahunSelesai] = String(
        periodeData.tanggal_selesai
      ).split("-");
      const start = format(
        new Date(`${tahunMulai}-${bulanMulai}-${tanggalMulai}`),
        "dd MMMM yyyy",
        { locale: localeId }
      );
      const end = format(
        new Date(`${tahunSelesai}-${bulanSelesai}-${tanggalSelesai}`),
        "dd MMMM yyyy",
        { locale: localeId }
      );
      return `Detail Periode ${start} - ${end}`;
    }
  }, [periodeData]);

  const fetchData = async () => {
    setLoading(true);
    request
      .get(`/api/periode-usaha-tiketing/${id}`)
      .then((res) => {
        setPeriodeData(res?.data?.data);
      })
      .catch((err) => {
        toast.error("Gagal mengambil data");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (periodeData)
    return (
      <>
        <PageHeaderBtns
          title={title}
          btns={[
            {
              name: "Kembali",
              path: "/hasil-usaha/tiketing/rekapitulasi",
              url: "",
              icon: "bi bi-chevron-left",
              newTab: false,
              type: "btn-danger",
            },
          ]}
        />
        <div
          style={{
            backgroundColor: "#FFFFFF",
            paddingTop: "77px",
            paddingBottom: "74px",
            borderRadius: "16px",
            border: "1px solid #D9D9D9",
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "80px repeat(5,1fr)",
              borderTop: "1px solid #D9D9D9",
              borderBottom: "3px solid #D9D9D9",
              padding: "18px 26px",
              backgroundColor: "#F2F2F2",
            }}
          >
            <div>No</div>
            <div>Uraian</div>
            <div>Jumlah</div>
            <div>Harga</div>
            <div>Total</div>
            <div>Pendapatan</div>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "80px repeat(5,1fr)",
              borderTop: "1px solid #D9D9D9",
              borderBottom: "1px solid #D9D9D9",
              padding: "18px 26px",
            }}
            className="hoverable-row"
          >
            <div>1</div>
            <div>Wisnus</div>
            <div>{periodeData.jumlah_pengunjung_domestik}</div>
            <div>{toRupiah(periodeData.harga_pengunjung_domestik)}</div>
            <div>
              {toRupiah(
                periodeData.jumlah_pengunjung_domestik *
                  periodeData.harga_pengunjung_domestik
              )}
            </div>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "80px repeat(5,1fr)",
              borderTop: "1px solid #D9D9D9",
              borderBottom: "1px solid #D9D9D9",
              padding: "18px 26px",
            }}
            className="hoverable-row"
          >
            <div>2</div>
            <div>Wisman</div>
            <div>{periodeData.jumlah_pengunjung_mancanegara}</div>
            <div>{toRupiah(periodeData.harga_pengunjung_mancanegara)}</div>
            <div>
              {toRupiah(
                periodeData.jumlah_pengunjung_mancanegara *
                  periodeData.harga_pengunjung_mancanegara
              )}
            </div>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "80px repeat(5,1fr)",
              borderTop: "1px solid #D9D9D9",
              borderBottom: "1px solid #D9D9D9",
              padding: "18px 26px",
            }}
            className="hoverable-row"
          >
            <div>3</div>
            <div>Parkir</div>
            <div></div>
            <div></div>
            <div>{toRupiah(periodeData.total_parkir)}</div>
          </div>
          <div
            style={{
              padding: "22px 32px",
              backgroundColor: "#F2F2F2",
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>Total Pemasukan</div>
            <div style={{ width: "228.2px", textAlign: "center" }}>
              {toRupiah(periodeData.total_pendapatan_kotor)}
            </div>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "80px repeat(5,1fr)",
              borderTop: "1px solid #D9D9D9",
              borderBottom: "1px solid #D9D9D9",
              padding: "18px 26px",
            }}
            className="hoverable-row"
          >
            <div>1</div>
            <div>Dispensasi Sekolah</div>
            <div>{periodeData.jumlah_dispensasi_sekolah}</div>
            <div>{toRupiah(periodeData.harga_pengunjung_domestik)}</div>
            <div>{toRupiah(periodeData.dispensasi_sekolah)}</div>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "80px repeat(5,1fr)",
              borderTop: "1px solid #D9D9D9",
              borderBottom: "1px solid #D9D9D9",
              padding: "18px 26px",
            }}
            className="hoverable-row"
          >
            <div>2</div>
            <div>Dispensasi Umum</div>
            <div>{periodeData.jumlah_dispensasi_umum}</div>
            <div>{toRupiah(periodeData.harga_pengunjung_domestik)}</div>
            <div>{toRupiah(periodeData.dispensasi_umum)}</div>
          </div>
          <div
            style={{
              padding: "22px 32px",
              backgroundColor: "#F2F2F2",
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>Total Dispensasi</div>
            <div style={{ width: "228.2px", textAlign: "center" }}>
              {toRupiah(
                periodeData.dispensasi_umum + periodeData.dispensasi_sekolah
              )}
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "80px repeat(5,1fr)",
              borderTop: "1px solid #D9D9D9",
              borderBottom: "1px solid #D9D9D9",
              padding: "18px 26px",
            }}
            className="hoverable-row"
          >
            <div>1</div>
            <div>Asuransi</div>
            <div>{periodeData.jumlah_asuransi}</div>
            <div>{toRupiah(periodeData.harga_asuransi)}</div>
            <div>{toRupiah(periodeData.asuransi)}</div>
          </div>
          <div
            style={{
              padding: "22px 32px",
              backgroundColor: "#F2F2F2",
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>Total Pendapatan</div>
            <div style={{ width: "228.2px", textAlign: "center" }}>
              {toRupiah(periodeData.total_pendapatan_bersih)}
            </div>
          </div>
        </div>
      </>
    );
};

export default DetailPeriodeTiketing;
