import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import PageHeader from "components/page-header";

const CreateContainer = ({
  title,
  children,
  onSubmit,
  loading,
  loadingText,
  loadingRequest,
  autoSubmit = true,
  unsubmitable = false,
  onCancel = null,
  additionalButton,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <PageHeader title={title} additionalButton={additionalButton} />
      {loadingRequest || loading ? (
        <Spinner className="d-block mx-auto" animation="border" />
      ) : (
        <div className="py-6 bg-surface-secondary">
          <div className="container-fluid max-w-screen-md vstack gap-6">
            <form autoComplete="off" onSubmit={onSubmit}>
              <div className="row g-5">
                <div className="col-12">{children}</div>

                {!unsubmitable && (
                  <div className="col-12 text-end">
                    <button
                      type="button"
                      onClick={() => {
                        if (onCancel) onCancel();
                        else navigate(-1);
                      }}
                      className="btn btn-sm btn-neutral me-2"
                      style={{
                        width: "100%",
                        maxWidth: "120px",
                        height: "40px",
                      }}
                    >
                      Batal
                    </button>
                    <button
                      type={autoSubmit ? "submit" : "button"}
                      disabled={loading}
                      className="btn btn-sm btn-primary"
                      style={{
                        width: "100%",
                        height: "40px",
                        maxWidth: loading ? "300px" : "120px",
                      }}
                      {...(autoSubmit ? {} : { onClick: onSubmit })}
                    >
                      {loading ? loadingText : "Simpan"}
                    </button>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

CreateContainer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  loadingRequest: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

export default CreateContainer;
