import DynamicTable from "components/dynamic-table";
import PageHeaderBtns from "components/page-header-btns";
import { toRupiah } from "utils/helper";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ModalDelete from "components/modal-delete";
import { request } from "utils/request";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setTriggerTable } from "redux/actions";
import CheckPermission from "components/CheckPermission";
import useHasPermission from "hooks/useHasPermission";

const DaftarPengeluaran = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [tablePick, setTablePick] = useState(null);
  const [loading, setLoading] = useState(false);
  const mayCreatePengeluaranReservasi = useHasPermission(
    "create-pengeluaran-reservasi"
  );

  const columns = [
    {
      Header: "No",
      accessor: "nomor",
    },
    {
      Header: "Nama Biaya",
      accessor: "nama",
    },
    {
      Header: "Biaya",
      accessor: "biaya",
      Cell: ({ row }) => {
        return toRupiah(row.original.harga);
      },
    },
    {
      Header: "Aksi",
      accessor: "aksi",
      Cell: ({ row }) => {
        return (
          <>
            <CheckPermission permission="edit-pengeluaran-reservasi">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip">Edit</Tooltip>}
              >
                <button
                  type="button"
                  className="btn btn-sm btn-square btn-neutral text-warning-hover ms-2"
                  onClick={() => {
                    navigate(
                      `/reservasi/biaya-pengeluaran/${row.original.id}/edit`
                    );
                  }}
                >
                  <i className="bi bi-pencil"></i>
                </button>
              </OverlayTrigger>
            </CheckPermission>
            <CheckPermission permission="delete-pengeluaran-reservasi">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip">Hapus</Tooltip>}
              >
                <button
                  type="button"
                  className="btn btn-sm btn-square btn-neutral text-warning-hover ms-2"
                  onClick={() => {
                    setDeleteModalVisible(true);
                    setTablePick(row.original);
                  }}
                >
                  <i className="bi bi-trash"></i>
                </button>
              </OverlayTrigger>
            </CheckPermission>
          </>
        );
      },
    },
  ];

  const btns = {
    back: {
      name: "Kembali",
      path: "/reservasi",
      url: "",
      icon: "bi bi-chevron-left",
      type: "btn-danger",
      newTab: false,
    },
    ...(mayCreatePengeluaranReservasi && {
      create: {
        name: "Tambah",
        path: "/reservasi/biaya-pengeluaran/create",
        url: "",
        icon: "bi bi-plus-square-dotted",
        newTab: false,
      },
    }),
  };

  const onDeleteData = () => {
    setLoading(true);
    request
      .delete(`/api/pengeluaran-reservasi/${tablePick?.id}`)
      .then((res) => {
        toast.success(`${res.data.message}`, {
          duration: 5000,
        });
        dispatch(setTriggerTable(true));
        setDeleteModalVisible(false);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <PageHeaderBtns
        title="Daftar Biaya Pengeluaran"
        btns={Object.values(btns)}
      />
      <DynamicTable columns={columns} url="/api/pengeluaran-reservasi" />
      <ModalDelete
        loading={loading}
        show={deleteModalVisible}
        onClick={onDeleteData}
        titleAction="Hapus"
        loadingText="Menghapus pengeluaran reservasi..."
        title={`Apakah anda yakin ingin menghapus biaya ${tablePick?.nama}?`}
        onHide={() => setDeleteModalVisible(false)}
      />
    </>
  );
};

export default DaftarPengeluaran;
