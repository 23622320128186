import { useEffect, useState } from "react";
import { request } from "utils/request";

const AttendanceBarcode = () => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)

    const getBarcodePresensi = async () => {
        try {
            setLoading(true)
            const response = await request.get('/api/qr-presensi')
            if (response) {
                setData(response.data)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getBarcodePresensi()
    },[])

    return (
        <div
            style={{
                marginLeft: '-48px',
                marginRight: '-48px',
                marginTop: '-24px',
                height: '100vh',
            }}
        >
            <div
                style={{
                    fontSize: '48px',
                    fontWeight: '600',
                    color: '#019443',
                    backgroundColor: '#F2F2F2',
                    padding: '25px 0px 25px 0px',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                QR Code Presensi Jam Kerja
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#FFFFFF',
                    height: '100%',
                }}
            >
                {
                    !loading && data ? (
                        <div>
                            <img
                                src={data?.data}
                                alt="barcode"
                                style={{
                                    width: '455px',
                                    height: '455px',
                                    marginTop: '-50%'
                                }}
                            />
                        </div>
                    ) : (
                        <div>QR Code tidak tersedia</div>
                    )
                }
            </div>
        </div>
    )
}

export default AttendanceBarcode;