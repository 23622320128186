const PresensiIcons = () => {
  return (
    <svg
      width="25"
      height="20"
      viewBox="0 0 25 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.4621 9.99753C-0.0801422 10.1331 -0.170516 10.8561 0.326539 11.082C0.59766 11.2176 1.81771 11.1724 2.1792 11.1724C2.67626 11.1724 5.83933 11.2176 6.06527 11.1724C6.60751 11.0368 6.69789 10.3138 6.20083 10.0427C5.9749 9.90715 4.66448 9.99753 4.34817 9.99753C3.80593 9.99753 0.733221 9.95234 0.4621 10.0427V9.99753Z"
        fill="#6B7BA1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.90807 13.0252C1.68214 13.0704 1.50139 13.1608 1.41102 13.3867C1.32064 13.6126 1.36583 13.8386 1.54658 14.0193C1.86288 14.3356 2.67625 14.2453 3.12812 14.2453C3.62517 14.2453 6.38157 14.2904 6.65269 14.2453C7.14975 14.1549 7.33049 13.6126 7.01419 13.2511C6.78825 13.0252 6.65269 13.0252 6.33638 13.0252C5.61339 13.0252 2.26957 12.98 1.95326 13.0704L1.90807 13.0252Z"
        fill="#6B7BA1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.81771 7.0152C1.32066 7.15076 1.1851 7.73819 1.59178 8.0545C1.90809 8.32562 2.76664 8.19006 3.2185 8.19006C3.71556 8.19006 6.42677 8.23525 6.69789 8.19006C7.14976 8.0545 7.33051 7.46707 6.92383 7.15076C6.69789 6.97002 6.47196 7.0152 6.20084 7.0152C5.52303 7.0152 2.08883 6.97002 1.81771 7.06039V7.0152Z"
        fill="#6B7BA1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.2578 4.07819C4.03186 4.12338 3.8963 4.16857 3.76074 4.3945C3.67037 4.57525 3.67037 4.80118 3.76074 4.98193C4.03186 5.38861 4.61929 5.29824 5.02597 5.29824C5.47784 5.29824 7.96312 5.34342 8.18905 5.29824C8.64092 5.20786 8.86686 4.75599 8.64092 4.3945C8.3698 3.98782 7.78237 4.07819 7.37569 4.07819C6.87864 4.07819 4.52892 4.033 4.21261 4.12338L4.2578 4.07819Z"
        fill="#6B7BA1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16742 15.917C3.71555 16.0525 3.57999 16.4592 3.85111 16.8659C4.07705 17.1822 4.80004 17.0918 5.20672 17.0918C5.6134 17.0918 8.18905 17.137 8.32461 17.0918C8.73129 17.0015 8.91204 16.4592 8.64092 16.1429C8.3698 15.8266 7.692 15.917 7.28532 15.917C6.78826 15.917 4.43854 15.8718 4.16742 15.9622V15.917Z"
        fill="#6B7BA1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6508 0.0564836C14.1537 0.192044 14.2441 0.508352 14.2441 0.960221C14.2441 1.50246 14.6959 1.45728 15.1026 1.45728C15.4641 1.45728 17.6783 1.45728 17.859 1.45728C18.3109 1.3669 18.2657 0.82466 18.2205 0.508352C18.2205 0.0112967 17.7687 0.0112967 17.362 0.0112967C16.9101 0.0112967 14.9219 -0.0338901 14.6508 0.0564836Z"
        fill="#6B7BA1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0516 9.68134V10.088C16.0064 10.1332 15.2834 10.3591 15.3738 10.9918C15.5093 11.9859 16.9553 12.0311 17.136 10.9918C17.2716 10.3591 16.5034 10.088 16.4582 10.0428C16.4131 8.64204 16.4582 6.97013 16.4582 5.56934H16.0967V9.68134H16.0516Z"
        fill="#6B7BA1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8197 1.72833C16.6842 1.68315 16.2775 1.72833 16.0968 1.72833C15.2834 1.72833 15.4189 1.59277 15.4189 2.49651C15.4189 2.67726 15.4189 2.90319 15.5997 3.03875C15.8256 3.26469 16.4582 3.26469 16.6842 3.03875C16.8649 2.85801 16.8197 2.67726 16.8197 2.45132C16.8197 2.27058 16.8649 1.90908 16.8197 1.77352V1.72833Z"
        fill="#6B7BA1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9101 9.68131C17.0457 9.77168 17.136 9.77168 17.2716 9.90724C17.4072 10.0428 17.4072 10.1332 17.4975 10.2687L18.9887 8.86795L18.4013 8.28052L16.9101 9.68131Z"
        fill="#6B7BA1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3168 2.63208C21.3384 3.21951 24.4111 6.65371 24.4111 10.8109C24.4111 15.3748 20.7058 19.0801 16.1419 19.0801C11.5781 19.0801 7.87274 15.3748 7.87274 10.8109C7.87274 6.65371 10.9454 3.21951 14.9671 2.63208C15.0123 2.9032 15.1026 3.08395 15.2834 3.2647C15.6901 3.62619 16.5938 3.62619 17.0457 3.21951C17.2264 2.99357 17.2716 2.81283 17.3168 2.63208ZM16.1419 3.80694C20.028 3.80694 23.1911 6.97002 23.1911 10.8561C23.1911 14.7422 20.028 17.9052 16.1419 17.9052C12.2559 17.9052 9.09279 14.7422 9.09279 10.8561C9.09279 6.97002 12.2559 3.80694 16.1419 3.80694Z"
        fill="#6B7BA1"
      />
    </svg>
  );
};

export default PresensiIcons;
