import { useRequest } from "hooks/useRequest";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { toRupiah } from "utils/helper";

const Chip = ({ text = '' }) => {
    return (
        <div
            style={{
                backgroundColor: '#FFFFFF',
                padding: '4px 10px',
                borderRadius: '8px',
                border: '1px solid #C4C4C4'
            }}
        >
            {text}
        </div>
    )
}


const ShowKatalogPage = () => {

    const {venueId, id} = useParams();

    const [isLoading, setIsLoading] = useState(false);
    
    const {
        errors: errorsKatalog,
        loading: loadingKatalog,
        response: responseKatalog,
    } = useRequest(
        `/api/venue/${venueId}/katalog/${id}`
    );

    const katalog = responseKatalog?.data?.data;

    return (
        <>
            <div style={{ marginBottom: '35px' }}>
                <img alt={`Foto ${katalog?.nama}`} src={katalog?.first_media_url} />
            </div>
            <div className="py-2 border-bottom">
                <h2 className="text-primary">
                    {katalog?.nama}
                </h2>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '20px',
                        marginTop: '14px',
                        gap: '20px'
                    }}
                >
                    <Chip text={`Jumlah Penonton: ${katalog?.maksimal_peserta} orang`} />
                    <Chip text={`Harga: ${toRupiah(katalog?.harga)}`} />
                </div>
            </div>

            <div className="mt-3">
                <div className="mt-3">
                    {katalog?.keterangan}
                </div>
            </div>
        </>
    );
}

export default ShowKatalogPage;