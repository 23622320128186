import { Button, Col, Modal, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import FormInput from "components/form-input";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { request } from "utils/request";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ModalPresensiIzin = ({
    show,
    onHide,
    data
}) => {
    const [loading, setLoading] = useState(false)
    const {
        control,
        register,
        formState: { errors },
        setValue,
    } = useForm();
    const navigate = useNavigate()

    const watchNip = useWatch({ name: "nip", control });

    useEffect(() => {
        const handleSubmitData = async () => {
            try {
                setLoading(true)
                const payload = {
                    nip: watchNip,
                    status: String(data?.status?.value),
                    tanggal: format(new Date(), 'yyyy-MM-dd'),
                    jenis: "manual",
                    setengah_hari: true
                }
                const response = await request.post('/api/presensi-karyawan', payload)
                    .then((res) => res)
                if (response) {
                    toast.success(`Berhasil menambahkan`, { duration: 3000 });
                    setValue('nip', '')
                    navigate('/presensi')
                }
            } catch (err) {
                toast.error('Gagal memasukkan presensi', { duration: 3000 })
                setValue('nip', '')
                console.log(err)
            } finally {
                setLoading(false)
            }
        }

        if (watchNip?.length === 17 && !loading) {
            handleSubmitData()
        }
    },[watchNip, data])

    return (
        <Modal show={show} onHide={onHide} size="md" centered>
            <Modal.Header className="bg-primary">
                <Modal.Title className="text-white">
                    <div>
                        Presensi Izin Setengah Hari
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs="12" sm="12" md="12">
                        {
                            loading ? (
                                <Spinner className="d-block mx-auto" animation="border" />
                            ) : (
                                <>
                                    <FormInput
                                        label="NIP"
                                        name="nip"
                                        type="number"
                                        errors={errors}
                                        register={register}
                                        control={control}
                                        placeholder="3309054307920003"
                                        errorMessage="NIP tidak boleh kosong"
                                    />
                                    <div>
                                        <i class="bi bi-info-circle"></i>
                                        <span style={{ marginLeft: '4px'}}>
                                            Pada saat karakter 17, presensi dikirim.
                                        </span>
                                    </div>
                                </>
                            )
                        }
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalPresensiIzin;