import DynamicTable from "components/dynamic-table";
import PageHeader from "components/page-header";
import { format } from "date-fns";
import { toRupiah } from "utils/helper";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMemo, useEffect, useState } from "react";
import FormInput from "components/form-input";
import { request } from "utils/request";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setTriggerTable } from "redux/actions";
import { id } from "date-fns/locale";
import "./index.css";
import CheckPermission from "components/CheckPermission";
import useHasPermission from "hooks/useHasPermission";
import ModalDelete from "components/modal-delete";

const DateRangeFilter = ({ onDateChange = () => {} }) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useForm();
  const filterFields = watch();
  const { tanggal } = filterFields;

  const additionalQuery = useMemo(() => {
    const from = tanggal?.split(" - ")[0]
      ? `&from=${tanggal?.split(" - ")[0]}`
      : null;
    const to = tanggal?.split(" - ")[1]
      ? `&to=${tanggal?.split(" - ")[1]}`
      : null;
    if (!from && !to) return null;
    return `${from ? from : ""}${to ? to : ""}`;
  }, [tanggal]);

  useEffect(() => {
    onDateChange(additionalQuery);
  }, [additionalQuery, onDateChange]);

  return (
    <FormInput
      name="tanggal"
      label="Periode"
      inputType="date"
      errors={errors}
      control={control}
      placeholder="Pilih periode"
      errorMessage="Waktu tidak boleh kosong!"
      options={{
        mode: "range",
      }}
      isClearable={true}
      mb={0}
      containerStyle={{
        width: "402px",
      }}
    />
  );
};

const RekapitulasiHasilUsahaToilet = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [additionalQuery, setAdditionalQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [dataTablePick, setDataTablePick] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onDeleteData = () => {
    setLoading(true);
    request
      .delete(`/api/periode-usaha-toilet/${dataTablePick?.id}`)
      .then((res) => {
        toast.success(`Periode usaha toilet berhasil dihapus`, {
          duration: 5000,
        });
        dispatch(setTriggerTable(true));
        setIsModalOpen(false);
      })

      .finally(() => setLoading(false));
  };

  const confirmRekapitulasi = (data) => {
    request
      .patch(`api/periode-usaha-toilet/${data.id}`, {
        konfirmasi: true,
      })
      .then((res) => {
        toast.success("Berhasil mengonfirmasi periode");
        dispatch(setTriggerTable(true));
      })
      .catch((err) => {
        console.log(err);
        toast.error("Gagal melakukan konfirmasi");
      });
  };

  const columns = [
    {
      Header: "No",
      accessor: "nomor",
    },
    {
      Header: "Periode",
      accessor: "periode",
      Cell: ({ row }) => {
        return `${format(
          new Date(row?.original?.tanggal_mulai),
          "dd MMMM yyyy",
          { locale: id }
        )} - ${format(
          new Date(row?.original?.tanggal_selesai),
          "dd MMMM yyyy",
          { locale: id }
        )}`;
      },
    },
    {
      Header: "Total",
      accessor: "pendapatan_sum_pendapatan",
      Cell: ({ row }) => toRupiah(row?.original?.pendapatan_sum_pendapatan),
    },
    {
      Header: "Aksi",
      accessor: "aksi",
      Cell: ({ row }) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="button-tooltip">Detail</Tooltip>}
            >
              <button
                type="button"
                className="btn btn-sm btn-square btn-neutral text-warning-hover ms-2"
                onClick={() => {
                  navigate(
                    `/hasil-usaha/toilet/rekapitulasi/${row.original.id}/detail`
                  );
                }}
              >
                <i className="bi bi-info-circle"></i>
              </button>
            </OverlayTrigger>
            <CheckPermission permission="konfirmasi-laporan">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip">Konfirmasi</Tooltip>}
              >
                <button
                  type="button"
                  className="btn btn-sm btn-square btn-neutral text-warning-hover ms-2"
                  onClick={() => {
                    if (row?.original?.konfirmasi) return;
                    confirmRekapitulasi(row?.original);
                  }}
                >
                  <i
                    className={`bi ${
                      !row?.original?.konfirmasi ? "bi-clock" : "bi-check2"
                    }`}
                  ></i>
                </button>
              </OverlayTrigger>
            </CheckPermission>
            {!row?.original?.konfirmasi && (
              <button
                type="button"
                className="btn btn-sm btn-square btn-neutral text-danger-hover ms-2"
                onClick={() => {
                  setIsModalOpen(true);
                  setDataTablePick(row.original);
                }}
              >
                <i className="bi bi-trash"></i>
              </button>
            )}
          </div>
        );
      },
    },
  ];

  const mayCreateRekapitulasiToilet = useHasPermission(
    "create-periode-usaha-toilet"
  );
  return (
    <>
      <PageHeader
        title="Rekapitulasi Pendapatan Mingguan"
        {...(mayCreateRekapitulasiToilet && {
          to: "/hasil-usaha/toilet/rekapitulasi/create",
        })}
      />
      <DateRangeFilter onDateChange={setAdditionalQuery} />
      <DynamicTable
        withSearch={false}
        url="/api/periode-usaha-toilet"
        columns={columns}
        {...(additionalQuery && { newQueryParams: additionalQuery })}
      />
      <ModalDelete
        loading={loading}
        show={isModalOpen}
        onClick={onDeleteData}
        titleAction="Hapus"
        loadingText="menghapus periode usaha toilet..."
        title={`Apakah anda yakin ingin menghapus periode usaha toilet ${dataTablePick?.tanggal_mulai} - ${dataTablePick?.tanggal_selesai}?`}
        onHide={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default RekapitulasiHasilUsahaToilet;
