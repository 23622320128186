import { useMemo } from "react";
import { useSelector } from "react-redux";

const CheckPermission = ({ children, permission }) => {
  const { user } = useSelector((state) => {
    return state?.auth;
  });

  const permissions = useMemo(() => {
    return user?.permissions ?? [];
  }, [user]);

  if (permissions.includes(String(permission).toLowerCase())) {
    return children;
  }

  return <></>;
};

export default CheckPermission;
