import DynamicTable from "components/dynamic-table";
import PageHeader from "components/page-header";
import InfoIcon from "icons/info";
import PrintIcon from "icons/print";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { toRupiah } from "utils/helper";
import { request } from "utils/request";
import { Modal } from "react-bootstrap";
import { useDebounce } from "use-debounce";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { format } from "date-fns";
import {id as localeid } from "date-fns/locale";

const DetailPeriode = () => {

    const params = useParams()
    const { id = null } = params
    const [loading, setLoading] = useState(false)
    const [dataPeriode,setDataPeriode] = useState(null)
    const [dataPegawai,setDataPegawai] = useState([])
    const [modalVisible,setModalVisible] = useState(false)
    const [dataPegawaiDetail,setDataPegawaiDetail] = useState(null)
    const [search,setSearch] = useState('')
    const slipGaji = useMemo(() => {
        return dataPegawaiDetail?.slip_gaji?.find(item => item.periode_gaji_id === Number(id))
    },[dataPegawaiDetail])

    const [debouncedSearch] = useDebounce(search, 500)

    const fetchPeriodeData = async (search) => {
        setLoading(true)
        request.get(`/api/periode-gaji/${id}${search ? `?karyawan=${search}` : ''}`)
            .then((res) => {
                setDataPeriode(res?.data?.data)
                setDataPegawai(res?.data?.data?.karyawan)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchPeriodeData(debouncedSearch)
    },[debouncedSearch])

    const handleDownloadSlipGaji = (slipId) => {
        window.open(`${process.env.REACT_APP_API_URL}slip-gaji/${id}/${slipId}`, '_blank')
    }
    
    const gajiTable = [
        {
            title: 'Gaji Pokok',
            dataIndex: 'gaji_pokok',
        },
        {
            title: 'Tunjangan Jabatan',
            dataIndex: 'tunjangan',
        },
        {
            title: 'Lembur',
            dataIndex: 'lembur',
        },
        {
            title: 'Potongan Pinjaman',
            dataIndex: 'potongan_pinjaman',
        },
        {
            title: 'Bonus Tahunan',
            dataIndex: 'bonus',
        }
    ]

    const tableColumns = [
        {
            Header: "No",
            accessor: "nomor",
            width: "20px",
        },
        {
            Header: 'Nama',
            accessor: 'nama',
        },
        {
            Header: 'Total Masuk',
            accessor: 'presensi_count',
            Cell: ({ row }) => {
                return (
                    <div style={{ textAlign: 'center' }}>
                        {row?.original?.presensi_count}
                    </div>
                )
            }
        },
        {
            Header: 'Total',
            accessor: 'total'
        },
        {
            Header: 'Aksi',
            accessor: 'aksi',
            Cell: ({ row }) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '8px'
                        }}
                    >
                        <OverlayTrigger 
                            placement="bottom"
                            overlay={
                            <Tooltip id="button-tooltip">Print Gaji</Tooltip>
                            }
                        >
                            <button
                                style={{
                                    backgroundColor: '#FFFFFF',
                                    padding: '8px',
                                    borderRadius: '8px',
                                    border: '1px solid #D9D9D9',
                                }}
                                onClick={() => {
                                    handleDownloadSlipGaji(row.original.slip_gaji[0].id)
                                }}
                            >
                                <PrintIcon />
                            </button>
                        </OverlayTrigger>
                        <OverlayTrigger 
                            placement="bottom"
                            overlay={
                                <Tooltip id="button-tooltip">Detail Gaji</Tooltip>
                            }
                        >
                            <button
                                style={{
                                    backgroundColor: '#FFFFFF',
                                    padding: '8px',
                                    borderRadius: '8px',
                                    border: '1px solid #D9D9D9',
                                }}
                                onClick={() => {
                                    setModalVisible(true)
                                    setDataPegawaiDetail(row.original)
                                }}
                            >
                                <InfoIcon />
                            </button>
                        </OverlayTrigger>
                    </div>
                )
            }
        }
    ]
    
    return (
        <>
            {!loading && dataPeriode?.tanggal_mulai && dataPeriode?.tanggal_selesai && (
                <PageHeader
                    title={`Daftar Gaji Periode ${format(new Date(dataPeriode?.tanggal_mulai), 'dd MMMM yyyy', { locale: localeid }) ?? ''} - ${format(new Date(dataPeriode?.tanggal_selesai), 'dd MMMM yyyy', { locale: localeid }) ?? ''}`}
                />
            )}

            <DynamicTable
                title="nama"
                loadingRender={loading}
                childLeft={(() => {
                    return (
                        <button
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '12px',
                                backgroundColor: '#019443',
                                color: '#FFFFFF',
                                padding: '8px 16px',
                                borderRadius: '8px',
                                border: 'none',
                            }}
                            onClick={() => {
                                window.open(`${process.env.REACT_APP_API_URL}slip-gaji/${id}`, '_blank')
                            }}
                        >
                            <PrintIcon color={"white"} />
                            <span>
                                Cetak Slip Gaji per Bidang
                            </span>
                        </button>
                    )
                })()}
                customData={dataPegawai.map((item, idx) => {
                    const penggajian = item?.slip_gaji?.find((item) => item?.periode_gaji_id === Number(id))
                    const presensi = 
                        penggajian?.presensi_biasa +
                        penggajian?.presensi_lembur +
                        penggajian?.presensi_bonus +
                        penggajian?.presensi_tunjangan 
                    
                    return {
                        ...item,
                        nomor: idx + 1,
                        nama: item?.nama,
                        presensi,
                        total: toRupiah(penggajian?.total),
                    }
                })}
                columns={tableColumns}
                searchableCustomData={true}
                onSearchCustomData={(value) => setSearch(value)}
            />

            <Modal
                centered
                show={modalVisible}
                onHide={() => setModalVisible(false)}
            >
                <Modal.Body>
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                borderBottom: '1px solid #D9D9D9',
                                paddingBottom: '16px',
                            }}
                        >
                            <div>
                                <div
                                    style={{
                                        fontSize: '30px',
                                        fontWeight: 'bold',
                                        color: 'black'
                                    }}
                                >
                                    Slip Gaji
                                </div>
                                <div style={{ color: 'black' }}>
                                    Periode {dataPeriode?.tanggal_mulai ?? ''} - {dataPeriode?.tanggal_selesai ?? ''}
                                </div>
                            </div>
                            <img
                                src="/lowo-ijo.png"
                                alt="logo"
                            />
                        </div>
                        <div
                            style={{
                                padding: '12px 0px',
                            }}
                        >
                            <div
                                style={{
                                    paddingBottom: '2px',
                                    fontSize: '22px',
                                    fontWeight: 'bold',
                                }}
                            >
                                {dataPegawaiDetail?.nama}
                            </div>
                            <div
                                style={{
                                    fontSize: '18px',
                                    marginBottom: '2px'
                                }}
                            >
                                {dataPegawaiDetail?.nip}
                            </div>
                            <div
                                style={{
                                    fontSize: '18px',
                                    marginBottom: '2px'
                                }}
                            >
                                <p>
                                    {`Jumlah Kerja ${dataPegawaiDetail?.slip_gaji?.find((item) => item?.periode_gaji_id === Number(id))?.presensi_biasa ?? 0} hari`}
                                </p>
                                <p>
                                    {`Jumlah Lembur ${dataPegawaiDetail?.slip_gaji?.find((item) => item?.periode_gaji_id === Number(id))?.presensi_lembur ?? 0} hari`}
                                </p>
                            </div>
                        </div>
                        <div
                            style={{
                                marginTop: '12px',
                                borderBottom: '1px solid #D9D9D9',
                                paddingBottom: '12px'
                            }}
                        >
                            {gajiTable.map((item) =>  {
                                return (
                                    <div
                                        key={item?.key}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginBottom: '8px',
                                            color: 'black',
                                            fontSize: '18px'
                                        }}
                                    >
                                        <div>
                                            {item.title}
                                        </div>
                                        <div>
                                            {toRupiah(slipGaji?.[item.dataIndex] ?? 0)}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginTop: '12px',
                                color: 'black',
                                fontSize: '18px'
                            }}
                        >
                            <div>
                                Total
                            </div>
                            <div>
                                {toRupiah(slipGaji?.total ?? 0)}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default DetailPeriode;