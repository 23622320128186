import PageHeader from "components/page-header";
import DynamicTable from "components/dynamic-table";
import { PRESENSI_STATUS } from "utils/constant";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { request } from "utils/request";
import ModalDelete from "components/modal-delete";
import { setTriggerTable } from "redux/actions";
import { useDispatch } from "react-redux";
import FilterPresensi from "components/presensi/filterPresensi";
import { format } from "date-fns";
import ModalPresensi from "components/presensi/modal-konfirmasi";
import { useNavigate } from "react-router-dom";
import { toTitleCase } from "utils/helper";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import useHasPermission from "hooks/useHasPermission";
import CheckPermission from "components/CheckPermission";

const Presensi = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [dataTablePick, setDataTablePick] = useState(null);
  const [data, setData] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();
  const [filterParams, setFilterParams] = useState("");

  const handleDeletePresence = async () => {
    try {
      setLoading(true);
      await request
        .delete(`/api/presensi-karyawan/${dataTablePick?.id}`)
        .then(() => {
          toast.success("Berhasil menghapus presensi");
          dispatch(setTriggerTable(true));
        });
    } catch (error) {
      console.log(error);
      toast.error("Gagal menghapus presensi");
    } finally {
      setLoading(false);
      setDeleteModalVisible(false);
    }
  };

  const getPresensiData = async () => {
    try {
      setLoading(true);
      const response = await request.get(
        "/api/presensi?limit=1&sort=id&order=desc"
      );
      if (response && response?.data?.data?.length > 0) {
        const { id, jam_masuk, jam_keluar, status } = response?.data?.data[0];
        setData({
          id,
          start: jam_masuk,
          end: jam_keluar,
          status: PRESENSI_STATUS.find((item) => item.value === status),
          date: format(new Date().getDate(), "yyyy-MM-dd"),
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleAddPresence = () => {
    if (data) {
      setModalVisible(true);
    } else navigate("/presensi/add");
  };

  const tableColumns = [
    {
      Header: "NO",
      accessor: "nomor",
      width: "20px",
    },
    {
      Header: "Nama",
      accessor: "karyawan.nama",
    },
    {
      Header: "Bidang",
      accessor: "karyawan.bidang",
      Cell: ({ row }) => toTitleCase(row.original.karyawan.bidang),
    },
    {
      Header: "Jam Masuk",
      accessor: "jam_masuk",
    },
    {
      Header: "Jam Pulang",
      accessor: "jam_keluar",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => {
        return (
          <div
            style={{
              backgroundColor: PRESENSI_STATUS.find(
                (item) => item.value === value
              ).style.backgroundColor,
              color: "white",
              borderRadius: "8px",
              padding: "5px 20px",
              width: "fit-content",
            }}
          >
            {PRESENSI_STATUS.find((item) => item.value === value).tableLabel}
          </div>
        );
      },
    },
    {
      Header: "Aksi",
      accessor: "aksi",
      Cell: (rows) => {
        return (
          <CheckPermission permission="delete-presensi-karyawan">
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="button-tooltip">Hapus</Tooltip>}
            >
              <button
                style={{
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #DEDFE1",
                  padding: "4px 8px",
                  fontSize: "16px",
                  borderRadius: "2px",
                }}
                onClick={() => {
                  setDataTablePick(rows?.row?.original);
                  setDeleteModalVisible(true);
                }}
              >
                <i class="bi bi-trash"></i>
              </button>
            </OverlayTrigger>
          </CheckPermission>
        );
      },
    },
  ];

  const newQueryParams = useMemo(() => {
    return Object.keys(filterParams).reduce((cur, acc) => {
      if (filterParams[acc]) {
        return cur + `&${acc}=${filterParams[acc]}`;
      }
      return cur;
    }, "");
  }, [filterParams]);

  useEffect(() => {
    getPresensiData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setTriggerTable(false));
    }, 500);
  }, []);

  const mayCreatePresensi = useHasPermission("create-presensi-karyawan");
  return (
    <>
      <PageHeader
        title="Daftar Presensi"
        {...(mayCreatePresensi && {
          onClick: handleAddPresence,
        })}
      />
      <FilterPresensi
        setFilterParams={setFilterParams}
        filterParams={filterParams}
      />
      <DynamicTable
        withSearch={false}
        title="Presensi"
        columns={tableColumns}
        url={`/api/presensi-karyawan`}
        filter={filterParams}
        newQueryParams={newQueryParams}
      />

      <ModalDelete
        loading={loading}
        show={deleteModalVisible}
        onClick={handleDeletePresence}
        titleAction="Hapus"
        loadingText="menghapus presensi..."
        title={`Apakah anda yakin ingin menghapus presensi harian ${dataTablePick?.karyawan?.nama}?`}
        onHide={() => setDeleteModalVisible(false)}
      />

      <ModalPresensi
        show={modalVisible}
        title="Modal Konfirmasi"
        onClickOutside={() => setModalVisible(false)}
        onHide={() =>
          navigate("/presensi/add", {
            state: {
              data,
            },
          })
        }
        onConfirm={async () => {
          try {
            setLoading(true);
            const payload = {
              jam_masuk: data.start + ":00",
              jam_keluar: data.end + ":00",
              status: data.status.value,
              tanggal: format(new Date(), "yyyy-MM-dd"),
            };
            const response = await request
              .post("/api/presensi", payload)
              .then((res) => res);
            if (response) {
              toast.success(`Berhasil menambahkan`, { duration: 3000 });
              navigate("/presensi/new-presence", {
                state: {
                  data,
                },
              });
            }
          } catch (err) {
            toast.error("Gagal memasukkan presensi", { duration: 3000 });
            console.log(err);
          } finally {
            setLoading(false);
          }
        }}
        data={data}
      />
    </>
  );
};

export default Presensi;
