import { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { id } from 'date-fns/locale'

const DateTimeDisplay = () => {
    const [date, setDate] = useState(new Date())

    useEffect(() => {
        const interval = setInterval(() => {
            setDate(new Date().getTime())
        }, 1000)

        return () => clearInterval(interval)
    },[date])

    return (
        <div
            style={{
                fontSize: '20px',
                fontWeight: '600',
                color: '#019443'
            }}
        >
            {format(date, 'EEEE, dd MMMM yyyy | HH:mm', { locale: id })}
        </div>
    )
}

export default DateTimeDisplay;