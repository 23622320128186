import DynamicTable from "components/dynamic-table";
import PageHeader from "components/page-header";
import { format } from "date-fns";
import { id } from "date-fns/locale";
import { useMemo, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toRupiah, toTitleCase } from "utils/helper";

const DetailLaporanPendapatanPenginapan = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const columns = [
    {
      Header: "No",
      accessor: "nomor",
    },
    {
      Header: "Tanggal",
      accessor: "tanggal",
      Cell: ({ row }) =>
        format(new Date(row?.original?.tanggal_mulai), "dd MMMM yyyy", {
          locale: id,
        }),
    },
    {
      Header: "Nama",
      accessor: "nama",
    },
    {
      Header: "Alamat",
      accessor: "alamat",
    },
    {
      Header: "Nomor HP",
      accessor: "no_hp",
    },
    {
      Header: "Paket",
      accessor: "paket_penginapan.nama",
    },
    {
      Header: "Venue",
      accessor: "venue.nama",
      Cell: ({ row }) => toTitleCase(row?.original?.venue?.nama),
    },
    {
      Header: "Keluar",
      accessor: "keluar",
      Cell: ({ row }) => {
        return (
          <div style={{ display: "flex", gap: "8px" }}>
            {row?.original?.pengeluaran_reservasi?.map((item) => {
              return (
                <div
                  style={{
                    backgroundColor: "#019443",
                    color: "white",
                    padding: "2px 6px",
                    borderRadius: 4,
                  }}
                >
                  {`${item.nama}: ${toRupiah(item.harga)}`}
                </div>
              );
            })}
          </div>
        );
      },
    },
  ];

  const tanggal = useMemo(() => {
    return new URLSearchParams(search).get("tanggal");
  }, []);

  useEffect(() => {
    if (!tanggal) return navigate("/hasil-usaha/penginapan/laporan-pendapatan");
  }, [tanggal]);

  return (
    <>
      <PageHeader
        title={`Detail Pendapatan Penginapan Tanggal ${format(
          new Date(tanggal),
          "dd MMMM yyyy"
        )}`}
        additionalButton={{
          title: "Kembali",
          icon: "bi bi-chevron-left",
          type: "btn-danger",
          onClick: () => navigate("/hasil-usaha/penginapan/laporan-pendapatan"),
        }}
      />
      <DynamicTable
        columns={columns}
        url={`/api/reservasi`}
        newQueryParams={`&sort=tanggal_mulai&tanggal_mulai=${tanggal}`}
      />
    </>
  );
};

export default DetailLaporanPendapatanPenginapan;
