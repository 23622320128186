import { Modal } from "react-bootstrap";
import { Oval } from "react-loader-spinner";

const LoadingGlobal = () => {
  return (
    <Modal centered show size="sm" className="breksi-loading">
      <Modal.Body className="d-flex justify-content-center align-items-center">
        <Oval
          height={50}
          width={50}
          color="#59D5C5"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#59D5C5"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </Modal.Body>
    </Modal>
  );
};

export default LoadingGlobal;
