import DynamicTable from "components/dynamic-table";
import PageHeader from "components/page-header";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { toRupiah } from "utils/helper";
import { request } from "utils/request";
import { id as localeid } from 'date-fns/locale'

const DetailRekapitulasiUsahaToilet = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [dataRekapitulasi,setDataRekapitulasi] = useState(null)
    const [dataPendapatan,setDataPendapatan] = useState([])

    const columns = [
        {
            Header: 'No',
            accessor: 'nomor',
            Cell: ({ row }) => {
                return row.index + 1
            }
        },
        {
            Header: 'Area Toilet',
            accessor: 'toilet',
            Cell: ({ row }) => {
                return row.original.lokasi.nama
            }
        },
        {
            Header: 'Total',
            accessor: 'total',
            Cell: ({ row }) => toRupiah(row?.original?.pendapatan)
        }
    ]

    const fetchDataRekapitulasi = () => {
        request.get(`/api/periode-usaha-toilet/${id}`)
            .then((res) => {
                console.log(res?.data?.data)
                setDataRekapitulasi(res?.data?.data)
                setDataPendapatan(res?.data?.data?.pendapatan)
            })
            .catch((err) => {
                console.log(err)
                toast.error('Gagal mengambil data')
            })
    }

    useEffect(() => {
        fetchDataRekapitulasi()
    },[])

    return (
        <>
            <PageHeader
                {...( dataRekapitulasi && {title:
                    `Detail Laporan Periode ${format(new Date(dataRekapitulasi?.tanggal_mulai), 'dd MMMM yyyy', { locale: localeid })} - ${format(new Date(dataRekapitulasi?.tanggal_selesai), 'dd MMMM yyyy', { locale: localeid })}`
                })}
                additionalButton={{
                    type: 'btn-danger',
                    title: 'Kembali',
                    icon: 'bi bi-chevron-left',
                    onClick: () => navigate('/hasil-usaha/toilet/rekapitulasi')
                }}
            />
            <DynamicTable
                title="area toilet"
                columns={columns}
                customData={dataPendapatan}
                searchableCustomData={true}
                onSearchCustomData={(key) => {
                    if (!key) return setDataPendapatan(dataRekapitulasi.pendapatan)
                    return setDataPendapatan(dataRekapitulasi.pendapatan.filter((item) => {
                        return String(item.lokasi.nama).toLowerCase().includes(String(key).toLowerCase())
                    }))
                }}
            />
        </>
    )
}

export default DetailRekapitulasiUsahaToilet;