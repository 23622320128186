import CreateContainer from "containers/create-container";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import FormInput from "components/form-input";
import { request } from "utils/request";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

const CreateBiayaPengeluaran = () => {
    const {search} = useLocation()
    
    const meta = useMemo(() => {
        const redirect = new URLSearchParams(search).get('redirect')
        const payload = new URLSearchParams(search).get('payload')
        return  { redirect, payload }
    },[search])
    
    const [loading,setLoading] = useState(false)
    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm()

    const onSubmitBiaya = async (values) => {
        setLoading(true)
        request.post('/api/pengeluaran-reservasi', {
            nama: values.nama,
            harga: String(values?.harga).replace('Rp ', '').split(',').join('')
        })
        .then(() => {
            toast.success('Berhasil menambahkan biaya')
            if (meta.redirect) return navigate(`${meta.redirect}${ meta.payload && `?payload=${meta.payload}`}`)
            navigate('/reservasi/biaya-pengeluaran')
        })
        .catch(err => {
            console.log(err)
        })
        .finally(() => setLoading(false))
    }

    return (
        <CreateContainer
            title="Tambah Biaya Pengeluaran"
            loading={loading}
            loadingText="Menambahkan"
            onSubmit={handleSubmit(onSubmitBiaya)}
            {...(meta.redirect && {
                onCancel: () => {navigate(`${meta.redirect}${ meta.payload && `?payload=${meta.payload}`}`)}
            })}
        >
            <FormInput
                required
                label="Nama biaya"
                name="nama"
                type="text"
                errors={errors}
                register={register}
                placeholder="masukkan nama biaya"
                errorMessage="Nama biaya tidak boleh kosong!"
            />
            <FormInput
                required
                errors={errors}
                name="harga"
                label="Harga"
                control={control}
                inputType="price"
                placeholder="Masukkan harga"
                errorMessage="Harga tidak boleh kosong!"
            />
        </CreateContainer>
    )
}

export default CreateBiayaPengeluaran;