import { useState } from "react";
import DynamicTable from "components/dynamic-table";
import PageHeader from "components/page-header";
import { useForm } from "react-hook-form";
import FormInput from "components/form-input";
import { format } from "date-fns";
import { id } from "date-fns/locale";
import { toRupiah } from "utils/helper";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { request } from "utils/request";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setTriggerTable } from "redux/actions";
import { useMemo } from "react";
import useHasPermission from "hooks/useHasPermission";
import CheckPermission from "components/CheckPermission";
import ModalDelete from "components/modal-delete";

const RekapitulasiTiketingMingguan = () => {
  const [loading, setLoading] = useState(false);
  const [dataTablePick, setDataTablePick] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const mayCreatePeriodeTiketing = useHasPermission(
    "create-periode-usaha-tiketing"
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    control,
    watch,
    formState: { errors },
  } = useForm();
  const filterTanggal = watch("tanggal");
  const newQueryParams = useMemo(() => {
    if (!filterTanggal) return "";
    const [from, to] = String(filterTanggal).split(" - ");
    return `${from ? `&from=${from}` : ""}${to ? `&to=${to}` : ""}`;
  }, [filterTanggal]);

  const confirmRekapitulasi = (data) => {
    request
      .patch(`api/periode-usaha-tiketing/${data.id}`, {
        konfirmasi: true,
      })
      .then((res) => {
        toast.success("Berhasil mengonfirmasi periode");
        dispatch(setTriggerTable(true));
      })
      .catch((err) => {
        console.log(err);
        toast.error("Gagal melakukan konfirmasi");
      });
  };

  const onDeleteData = () => {
    setLoading(true);
    request
      .delete(`/api/periode-usaha-tiketing/${dataTablePick?.id}`)
      .then((res) => {
        toast.success(`Periode usaha tiketing berhasil dihapus`, {
          duration: 5000,
        });
        dispatch(setTriggerTable(true));
        setIsModalOpen(false);
      })

      .finally(() => setLoading(false));
  };

  const columns = [
    {
      Header: "No",
      accessor: "nomor",
    },
    {
      Header: "Periode",
      accessor: "periode",
      Cell: ({ row }) => {
        const [tanggal_mulai, bulan_mulai, tahun_mulai] = String(
          row?.original?.tanggal_mulai
        ).split("-");
        const [tanggal_selesai, bulan_selesai, tahun_selesai] = String(
          row?.original?.tanggal_selesai
        ).split("-");
        const start = format(
          new Date(`${tahun_mulai}-${bulan_mulai}-${tanggal_mulai}`),
          "dd MMMM yyyy",
          { locale: id }
        );
        const end = format(
          new Date(`${tahun_selesai}-${bulan_selesai}-${tanggal_selesai}`),
          "dd MMMM yyyy",
          { locale: id }
        );
        return `${start} - ${end}`;
      },
    },
    {
      Header: "Total",
      accessor: "total",
      Cell: ({ row }) => toRupiah(row?.original?.total_pendapatan_bersih),
    },
    {
      Header: "Aksi",
      accessor: "aksi",
      Cell: ({ row }) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <CheckPermission permission="view-periode-usaha-tiketing">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip">Detail</Tooltip>}
              >
                <button
                  type="button"
                  className="btn btn-sm btn-square btn-neutral text-warning-hover ms-2"
                  onClick={() => {
                    navigate(
                      `/hasil-usaha/tiketing/rekapitulasi/${row.original.id}/detail`
                    );
                  }}
                >
                  <i className="bi bi-info-circle"></i>
                </button>
              </OverlayTrigger>
            </CheckPermission>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="button-tooltip">Persentase</Tooltip>}
            >
              <button
                type="button"
                className="btn btn-sm btn-square btn-neutral text-warning-hover ms-2"
                onClick={() => {
                  navigate(
                    `/hasil-usaha/tiketing/rekapitulasi/${row.original.id}/persentase`
                  );
                }}
              >
                <i className="bi bi-percent"></i>
              </button>
            </OverlayTrigger>
            <CheckPermission permission="konfirmasi-laporan">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="button-tooltip">
                    {row?.original?.konfirmasi
                      ? "Sudah dikonfirmasi"
                      : "Belum dikonfirmasi"}
                  </Tooltip>
                }
              >
                <button
                  type="button"
                  className="btn btn-sm btn-square btn-neutral text-warning-hover ms-2"
                  onClick={() => {
                    if (row?.original?.konfirmasi) return;
                    confirmRekapitulasi(row?.original);
                  }}
                >
                  <i
                    className={`bi ${
                      !row?.original?.konfirmasi ? "bi-clock" : "bi-check2"
                    }`}
                  ></i>
                </button>
              </OverlayTrigger>
            </CheckPermission>
            {!row?.original?.konfirmasi && (
              <button
                type="button"
                className="btn btn-sm btn-square btn-neutral text-danger-hover ms-2"
                onClick={() => {
                  setIsModalOpen(true);
                  setDataTablePick(row.original);
                }}
              >
                <i className="bi bi-trash"></i>
              </button>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <PageHeader
        title="Rekapitulasi Pendapatan Tiketing Mingguan"
        {...(mayCreatePeriodeTiketing && {
          to: "/hasil-usaha/tiketing/rekapitulasi/create",
        })}
      />
      <div style={{ marginBottom: "24px" }}>
        <FormInput
          name="tanggal"
          label="Periode"
          inputType="date"
          errors={errors}
          control={control}
          placeholder="Pilih periode"
          errorMessage="Waktu tidak boleh kosong!"
          isClearable={true}
          mb={0}
          containerStyle={{
            width: "402px",
          }}
          options={{
            mode: "range",
          }}
        />
      </div>
      <DynamicTable
        withSearch={false}
        columns={columns}
        url="/api/periode-usaha-tiketing"
        newQueryParams={newQueryParams}
      />
      <ModalDelete
        loading={loading}
        show={isModalOpen}
        onClick={onDeleteData}
        titleAction="Hapus"
        loadingText="menghapus periode usaha tiketing..."
        title={`Apakah anda yakin ingin menghapus periode usaha tiketing ${dataTablePick?.tanggal_mulai} - ${dataTablePick?.tanggal_selesai}?`}
        onHide={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default RekapitulasiTiketingMingguan;
