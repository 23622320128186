const PrintIcon = ({ color = 'black'}) => {
    return (
        <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.1775 6.70076V2C20.1775 1.44772 19.7298 1 19.1775 1H6.66309C6.1108 1 5.66309 1.44772 5.66309 2V6.70076" stroke={color} stroke-width="1.2" stroke-miterlimit="22.9256" stroke-linecap="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M23.4713 17.6698H2.42952C1.64328 17.6698 1 17.0265 1 16.2403V8.13069C1 7.34446 1.64328 6.70117 2.42952 6.70117H23.4713C24.2575 6.70117 24.9008 7.34446 24.9008 8.13069V16.2403C24.9008 17.0265 24.2575 17.6698 23.4713 17.6698Z" stroke={color} stroke-width="1.2" stroke-miterlimit="22.9256"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.46591 14.2988H19.3983C19.8015 14.2988 20.1313 14.6288 20.1313 15.0318V23C20.1313 23.4032 19.8013 23.733 19.3983 23.733H6.46591C6.06266 23.733 5.73291 23.4031 5.73291 23L5.73291 15.0318C5.73291 14.6286 6.06284 14.2988 6.46591 14.2988Z" stroke={color} stroke-width="1.2" stroke-miterlimit="22.9256"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9506 9.21484H20.1513C20.6129 9.21484 20.9906 9.59249 20.9906 10.0541C20.9906 10.5158 20.6129 10.8934 20.1513 10.8934H17.9506C17.489 10.8934 17.1113 10.5158 17.1113 10.0541C17.1113 9.59249 17.489 9.21484 17.9506 9.21484Z" fill={color}/>
            <path d="M8.5 20.5C8.5 20.2239 8.72386 20 9 20H17C17.2761 20 17.5 20.2239 17.5 20.5C17.5 20.7761 17.2761 21 17 21H9C8.72386 21 8.5 20.7761 8.5 20.5Z" fill={color} />
        </svg>
    )
}

export default PrintIcon;