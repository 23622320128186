const DUMMY_DATAS_TABLE = [
  {
    no: "1.",
    nama_kegiatan: "Pengembangan Kawasan",
    opd: "Dinas Pertanian",
    deadline: "23-01-2022",
    status: "Sedang dikerjakan",
    penyedia_jasa: "CV. Harapan Jasa",
    progres: 38,
  },
  {
    no: "2.",
    nama_kegiatan: "Pengembangan Kawasan",
    opd: "Dinas Pertanian",
    deadline: "23-01-2022",
    status: "Sedang dikerjakan",
    penyedia_jasa: "CV. Harapan Jasa",
    progres: 38,
  },
  {
    no: "3.",
    nama_kegiatan: "Pengembangan Kawasan",
    opd: "Dinas Pertanian",
    deadline: "23-01-2022",
    status: "Sedang dikerjakan",
    penyedia_jasa: "CV. Harapan Jasa",
    progres: 38,
  },
  {
    no: "4.",
    nama_kegiatan: "Pengembangan Kawasan",
    opd: "Dinas Pertanian",
    deadline: "23-01-2022",
    status: "Sedang dikerjakan",
    penyedia_jasa: "CV. Harapan Jasa",
    progres: 38,
  },
  {
    no: "5.",
    nama_kegiatan: "Pengembangan Kawasan",
    opd: "Dinas Pertanian",
    deadline: "23-01-2022",
    status: "Sedang dikerjakan",
    penyedia_jasa: "CV. Harapan Jasa",
    progres: 38,
  },
];

const CHANGELOG = [
  {
    name: "Menghapus font inter",
  },
  {
    name: "Menambahkan logo",
  },
  {
    name: "Menambahkan dummy data table",
  },
  {
    name: "Menambahkan perfect scrollbar pada sidebar",
  },
  {
    name: "Menambahkan modal update app",
  },
];

const DATA_TYPE = {
  STRING: "string",
  UNDEFINED: "undefined",
};

const STATUS_CODE = {
  200: 200,
  400: 400,
  409: 409,
  404: 404,
  422: 422,
  500: 500,
};

const USER_TYPE = {
  SUPER_USER: 1,
  ADMIN_BAPPEDA: 2,
  USER_OPD: 3,
  LISTS: [
    {
      label: "Superadmin",
      value: 1,
    },
    {
      label: "Admin Bappeda",
      value: 2,
    },
    {
      label: "OPD",
      value: 3,
    },
  ],
};

const INPUT_TYPE = {
  INPUT: "input",
  TEXT: "text",
  TEXTAREA: "textarea",
  SELECT: "select",
  PASSWORD: "password",
  CHECKBOX: "checkbox",
  SWITCH: "switch",
  PRICE: "price",
  SELECT_SYNC: "select_sync",
  SELECT_CREATABLE: "select_creatable",
  UPLOAD_IMAGE: "upload_image",
  TEXT_EDITOR: "text_editor",
  RADIO: "radio",
  COLOR: "color",
  YEAR: "year",
  DATE: "date",
  IMAGE: "image",
  PERCENT: "percent",
  PREFIX_SUFFIX: "prefix_suffix",
  UPLOAD_IMAGES_GRID: "upload_images_grid",
  HOURS: 'hours',
  TIME: 'time',
  QUILL: 'quill',
  NUMBER: 'number',
  PHONE: 'phone',
  NIK: 'nik',
  REKENING: 'rekening'
};

const DROPDOWN_TYPE_USER = [
  {
    id: 1,
    label: "SUPER ADMIN",
  },
  {
    id: 2,
    label: "ADMIN BAPPEDA",
  },
  {
    id: 3,
    label: "ADMIN OPD",
  },
];

const REACT_SELECT_CUSTOM_STYLES = {
  control: (provided, state) => ({
    ...provided,
    border: `1px solid ${state.isFocused ? "#019543" : "#e7eaf0"}`,
    boxShadow: state.isFocused ? "0 0 0 3px rgba(1, 149, 68, 0.3)" : "none",
    '&:hover': {
        border: '1px solid #019543',
    },
  }),
  menu: (styles) => ({
    ...styles,
    borderRadius: 0,
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "#8898a9",
    marginLeft: ".75rem",
  }),
  input: (styles) => ({
    ...styles,
    marginLeft: ".75rem",
    padding: ".35rem 0 .35rem 0",
    boxShadow: "none",
  }),
  option: (styles, { isSelected, isFocused }) => ({
    ...styles,
    // backgroundColor: isSelected ? "#019543" : isFocused ? "#019543" : undefined,
    // color: isFocused ? "#fff" : isSelected ? "#fff" : undefined,
    backgroundColor: isSelected ? '#FFFFFF' : isFocused ? '#019543' : undefined,
    color: isSelected ? '#019543' : isFocused ? '#FFFFFF' : undefined,
  }),
};

const LAYER_OPTIONS = [
  {
    label: "DESA",
    value: "desa",
  },
  {
    label: "KECAMATAN",
    value: "kecamatan",
  },
];

const DEFAULT_CHOOSE = [
  {
    label: "SEMUA",
    value: "semua",
  },
];

const TIPE_LAYER = {
  POINT: "Point",
  LINE_STRING: "LineString",
  POLYGON: "Polygon",
  POLYLINE: "Polyline",
  MULTILINESTRING: "MultiLineString",
};

const MONTHS = [
  {
    label: "Januari",
    value: 1,
  },
  {
    label: "Februari",
    value: 2,
  },
  {
    label: "Maret",
    value: 3,
  },
  {
    label: "April",
    value: 4,
  },
  {
    label: "Mei",
    value: 5,
  },
  {
    label: "Juni",
    value: 6,
  },
  {
    label: "Juli",
    value: 7,
  },
  {
    label: "Agustus",
    value: 8,
  },
  {
    label: "September",
    value: 9,
  },
  {
    label: "Oktober",
    value: 10,
  },
  {
    label: "November",
    value: 11,
  },
  {
    label: "Desember",
    value: 12,
  },
];

const BASE_MAP = {
  GOOGLE_MAP: "GOOGLE_MAP",
  GOOGLE_SATELITE: "GOOGLE_SATELITE",
};

const LEGENDS_AKTIVITAS = {
  0: "red",
  50: "orange",
  100: "green",
  LISTS: [
    {
      label: "0%",
      value: "red",
    },
    {
      label: "50%",
      value: "orange",
    },
    {
      label: "100%",
      value: "green",
    },
  ],
};

const CENTER_MAP = [-1.1077600040995492, 134.31037070844107];

const DEFAULT_MASK_OPTIONS = {
  includeThousandsSeparator: true,
  //   thousandsSeparatorSymbol: ",",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 5, // how many digits allowed after the decimal
  integerLimit: 20, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

const PRESENSI_STATUS = [
  {
    label: 'Jam Kerja Reguler',
    value: 1,
    tableLabel: 'Jam Kerja Reguler',
    style: {
      backgroundColor: '#2dce89',
    },
  },
  {
    label: 'Lembur',
    value: 2,
    tableLabel: 'Jam Lembur',
    style: {
      backgroundColor: '#F6412D'
    }
  }
]

const PERIZINAN_STATUS = [
  {
    label: 'Sakit',
    value: 1,
  },
  {
    label: 'Lainnya',
    value: 2,
  }
]

const GOLONGAN_KARYAWAN = [
  {
    label: 'Junior',
    value: 3,
  },
  {
    label: 'Middle',
    value: 2,
  },
  {
    label: 'Senior',
    value: 1,
  }
]

const PENGGAJIAN_GOLONGAN = {
  senior: 1,
  middle: 2,
  junior: 3,
}

const PENGGAJIAN_TIPE = {
  pokok: 1,
  lembur: 2,
  tunjangan: 3,
  bonus: 4
}

export const JENIS_PENGINAPAN = [
  {
    value: 1,
    label: 'Camping',
  },
  {
    value: 2,
    label: 'Homestay',
  }
]

export {
  DUMMY_DATAS_TABLE,
  CHANGELOG,
  DATA_TYPE,
  STATUS_CODE,
  USER_TYPE,
  INPUT_TYPE,
  DROPDOWN_TYPE_USER,
  REACT_SELECT_CUSTOM_STYLES,
  LAYER_OPTIONS,
  DEFAULT_CHOOSE,
  TIPE_LAYER,
  MONTHS,
  BASE_MAP,
  LEGENDS_AKTIVITAS,
  CENTER_MAP,
  DEFAULT_MASK_OPTIONS,
  PRESENSI_STATUS,
  PERIZINAN_STATUS,
  GOLONGAN_KARYAWAN,
  PENGGAJIAN_GOLONGAN,
  PENGGAJIAN_TIPE
};
