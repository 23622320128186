import { Helmet } from "react-helmet";
import { useNavigate, Link } from "react-router-dom";

const PageHeader = ({
  title,
  btnIcon = "bi bi-plus-square-dotted",
  btnTitle = "Tambah",
  to,
  onClick = null,
  backAction,
  newTab = false,
  additionalButton = null,
  withBorder = TextTrackCueList
}) => {
  const navigate = useNavigate();

  return (
    <div>
      <Helmet title={`${title ?? ""} - Taman Tebing Breksi`} />

      <div className={`${withBorder && 'border-bottom' } row align-items-center mb-5 py-5 d-flex`}>
        <div className="col-sm col-12">
          <div className="d-flex">
            {/* {title?.map((el, idx) => (
              <h1 className="h2 ls-tight">
                <span className="d-inline-block text-primary me-1">{el}</span>
                {idx !== title?.length - 1 && (
                  <i
                    class="bi bi-chevron-right me-1"
                    style={{ fontSize: "20px", color: "#5c60f5" }}
                  ></i>
                )}
              </h1>
            ))} */}
            <h1 className="h2 ls-tight">
              <span
                style={{ letterSpacing: ".05rem" }}
                className="d-inline-block text-primary me-1"
              >
                {title}
              </span>
            </h1>
          </div>
        </div>

        <div
        className="col-sm-auto col-12 mt-4 mt-sm-0"
          style={{
            display: "flex",
            gap: '12px',
          }}
        >
        {
          additionalButton && (
            <button
              className={`btn btn-sm ${additionalButton?.type ? additionalButton?.type : 'btn-primary'}`}
              onClick={additionalButton.onClick}
            >
              {additionalButton?.icon && (
                <i className={`${additionalButton?.icon}`} style={{ marginRight: '8px' }}></i>
              )}
              {additionalButton.title}
            </button>
          )
        }

        {(to || onClick) && (
          <div>
            <div className="hstack gap-2 justify-content-sm-end">
              {backAction && (
                <span
                  className="btn btn-sm btn-danger"
                  onClick={() => navigate(-1)}
                >
                  <span className="pe-2">
                    <i className="bi bi-chevron-left"></i>
                  </span>
                  <span>Kembali</span>
                </span>
              )}

              { !onClick ? (
                  <Link
                    className="btn btn-sm btn-primary"
                    to={to}
                    {
                      ...(newTab && {
                        target: "_blank",
                      })
                    }
                  >
                    <span className="pe-2">
                      <i className={btnIcon}></i>
                    </span>
                    <span>{btnTitle}</span>
                  </Link>
              ) : (
                <button
                  onClick={onClick}
                  className="btn btn-sm btn-primary"
                >
                  <span className="pe-2">
                    <i className={btnIcon}></i>
                  </span>
                  <span>{btnTitle}</span>
                </button>
              )}
            </div>
          </div>
        )}
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
