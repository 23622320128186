import CreateContainer from "containers/create-container";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { request } from "utils/request";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import FormInput from "components/form-input";

const CreateFasilitas = () => {
    const navigate = useNavigate()

    const [loading,setLoading] = useState(false)
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()

    const onSubmitData = (values) => {
        setLoading(true)
        request
            .post("/api/fasilitas", values)
            .then((res) => {
                toast.success(`${res.data.message}`, { duration: 3000 });
                navigate("/data-master/fasilitas");
            })
            .finally(() => setLoading(false));
    }

    return (
        <CreateContainer
            title="Tambah Fasilitas"
            loading={loading}
            loadingText="Menambahkan fasilitas..."
            onSubmit={handleSubmit(onSubmitData)}
        >
            <FormInput
                required
                label="Nama fasilitas"
                name="nama"
                type="text"
                errors={errors}
                register={register}
                placeholder="Masukkan nama fasilitas"
                errorMessage="Nama fasilitas tidak boleh kosong!"
            />
        </CreateContainer>
    )
}

export default CreateFasilitas;