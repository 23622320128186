import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useRoutes } from "react-router-dom";
import { setMenu } from "redux/actions";
import { routes } from "./routes";
import { useSelector } from "react-redux";

const Routers = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => {
    return state?.auth;
  });

  const permissions = useMemo(() => {
    return user?.permissions ?? [];
  }, [user]);

  const guardedRoutes = useMemo(() => {
    if (permissions?.length === 0) return routes;
    return routes
      .filter((route) => {
        if (route.permission && permissions?.length > 0) {
          return permissions?.includes(String(route?.permission));
        }
        return true;
      })
      .map((route) => {
        if (route.children) {
          const newChildren = route.children
            .filter((child) => {
              if (child.permission && permissions?.length > 0) {
                return permissions?.includes(String(child?.permission));
              }
              return true;
            })
            .map((item) => {
              if (item.children) {
                const children = item.children.filter((last) => {
                  if (last.permission && permissions?.length > 0) {
                    return permissions?.includes(String(last?.permission));
                  }
                  return true;
                });
                return {
                  ...item,
                  children,
                };
              } else return item;
            })
            .filter((item) => {
              if (item.children && item.children.length === 0) return false;
              return true;
            });
          return {
            ...route,
            children: newChildren,
          };
        } else return route;
      })
      .filter((item) => {
        if (item.children && item.children.length === 0) return false;
        return true;
      });
  }, [permissions]);

  const routing = useRoutes(guardedRoutes);

  useEffect(() => {
    dispatch(setMenu(guardedRoutes));
  }, [guardedRoutes, permissions, dispatch]);

  return routing;
};

export default Routers;
