import { useForm } from "react-hook-form";
import PageHeader from "components/page-header";
import FormInput from "components/form-input";
import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { request } from "utils/request";
import toast from "react-hot-toast";
import { toTitleCase } from "utils/helper";
import { PENGGAJIAN_TIPE } from "utils/constant";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const TunjanganJabatan = () => {
    const [positions,setPositions] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const {
        control,
        setValue,
        formState: { errors }
    } = useForm()

    const handleSaveData = () => {
        const payload =
            Object.keys(control._formValues)
                .map((item) => {
                    const [golongan,jabatan_id] = item.split('-')
                    return {
                        tipe: PENGGAJIAN_TIPE.tunjangan,
                        jabatan_id,
                        golongan,
                        total_gaji: !!control._formValues[item]
                            ? String(control._formValues[item]).replace('Rp ','').split(',').join('')
                            : null
                    }
                })
                .filter((item) => !!item.total_gaji)
            setLoading(true)
            request.post('/api/penggajian',{
                penggajian: payload
            })
                .then((res) => {
                    toast.success('Berhasil menyimpan data gaji tunjangan.')
                })
                .catch(err => {
                    toast.error('Gagal menyimpan data.')
                })
                .finally(() => setLoading(false))
    }

    const fetchPositions = async () => {
        setLoading(true)
        request.get('/api/jabatan?per_page=100')
            .then((res) => {
                setPositions(res?.data?.data)
            })
            .catch((err) => {
                toast.error('Gagal mengambil data bidang.')
            })
            .finally(() => setLoading(false))
    }

    const fetchPenggajianData = async () => {
        request.get('/api/penggajian')
            .then((res) => {
                const data = res?.data?.data?.filter((item) => item.tipe === PENGGAJIAN_TIPE.tunjangan)
                data.forEach((item) => {
                    setValue(`${item.golongan}-${item.jabatan_id}`,item.total_gaji)
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        fetchPositions()
        fetchPenggajianData()
    },[])

    return (
        <>
            <>
                <PageHeader
                    title="Setting Tunjangan"
                />
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(4, 1fr)',
                        marginBottom: '30px',
                        gap: '100px',
                        fontWeight: '500'
                    }}
                >
                    <div>Jabatan/Golongan</div>
                    <div>Junior</div>
                    <div>Middle</div>
                    <div>Senior</div>
                </div>
                {positions.map((item) => {
                    return (
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(4, 1fr)',
                                marginBottom: '30px',
                                gap: '100px',
                                fontWeight: '500'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'start'
                                }}
                            >
                                {toTitleCase(item.nama)}
                            </div>
                            <FormInput
                                required
                                errors={errors}
                                name={`1-${item.id}`}
                                control={control}
                                inputType="price"
                                placeholder="Tunjangan"
                                errorMessage="Harga tidak boleh kosong !"
                            />
                            <FormInput
                                required
                                errors={errors}
                                name={`2-${item.id}`}
                                control={control}
                                inputType="price"
                                placeholder="Tunjangan"
                                errorMessage="Harga tidak boleh kosong !"
                            />
                            <FormInput
                                required
                                errors={errors}
                                name={`3-${item.id}`}
                                control={control}
                                inputType="price"
                                placeholder="Tunjangan"
                                errorMessage="Harga tidak boleh kosong !"
                            />
                        </div>
                    )
                })}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        gap: '20px'
                    }}
                >
                    <Button
                        variant="neutral"
                        onClick={() => navigate('/penggajian/setting')}
                    >
                        Batal
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleSaveData}
                    >
                        {loading ? "Menambahkan setting gaji" : 'Simpan'}
                    </Button>
                </div>
            </>
        </>
    )
}

export default TunjanganJabatan;