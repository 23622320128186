import { format } from "date-fns";
import { USER_TYPE } from "./constant";
import { request } from "./request";
const xlsx = require('xlsx')

const replaceAll = (text, replaceFrom, replaceAfter) => {
  const regex = new RegExp(replaceFrom, "g");
  const results = text?.replace(regex, replaceAfter);

  return results;
};

const findActiveMenuInRoutes = (match, path, childs, pathname) => {
  if (match) {
    const isHaveChild = childs?.length > 0;

    if (isHaveChild) {
      const results = childs.some((child) => child.path === pathname);

      return results;
    }

    return path === match.pathname;
  }

  return false;
};

const replaceRoleName = (userType) => {
  switch (userType) {
    case USER_TYPE.SUPER_USER:
      return "Super Admin";
    case USER_TYPE.ADMIN_BAPPEDA:
      return "Admin Bappeda";
    default:
      return "Admin OPD";
  }
};

const base64Validation = (base64) =>
  base64.replace(/^data:image.+;base64,/, "");

const toRupiah = (angka) => {
  const rupiah = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  }).format(angka || 0);
  return `${rupiah} ,-`;
};

const formatArrayInTable = (arr) => (
  <td className="d-flex flex-column gap-3">
    {arr?.map((elem) => (
      <>
        <p>
          {elem?.nama}, {elem?.nilai}
        </p>
      </>
    ))}
  </td>
);

const getYears = (startYear = 1980) => {
  let currentYear = new Date().getFullYear();

  let years = [];

  while (startYear <= currentYear) {
    years.push(startYear++);
  }

  return years.reverse();
};

const formatDate = (date, formatter) => {
  let pattern = /(\d{4})\-(\d{2})\-(\d{2})/;
  const defaultDate = date?.replace(pattern, "$2/$3/$1");

  const results = format(new Date(defaultDate), formatter);

  return results;
};

const replaceToFullPath = (relativePath) =>
  `${process.env.REACT_APP_API_URL}${relativePath}`;

const convertToLatLng = (latlng) => {
  const result = parseFloat(latlng.split(",")[0]);

  return result;
};

const rupiahToInt = (rupiah) => {
  const int = replaceAll(replaceAll(String(rupiah), "Rp", ""), ",", "");

  return parseInt(int);
};

const replaceSuffixPrefixToInt = (suffixPrefix, key, key2) => {
  const int = replaceAll(replaceAll(String(suffixPrefix), key, ""), key2, "");

  return parseInt(int);
};

const base64Formatter = (str) => `data:image/png;base64,${str}`;

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve(reader.result.replace(/^data:image.+;base64,/, ""));
    reader.onerror = (error) => reject(error);
  });

const isFile = (input) => {
  if ("File" in window && input instanceof File) return true;
  else return false;
};

const validURL = (str) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

const featureToGeometryCollection = (featureCollection) => {
  return {
    type: "GeometryCollection",
    geometries: [featureCollection?.geometry],
  };
};

const requestAll = (urls) => urls.map((url) => request.get(url));

const getNumberList = (index, page = 1, perPage = 5) => {
  return index + 1 + (parseInt(page ?? 1) - 1) * parseInt(perPage ?? 5);
};

const getCentroid = (arr) => {
  return arr.reduce(
    (x, y) => {
      return [x[0] + y[0] / arr.length, x[1] + y[1] / arr.length];
    },
    [0, 0]
  );
};

const uppercaseFirstLetter = (words) => {
  if (words) {
    let wordList = words?.split(" ");

    for (let i = 0; i < wordList.length; i++) {
      if (wordList[i].length < 3) {
        wordList[i] = wordList[i].toUpperCase();
      } else {
        wordList[i] = wordList[i][0].toUpperCase() + wordList[i].substr(1);
      }
    }

    return wordList.join(" ")?.replace(/_/g, " ")?.replace(/-/g, " ");
  }
};

export async function toExcel(fileName, sheetName, data) {
  const sheets = xlsx.utils.json_to_sheet(data)
  const workbook = xlsx.utils.book_new()
  xlsx.utils.book_append_sheet(workbook, sheets, sheetName)
  xlsx.writeFile(workbook, `./${fileName}.xlsx`)
}

export const toTitleCase = (str) => {
  if (!str) return str
  if (str.length <= 3) return String(str).toUpperCase()
  return str?.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export {
  replaceAll,
  findActiveMenuInRoutes,
  replaceRoleName,
  toBase64,
  base64Validation,
  toRupiah,
  formatArrayInTable,
  getYears,
  formatDate,
  replaceToFullPath,
  convertToLatLng,
  rupiahToInt,
  base64Formatter,
  isFile,
  validURL,
  featureToGeometryCollection,
  requestAll,
  getNumberList,
  getCentroid,
  replaceSuffixPrefixToInt,
  uppercaseFirstLetter,
};
