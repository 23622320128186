import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Avatar from "react-avatar";
import { USER_TYPE } from "utils/constant";
import LogoBreksiTopbar from "assets/png/topbar-logo.png";
import CheckPermission from "components/CheckPermission";

const Topbar = ({ topRightComponent = null }) => {
  const { user } = useSelector((state) => state.auth);

  return (
    <nav
      id="topbar"
      className="navbar navbar-light position-lg-sticky top-lg-0 d-none d-lg-block overlap-10 flex-none bg-white border-bottom px-0 py-3"
    >
      <div className="container-fluid">
        {/* <div className="hstack gap-2">
          <button
            type="button"
            className="btn btn-sm btn-square bg-tertiary bg-opacity-20 bg-opacity-100-hover text-tertiary text-white-hover"
          >
            C
          </button>
          <button
            type="button"
            className="btn btn-sm btn-square bg-primary bg-opacity-20 bg-opacity-100-hover text-primary text-white-hover"
          >
            D
          </button>
          <button
            type="button"
            className="btn btn-sm btn-square bg-warning bg-opacity-20 bg-opacity-100-hover text-warning text-white-hover"
          >
            A
          </button>
          <button
            type="button"
            className="btn btn-sm btn-square btn-neutral border-dashed shadow-none"
          >
            <i className="bi bi-plus-lg"></i>
          </button>
        </div> */}

        <Link to="/" className="d-flex align-items-center gap-5">
          <img alt="logobreksi" src={LogoBreksiTopbar} className="logo-left" />
        </Link>
        {
          topRightComponent ? (
            topRightComponent
          ) : (
        <div className="navbar-user d-none d-sm-block ms-auto">
          <div className="hstack gap-3 ms-4">
            <div className="dropdown">
              <a
                className="d-flex align-items-center"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <div>
                  <div className="avatar avatar-sm bg-warning rounded-circle text-white">
                    <Avatar
                      src={user?.avatar}
                      alt={user?.email}
                      name={user?.email}
                      size="40px"
                      color="#019443"
                      className="avatar avatar-sm rounded-circle"
                    />
                  </div>
                </div>
                <div className="d-none d-sm-block ms-3">
                  <span className="h6">{user?.email}</span>
                </div>
                <div className="d-none d-md-block ms-md-2">
                  <i className="bi bi-chevron-down text-muted text-xs"></i>
                </div>
              </a>
              <div className="dropdown-menu dropdown-menu-end">
                <div className="dropdown-header">
                  <span className="d-block text-sm text-muted mb-1">
                    Signed in as
                  </span>
                  <span className="d-block text-heading font-semibold">
                    {user?.email}
                  </span>
                </div>
                <CheckPermission permission="access-profile">
                  <div className="dropdown-divider"></div>
                  <Link className="dropdown-item" to="/profile">
                    <i className="bi bi-pencil me-3"></i>Ubah profil
                  </Link>
                </CheckPermission>
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="/keluar">
                  <i className="bi bi-box-arrow-left me-3"></i>Keluar
                </Link>
              </div>
            </div>
          </div>
        </div>
          )
        }
      </div>
    </nav>
  );
};

export default Topbar;
