import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { request } from "utils/request";

export const useRequest = (url, options, id = "", waitingParams, triggerFetchData) => {
  const [response, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await request.get(url, options);

      setData(response);
    } catch (err) {
      if (err.response.status !== 404) {
        setErrors(true);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (triggerFetchData) {
      fetchData();
    }
  }, [triggerFetchData]);

  useEffect(() => {
    if (waitingParams) {
      if (id) {
        fetchData();
      }
    } else {
      fetchData();
    }
  }, [waitingParams, id]);

  return { response, loading, errors };
};

useRequest.defaultProps = {
  waitingParams: false,
};

useRequest.propTypes = {
  url: PropTypes.string,
  options: PropTypes.object,
  loading: PropTypes.bool,
  data: PropTypes.shape({
    data: PropTypes.array,
    meta: PropTypes.object,
  }),
  waitingParams: PropTypes.bool,
  fetchData: PropTypes.bool,
};
