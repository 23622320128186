import { useState, useEffect } from "react";
import FormInput from "components/form-input";
import { useForm } from "react-hook-form";
import { request } from "utils/request";
import { PRESENSI_STATUS } from "utils/constant";
import { format } from "date-fns";

const FilterPresensi = ({
    setFilterParams,
}) => {
    const {
        control,
        register,
        formState: { errors },
        watch
      } = useForm();
    const [karyawanOptions,setKaryawanOptions] = useState([])

    const filterFields = watch();
    const {
        tanggal = null,
        karyawan = null,
        status = null,
    } = filterFields;

    const handleRequestKaryawan = async () => {
        try {
            const responseNames = await request.get("/api/karyawan");
            return setKaryawanOptions(
                responseNames.data.data.map((item) => ({
                    label: item.nama,
                    value: item.id,
                    nama: item.nama,
                }))
            );
          } finally {
          }
    }

    useEffect(() => {
        handleRequestKaryawan()
    },[])

    useEffect(() => {
        setFilterParams({
            tanggal,
            karyawan_id: karyawan?.value,
            status: status?.value,
        })
    },[tanggal, karyawan, status])

    return (
        <section
            style={{
                width: '75%',
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                gap: '1rem',
            }}
        >
            <FormInput
                label="Tanggal"
                name="tanggal"
                inputType="date"
                defaultValue={format(new Date(), 'yyyy-MM-dd')}
                errors={errors}
                control={control}
                placeholder="Pilih waktu"
                errorMessage="Waktu tidak boleh kosong!"
            />
            <FormInput
              name="karyawan"
              inputType="select"
              label="Nama Karyawan"
              errors={errors}
              control={control}
              register={register}
              options={karyawanOptions}
              placeholder="Pilih Karyawan"
            />
            <FormInput
              name="status"
              inputType="select"
              label="Status Presensi"
              errors={errors}
              control={control}
              register={register}
              options={PRESENSI_STATUS.map((item) => ({
                label: item.label,
                value: item.value,
              }))}
              placeholder="Pilih Status"
            />
        </section>
    )
}

export default FilterPresensi;