import DynamicTable from "components/dynamic-table";
import FormInput from "components/form-input";
import PageHeader from "components/page-header";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { toRupiah } from "utils/helper";
import { request } from "utils/request";
import ListIcon from "icons/list-icon";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { format } from "date-fns";
import useHasPermission from "hooks/useHasPermission";
import CheckPermission from "components/CheckPermission";

const PenggajianList = () => {
  const navigate = useNavigate();
  const [gajiOptions, setGajiOptions] = useState([]);

  const tableColumns = [
    {
      Header: "No",
      accessor: "nomor",
      width: "20px",
    },
    {
      Header: "Periode",
      accessor: "periode",
      Cell: ({ row }) => {
        return `${format(
          new Date(row.original.tanggal_mulai),
          "dd MMMM yyyy"
        )} - ${format(new Date(row.original.tanggal_selesai), "dd MMMM yyyy")}`;
      },
    },
    {
      Header: "Total",
      accessor: "total",
      Cell: ({ row }) => {
        return toRupiah(row?.original?.slip_gaji_sum_total ?? 0);
      },
    },
    {
      Header: "Aksi",
      accessor: "aksi",
      Cell: ({ row }) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CheckPermission permission="view-slip-gaji">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip">Lihat Detail</Tooltip>}
              >
                <button
                  type="button"
                  onClick={() => {
                    navigate(`/penggajian/periode/${row.original.id}`);
                  }}
                  style={{
                    backgroundColor: "#FFFFFF",
                    padding: "12px",
                    borderRadius: "8px",
                    border: "1px solid #D9D9D9",
                    boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <ListIcon />
                </button>
              </OverlayTrigger>
            </CheckPermission>
          </div>
        );
      },
    },
  ];

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const watchPeriode = watch("periode");

  const fetchGajiOptions = async () => {
    request
      .get("/api/periode-gaji")
      .then((res) => {
        setGajiOptions(
          res?.data?.data?.map((item) => ({
            value: item.id,
            label: `${format(
              new Date(item.tanggal_mulai),
              "dd MMMM yyyy"
            )} - ${format(new Date(item.tanggal_selesai), "dd MMMM yyyy")}`,
          }))
        );
      })
      .catch((err) => {
        toast.error("Gagal memuat data");
      });
  };

  useEffect(() => {
    fetchGajiOptions();
  }, []);

  const mayCreatePeriodeGaji = useHasPermission("create-periode-gaji");

  return (
    <>
      <PageHeader
        title="Daftar Periode Gaji"
        {...(mayCreatePeriodeGaji && {
          to: "/periode-gaji/create",
        })}
      />
      <div>
        <div
          style={{
            marginBottom: "8px",
            fontSize: "18px",
            fontWeight: "500",
          }}
        >
          Periode Gaji
        </div>
        <div style={{ width: "402px" }}>
          <FormInput
            required
            placeholder="Pilih periode"
            name="periode"
            value={setValue}
            options={gajiOptions}
            register={register}
            inputType="select"
            errors={errors}
            control={control}
          />
        </div>
      </div>
      <DynamicTable
        url="/api/periode-gaji"
        columns={tableColumns}
        withSearch={false}
        {...(watchPeriode && {
          filter: {
            id: watchPeriode.value,
          },
        })}
      />
    </>
  );
};

export default PenggajianList;
