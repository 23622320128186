import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import { GOLONGAN_KARYAWAN } from "utils/constant";
import { toTitleCase } from "utils/helper";

const ModalShow = ({
        onClick,
        show,
        onHide,
        loading,
        title,
        titleAction,
        loadingText,
        data
    }) => {

    const imgStyle = {
        width: "80px",
        height: "80px",
        objectFit: "cover",
        borderRadius: "100%"
    }

    const tdTitle = {
        width: "40%",
        display: 'flex',
        justifyContent: 'left'
    }

    return (
        <Modal show={show} onHide={onHide} size="xl" centered>
            <Modal.Header className="bg-primary">
                <Modal.Title className="text-white">
                    <div className="d-flex align-items-center" style={{gap: "20px"}}>
                        <div>
                            <img alt={`Foto ${data?.nama}`} src={data?.foto} style={imgStyle} />
                        </div>
                        <div>
                            <h4 className="text-white">{data?.nama ?? '-'}</h4> 
                            <p>{data?.email ?? '-'}</p>
                        </div>
                    </div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Row>
                    <Col xs="12" sm="12" md="6">
                        <table className="table table-sm table-borderless" style={{width: "100%"}}>
                            <tbody>
                                <tr>
                                    <td style={tdTitle}>Nama</td>
                                    <td>: {data?.nama}</td>
                                </tr>
                                <tr>
                                    <td style={tdTitle}>NIP</td>
                                    <td>: {data?.nip}</td>
                                </tr>
                                <tr>
                                    <td style={tdTitle}>NIK</td>
                                    <td>: {data?.nik}</td>
                                </tr>
                                <tr>
                                    <td style={tdTitle}>Jenis Kelamin</td>
                                    <td>: {data?.jenis_kelamin === 'L' ? 'Laki-laki' : 'Perempuan'}</td>
                                </tr>
                                <tr>
                                    <td style={tdTitle}>Tempat, Tanggal Lahir</td>
                                    <td>: {`${data?.tempat_lahir}, ${data?.tanggal_lahir}`}</td>
                                </tr>
                                <tr>
                                    <td style={tdTitle}>Jabatan</td>
                                    <td>: {data?.jabatan_label?.length <= 3 ? String(data?.jabatan_label).toUpperCase() : toTitleCase(data?.jabatan_label)}</td>
                                </tr>

                                <tr>
                                    <td style={tdTitle}>Golongan</td>
                                    <td>: {GOLONGAN_KARYAWAN.find((golongan) => golongan.value === data?.golongan)?.label}</td>
                                </tr>
                                <tr>
                                    <td style={tdTitle}>Bidang</td>
                                    <td>: {toTitleCase(data?.bidang)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                    <Col xs="12" sm="12" md="6">
                    <table className="table table-sm table-borderless" style={{width: "100%"}}>
                            <tbody>
                                <tr>
                                    <td style={tdTitle}>Role</td>
                                    <td>: {data?.role[0].name}</td>
                                </tr>
                                <tr>
                                    <td style={tdTitle}>Agama</td>
                                    <td>: {data?.agama}</td>
                                </tr>
                                <tr>
                                    <td style={tdTitle}>Pendidikan Terakhir</td>
                                    <td>: {String(data?.pendidikan_terakhir).split(' / ').join('/')}</td>
                                </tr>
                                <tr>
                                    <td style={tdTitle}>Bank</td>
                                    <td>: {data?.bank}</td>
                                </tr>
                                <tr>
                                    <td style={tdTitle}>No Rekening</td>
                                    <td>: {data?.no_rekening}</td>
                                </tr>
                                <tr>
                                    <td style={tdTitle}>Alamat Domisili</td>
                                    <td>: {data?.alamat_domisili}</td>
                                </tr>
                                <tr>
                                    <td style={tdTitle}>Alamat KTP</td>
                                    <td>: {data?.alamat_ktp}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="neutral" onClick={onHide}>
                    Tutup
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalShow;
