import { useEffect, useState } from "react";
import CreateContainer from "containers/create-container";
import { request } from "utils/request";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import FormInput from "components/form-input";
import { useNavigate, useParams } from "react-router-dom";

const EditTransactionDua = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [jenisTransaksi, setJenisTransaksi] = useState([]);
  const [dataTransaksi, setDataTransaksi] = useState(null);
  const [akun, setAkun] = useState([]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm();

  const watchJenisTransaksi = watch("jenisTransaksi");

  const fetchJenisTransaksi = async () => {
    setLoading(true);
    request
      .get("/api/jenis-transaksi")
      .then((res) => {
        const newJenisTransaksi = res.data.data;
        setJenisTransaksi(
          newJenisTransaksi.map((item) => ({
            value: item.id,
            label: item.nama,
            ...item,
          }))
        );
      })
      .catch((err) => {
        toast.error("Gagal memuat jenis transaksi");
      })
      .finally(() => setLoading(false));
  };

  const fetchData = () => {
    setLoading(true);
    request
      .get(`/api/jurnal-umum-dua/${id}`)
      .then((res) => {
        const data = res.data.data;
        const dataTransaksi = data?.transaksi_jurnal_umum_dua?.find(
          (item) => item?.kode_akun !== 111
        );
        setDataTransaksi(dataTransaksi);

        setValue("tanggal", data.tanggal);
        setValue("jenisTransaksi", {
          value: dataTransaksi.jenis_transaksi,
          label: jenisTransaksi?.find(
            (item) => item.value === dataTransaksi.jenis_transaksi
          )?.label,
        });
        setValue("akun", {
          value: dataTransaksi?.kode_akun,
          label: dataTransaksi?.nama_akun,
        });
        setValue("keterangan", dataTransaksi?.keterangan);
        setValue("jumlah", dataTransaksi?.debit);
      })
      .catch((err) => {
        toast.error("Gagal memuat data");
      })
      .finally(() => setLoading(false));
  };

  const fetchAkun = async () => {
    setLoading(true);
    request
      .get("/api/akun")
      .then((res) => {
        const newAkun = res.data.data;
        setAkun(
          newAkun.map((item) => ({
            value: item.kode,
            label: item.nama,
            ...item,
          }))
        );
      })
      .catch((err) => {
        toast.error("Gagal memuat akun");
      })
      .finally(() => setLoading(false));
  };

  const onSubmitTransaksi = (values) => {
    const payload = {
      tanggal: values.tanggal,
      jenis_transaksi: values.jenisTransaksi.value,
      kode_akun: values.akun.value,
      nama_akun: values.akun.label,
      keterangan: values.keterangan,
      nominal:
        String(values?.jumlah)?.replace("Rp ", "")?.split(",")?.join("") ?? 0,
    };

    setLoading(true);
    request
      .put(`/api/jurnal-umum-dua/${id}`, payload)
      .then(() => {
        toast.success("Berhasil menambahkan transaksi");
        navigate("/keuangan/bendahara-2/jurnal-umum");
      })
      .catch((err) => {
        toast.error("Gagal menambahkan transaksi");
        console.log(err);
      });
  };

  useEffect(() => {
    fetchJenisTransaksi();
    fetchAkun();
    fetchData();
  }, []);

  useEffect(() => {
    if (jenisTransaksi?.length > 0) {
      setValue("jenisTransaksi", {
        value: dataTransaksi?.jenis_transaksi,
        label: jenisTransaksi?.find(
          (item) => item?.value === dataTransaksi?.jenis_transaksi
        )?.label,
      });
    }
  }, [jenisTransaksi]);

  useEffect(() => {
    const newAkun = jenisTransaksi
      .find((item) => item.value === watchJenisTransaksi.value)
      ?.akun?.map((item) => ({
        ...item,
        value: item.kode_akun,
        label: item.nama_akun ?? item.kode_akun,
      }));
    setAkun(newAkun);
  }, [watchJenisTransaksi]);

  return (
    <CreateContainer
      title="Ubah Transaksi"
      loading={loading}
      loadingText="Menambahkan transaksi.."
      onSubmit={handleSubmit(onSubmitTransaksi)}
    >
      <FormInput
        required={true}
        label="Tanggal"
        name="tanggal"
        inputType="date"
        errors={errors}
        control={control}
        register={register}
        placeholder="Pilih tanggal"
        errorMessage="Waktu tidak boleh kosong!"
        isClearable={true}
      />

      <FormInput
        name="jenisTransaksi"
        inputType="select"
        label="Jenis Transaksi"
        errors={errors}
        control={control}
        register={register}
        options={jenisTransaksi}
        placeholder="Pilih jenis transaksi"
        errorMessage="Jenis transaksi tidak boleh kosong!"
      />

      <FormInput
        name="akun"
        inputType="select"
        label="Akun"
        errors={errors}
        control={control}
        register={register}
        options={akun}
        placeholder="Pilih akun"
        errorMessage="Akun tidak boleh kosong!"
      />

      <FormInput
        required
        label="Keterangan"
        name="keterangan"
        type="text"
        errors={errors}
        register={register}
        placeholder="Masukkan keterangan"
        errorMessage="Keterangan tidak boleh kosong!"
      />

      <FormInput
        required
        errors={errors}
        name="jumlah"
        label="Jumlah"
        control={control}
        inputType="price"
        placeholder="Masukkan jumlah"
        errorMessage="Jumlah tidak boleh kosong!"
      />
    </CreateContainer>
  );
};

export default EditTransactionDua;
