import FormInput from "components/form-input";
import PageHeader from "components/page-header";
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { format } from "date-fns";
import { request } from "utils/request";
import ModalPresensiIzin from "./modal-presensi-izin";

const InputNIP = () => {
    const [loading, setLoading] = useState(false)
    const [presensiModalVisible, setPresensiModalVisible] = useState(false)
    const navigate = useNavigate()
    const {
        control,
        register,
        formState: { errors },
        setValue,
      } = useForm();

    const location = useLocation();
    const { data = null } = location.state
    const watchNip = useWatch({ name: "nip", control });

    useEffect(() => {
        const handleSubmitData = async () => {
            try {
                setLoading(true)
                const payload = {
                    nip: watchNip,
                    status: String(data?.status?.value),
                    tanggal: format(new Date(), 'yyyy-MM-dd'),
                    jenis: "manual"
                }
                const response = await request.post('/api/presensi-karyawan', payload)
                    .then((res) => res)
                if (response) {
                    toast.success(`Berhasil menambahkan`, { duration: 3000 });
                    setValue('nip', '')
                }
            } catch (err) {
                toast.error('Gagal memasukkan presensi', { duration: 3000 })
                setValue('nip', '')
                console.log(err)
            } finally {
                setLoading(false)
            }
        }

        if (watchNip?.length === 17 && !loading) {
            handleSubmitData()
        }
    },[watchNip, loading, data])

    const handlePresensiIzin = () => {
        setPresensiModalVisible(true)
    }

    return (
        <>
            <PageHeader
                title={`Presensi ${data?.status?.value === 1 ? 'Jam Kerja Reguler' : 'Jam Lembur'}`}
                to="/presensi/barcode"
                newTab={true}
                btnTitle="QR Code"
                btnIcon="bi bi-qr-code"
                additionalButton={{
                    title: 'Presensi Izin',
                    onClick: handlePresensiIzin
                }}
            />
            <section
                style={{
                    padding: '30px 50px',
                    backgroundColor: '#FFFFFF',
                    borderRadius: '16px',
                }}
            >
                {
                    loading ? (
                        <Spinner className="d-block mx-auto" animation="border" />
                    ) : (
                        <>
                            <FormInput
                                label="NIP"
                                name="nip"
                                type="number"
                                errors={errors}
                                register={register}
                                control={control}
                                placeholder="3309054307920003"
                                errorMessage="NIP tidak boleh kosong"
                            />
                            <div>
                                <i class="bi bi-info-circle"></i>
                                <span style={{ marginLeft: '4px'}}>
                                    Jika ingin presensi manual, bisa diinput. Pada saat karakter 17, presensi dikirim.
                                </span>
                            </div>
                        </>
                    )
                }
            </section>
            <ModalPresensiIzin
                show={presensiModalVisible}
                onHide={() => setPresensiModalVisible(false)}
                data={data}
            />
        </>
    )
}

export default InputNIP;