import { useEffect, useState } from "react";
import CreateContainer from "containers/create-container";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import FormInput from "components/form-input";
import { request } from "utils/request";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";

const EditLokasiToilet = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [loading,setLoading] = useState(false)
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm()

    const onSubmitData = async (values) => {
        setLoading(true)
        request.patch(`/api/lokasi-toilet/${id}`, values)
            .then((res) => {
                toast.success(`${res.data.message}`, { duration: 3000 });
                navigate("/hasil-usaha/toilet/lokasi");
            })
            .catch(err => {
                toast.danger('Gagal menambahkan lokasi toilet')
            })
            .finally(() => setLoading(false));
    }

    const fetchLokasiToilet = () => {
        request.get(`/api/lokasi-toilet/${id}`)
            .then((res) => {
                const { data } = res.data
                setValue('nama', data.nama)
            })
    }

    useEffect(() => {
        fetchLokasiToilet()
    },[])

    return (
        <CreateContainer
            title="Ubah Lokasi Toilet"
            loadingText="Mengubah lokasi toilet"
            onSubmit={handleSubmit(onSubmitData)}
            loading={loading}
        >
            <FormInput
                required
                label="Lokasi Toilet"
                name="nama"
                type="text"
                errors={errors}
                register={register}
                placeholder="Masukkan lokasi toilet"
                errorMessage="Lokasi toilet tidak boleh kosong"
            />
        </CreateContainer>
    )
}

export default EditLokasiToilet;