import FormInput from "components/form-input";
import FormRadio from "components/form-radio";
import CreateContainer from "containers/create-container";
import { useRequest } from "hooks/useRequest";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { request } from "utils/request";
import { GOLONGAN_KARYAWAN } from "utils/constant";
import { toTitleCase } from "utils/helper";


const UpdateKaryawan = () => {
    const [loading, setLoading] = useState(false);
    const [bidang, setBidang] = useState({});
    const [jabatan, setJabatan] = useState({});
    const [role, setRole] = useState({});
    const [agama, setAgama] = useState({});
    const [pendidikan, setPendidikan] = useState({});
    const [bank, setBank] = useState({});
    const [statusKaryawan, setStatusKaryawan] = useState({});

    const getBidang = async() => {
        const response = await request.get("api/bidang");
        return response;
    }

    const getJabatan = async() => {
        const response = await request.get("api/jabatan");
        return response;
    }

    const getRole = async() => {
        const response = await request.get("api/role");
        return response;
    }

    const getPendidikan = async() => {
        const response = await request.get("api/pendidikan-terakhir");
        return response;
    }

    const getAgama = async() => {
        const response = await request.get("api/agama");
        return response;
    }

    const getBank = async() => {
        const response = await request.get("api/bank");
        return response;
    }

    const getStatusKaryawan = async() => {
        const response = await request.get("api/status-karyawan");
        return response;
    }

    useEffect(() => {
        getBidang().then((res) => {
            setBidang(res.data.data.map( bidang => ({value: bidang.id, label: toTitleCase(bidang.nama)}) ))
        })

        getJabatan().then((res) => {
            setJabatan(res.data.data.map( jabatan => ({value: jabatan.id, label: toTitleCase(jabatan.nama)}) ))
        })

        getRole().then((res) => {
            setRole(res.data.data.map( role => ({value: role.id, label: role.name}) ))
        })

        getAgama().then((res) => {
            setAgama(res.data.data.map( agama => ({value: agama.id, label: agama.nama}) ))
        })

        getPendidikan().then((res) => {
            setPendidikan(res.data.data.map( pendidikan => ({value: pendidikan.id, label: String(pendidikan.nama).split(' / ').join('/')}) ))
        })

        getBank().then((res) => {
            setBank(res.data.data.map( bank => ({value: bank.id, label: bank.nama}) ))
        })

        getStatusKaryawan().then((res) => {
            setStatusKaryawan(res.data.data.map( status => ({value: status.id, label: status.nama}) ))
        })

    }, [setBidang, setJabatan, setRole, setAgama, setPendidikan, setBank])


    const navigate = useNavigate();
    const {
        control,
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    const { id } = useParams();
    const [initialFoto,setInitialFoto] = useState(null)

    const {
        response: responseDetailKaryawan,
        loading: loadingDetailKaryawan,
        errors: errorsDetailKaryawan,
    } = useRequest(`/api/karyawan/${id}`, {}, id, true);

    const onSubmitData = (values) => {
        const includeFoto = values?.foto?.[0]?.file.constructor !== Blob
        if (!includeFoto) {
            delete values.foto
        }
        const isPayload = {
            ...values,
            _method: "PUT",
            ...(includeFoto && {
                foto: values?.foto?.[0]?.file,
            }),
            bidang_id: values?.bidang_id?.value,
            jabatan_id: values?.jabatan_id?.value,
            role_id: values?.role_id?.value,
            agama_id: values?.agama_id?.value,
            pendidikan_terakhir_id: values?.pendidikan_terakhir_id?.value,
            status_karyawan_id: values?.status_karyawan_id?.value,
            bank_id: values?.bank_id?.value,
            golongan: values?.golongan?.value,
        };
    
        const formData = new FormData();
    
        Object.entries(isPayload).forEach(([key, value]) =>
            formData.append(key, value)
        );

        setLoading(true);
        request
            .post(`/api/karyawan/${id}`,formData, {
                headers: {
                    "Content-Type" : "multipart/form-data"
                }
            })
            .then((res) => {
                toast.success(`${res.data.message}`, { duration: 3000 });
                navigate("/karyawan");
            })
            .finally(() => { setLoading(false) })
    }

    useEffect(() => {
        if(responseDetailKaryawan) {
            const karyawan = responseDetailKaryawan?.data?.data;
            setInitialFoto(karyawan?.foto)
        }
    }, [responseDetailKaryawan])

    if(responseDetailKaryawan) {
        const karyawan = responseDetailKaryawan?.data?.data;

        setValue("foto", karyawan?.foto);
        setValue("nama", karyawan?.nama);
        setValue("nik", karyawan?.nik);
        setValue("jenis_kelamin", karyawan?.jenis_kelamin);
        setValue("no_hp", karyawan?.no_hp);
        setValue("email", karyawan?.user.email);
        setValue("tempat_lahir", karyawan?.tempat_lahir);
        setValue("tanggal_lahir", karyawan?.tanggal_lahir);
        setValue("tanggal_masuk", karyawan?.tanggal_masuk);
        setValue("jabatan_id", {label: toTitleCase(karyawan?.jabatan_label), value: karyawan?.jabatan_id});
        setValue("bidang_id", {label:toTitleCase( karyawan?.bidang), value: karyawan?.bidang_id});
        setValue("role_id", {label: karyawan?.user.roles[0].name, value: karyawan?.user.roles[0].id});
        setValue("agama_id", {label: karyawan?.agama, value: karyawan?.agama_id});
        setValue("pendidikan_terakhir_id", {label: String(karyawan?.pendidikan_terakhir).split(' / ').join('/') , value: karyawan?.pendidikan_terakhir_id});
        setValue("bank_id", {label: karyawan?.bank, value: karyawan?.bank_id});
        setValue("no_rekening", karyawan?.no_rekening);
        setValue("alamat_domisili", karyawan?.alamat_domisili);
        setValue("alamat_ktp", karyawan?.alamat_ktp);
        setValue("status_karyawan_id", {label: karyawan?.status_karyawan, value: karyawan?.status_karyawan_id});
        setValue("golongan", GOLONGAN_KARYAWAN.find(golongan => golongan.value === karyawan?.golongan));
    }

    if (errorsDetailKaryawan) {
        return <Navigate to="/data-master/daftar-venue" />;
    }

    
    return (
        <CreateContainer
            title="Ubah Karyawan"
            loading={loading}
            loadingRequest={loadingDetailKaryawan}
            onSubmit={handleSubmit(onSubmitData)}
            loadingText="menambahkan karyawan..."
        >

            <FormInput
                required
                name="foto"
                errors={errors}
                control={control}
                maxFiles={1}
                allowMultiple={false}
                inputType="upload_images_grid"
                errorMessage="Foto tidak boleh kosong !"
            />

            <FormInput
                required
                label="Nama"
                name="nama"
                type="text"
                errors={errors}
                register={register}
                placeholder="Masukkan Nama"
                errorMessage="Nama tidak boleh kosong !"
            />

            <FormInput
                required
                label="NIK"
                name="nik"
                inputType="nik"
                errors={errors}
                register={register}
                placeholder="Masukkan NIK"
                errorMessage="NIK tidak boleh kosong !"
                control={control}
            />

            <FormRadio 
                required 
                label="Jenis Kelamin"
                name="jenis_kelamin"
                register={register}
                options={[
                        {label: "Laki-laki", "value": "L"},
                        {label: "Perempuan", "value" : "P"}
                    ]
                }
                errors={errors}
                errorMessage="Jenis kelamin tidak boleh kosong"
            />

            <FormInput
                required
                label="Nomor Handphone"
                name="no_hp"
                inputType="phone"
                errors={errors}
                control={control}
                register={register}
                placeholder="Masukkan Nomor Handphone"
                errorMessage="Nomor Handphone tidak boleh kosong !"
                maxLength={16}
            />

            <FormInput
                required
                label="Email"
                name="email"
                type="text"
                errors={errors}
                register={register}
                placeholder="Masukkan Email"
                errorMessage="Email tidak boleh kosong !"
            />

            <FormInput
                required
                label="Tempat Lahir"
                name="tempat_lahir"
                type="text"
                errors={errors}
                register={register}
                placeholder="Masukkan Tempat Lahir"
                errorMessage="Tempat lahir tidak boleh kosong !"
            />

            <FormInput
                required
                label="Tanggal Lahir"
                name="tanggal_lahir"
                type="date"
                errors={errors}
                register={register}
                placeholder="Masukkan Tempat Lahir"
                errorMessage="Tanggal lahir tidak boleh kosong !"
            />

            <FormInput
                required
                label="Tanggal Masuk"
                name="tanggal_masuk"
                type="date"
                errors={errors}
                register={register}
                placeholder="Tanggal Masuk"
                errorMessage="Tanggal Masuk tidak boleh kosong !"
            />

            <FormInput 
                required
                label="Jabatan"
                name="jabatan_id"
                options={jabatan}
                register={register}
                inputType="select"
                errors={errors}
                control={control}
                errorMessage="Jabatan tidak boleh kosong!"
            />

            <FormInput
                required
                label="Golongan"
                name="golongan"
                options={GOLONGAN_KARYAWAN}
                register={register}
                inputType="select"
                errors={errors}
                control={control}
                errorMessage="Golongan tidak boleh kosong!"
            />

            <FormInput 
                required
                label="Bidang"
                name="bidang_id"
                options={bidang}
                register={register}
                inputType="select"
                errors={errors}
                control={control}
                errorMessage="Bidang tidak boleh kosong!"
            />

            <FormInput 
                required
                label="Role"
                name="role_id"
                options={role}
                register={register}
                inputType="select"
                errors={errors}
                control={control}
                errorMessage="Role tidak boleh kosong!"
            />

            <FormInput 
                required
                label="Agama"
                name="agama_id"
                options={agama}
                register={register}
                inputType="select"
                errors={errors}
                control={control}
                errorMessage="Agama tidak boleh kosong!"
            />

            <FormInput 
                required
                label="Pendidikan Terakhir"
                name="pendidikan_terakhir_id"
                options={pendidikan}
                register={register}
                inputType="select"
                errors={errors}
                control={control}
                errorMessage="Pendidikan tidak boleh kosong!"
            />

            <FormInput 
                required
                label="Bank"
                name="bank_id"
                options={bank}
                register={register}
                inputType="select"
                errors={errors}
                control={control}
                errorMessage="Bank tidak boleh kosong!"
            />

            <FormInput
                required
                label="Nomor Rekening"
                name="no_rekening"
                inputType="rekening"
                errors={errors}
                register={register}
                control={control}
                min={1}
                placeholder="Masukkan Nomor Rekening"
                errorMessage="Nomor rekening tidak boleh kosong !"
            />

            <FormInput
                required
                label="Alamat Domisili"
                name="alamat_domisili"
                inputType="textarea"
                errors={errors}
                register={register}
                placeholder="Masukkan Alamat Domisili"
                errorMessage="Alamat domisili tidak boleh kosong !"
            />

            <FormInput
                skipCapitalize
                required
                label="Alamat KTP"
                name="alamat_ktp"
                inputType="textarea"
                errors={errors}
                register={register}
                placeholder="Masukkan Alamat KTP"
                errorMessage="Alamat KTP tidak boleh kosong !"
            />

        </CreateContainer>
    )
}

export default UpdateKaryawan;