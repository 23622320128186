import KaryawanIcons from "icons/karyawan";
import PresensiIcons from "icons/presensi";
import CreateKatalog from "pages/data-master/venue/katalog/create";
import CreateImageKatalogPage from "pages/data-master/venue/katalog/images/create";
import ListKatalogImage from "pages/data-master/venue/katalog/images/list";
import ListKatalogVenue from "pages/data-master/venue/katalog/lists";
import ShowKatalogPage from "pages/data-master/venue/katalog/show";
import UpdateKatalog from "pages/data-master/venue/katalog/update";
import Map2d from "pages/data-master/venue/map2d";
import CreateKaryawan from "pages/karyawan/create";
import ListKaryawan from "pages/karyawan/lists";
import UpdateKaryawan from "pages/karyawan/update";
import Presensi from "pages/presensi";
import AturPresensi from "pages/presensi/AturPresensi";
import AttendanceBarcode from "pages/presensi/barcode";
import DateTimeDisplay from "components/presensi/date-time";
import InputNIP from "pages/presensi/input-nip";
import { lazy } from "react";
import { Navigate } from "react-router-dom";
import ListIzin from "pages/izin";
import CreatePerizinan from "pages/izin/create";
import DetailPerizinan from "pages/izin/detail";
import UserList from "pages/settings/user-management/lists";
import PenggajianList from "pages/penggajian";
import SettingPenggajian from "pages/penggajian/setting";
import SettingLembur from "pages/penggajian/settings/lembur";
import SettingGajiPokok from "pages/penggajian/settings/gaji-pokok";
import SettingBonusTahunan from "pages/penggajian/settings/bonus-tahunan";
import CreatePeriodeGaji from "pages/penggajian/create-periode-gaji";
import DetailPeriode from "pages/penggajian/detail-periode";
import TunjanganJabatan from "pages/penggajian/settings/tunjangan";
import ListPaketPenginapan from "pages/data-master/penginapan/list";
import CreatePaketPenginapan from "pages/data-master/penginapan/create";
import ListFasilitas from "pages/data-master/penginapan/fasilitas/list";
import CreateFasilitas from "pages/data-master/penginapan/fasilitas/create";
import EditFasilitas from "pages/data-master/penginapan/fasilitas/update";
import EditPaketPenginapan from "pages/data-master/penginapan/edit";
import HasilUsahaIcon from "icons/hasil-usaha";
import LaporanPendapatanToilet from "pages/hasil-usaha/toilet/laporan-pendapatan";
import TambahLokasiToilet from "pages/hasil-usaha/toilet/lokasi/tambah";
import DaftarLokasiToilet from "pages/hasil-usaha/toilet/lokasi/list";
import EditLokasiToilet from "pages/hasil-usaha/toilet/lokasi/edit";
import TambahLaporanPendapatanToilet from "pages/hasil-usaha/toilet/laporan-pendapatan/tambah";
import EditLaporanPendapatanToilet from "pages/hasil-usaha/toilet/laporan-pendapatan/edit";
import RekapitulasiHasilUsahaToilet from "pages/hasil-usaha/toilet/rekapitulasi";
import TambahRekapilutasiToilet from "pages/hasil-usaha/toilet/rekapitulasi/create";
import DetailRekapitulasiUsahaToilet from "pages/hasil-usaha/toilet/rekapitulasi/detail";
import ReservasiIcon from "icons/reservasi";
import DaftarReservasi from "pages/reservasi/list";
import CreateReservasi from "pages/reservasi/create";
import DaftarPengeluaran from "pages/reservasi/pengeluaran/list";
import CreateBiayaPengeluaran from "pages/reservasi/pengeluaran/create";
import EditBiayaPengeluaran from "pages/reservasi/pengeluaran/edit";
import EditReservasi from "pages/reservasi/edit";
import LaporanPendapatanPenginapan from "pages/hasil-usaha/penginapan/laporan-pendapatan";
import DetailLaporanPendapatanPenginapan from "pages/hasil-usaha/penginapan/laporan-pendapatan/detail";
import RekapitulasiUsahaPenginapan from "pages/hasil-usaha/penginapan/rekapitulasi";
import CreateRekapitulasiPenginapan from "pages/hasil-usaha/penginapan/rekapitulasi/create";
import LaporanPendapatanTiket from "pages/hasil-usaha/tiketing/laporan-pendapatan";
import SettingHargaTiket from "pages/hasil-usaha/tiketing/laporan-pendapatan/setting-harga";
import CreateLaporanPendapatanTiketing from "pages/hasil-usaha/tiketing/laporan-pendapatan/create";
import EditLaporanPendapatanTiketing from "pages/hasil-usaha/tiketing/laporan-pendapatan/edit";
import RekapitulasiTiketingMingguan from "pages/hasil-usaha/tiketing/rekapitulasi";
import PersentaseTiketing from "pages/hasil-usaha/tiketing/rekapitulasi/bagi-hasil";
import DetailPeriodeTiketing from "pages/hasil-usaha/tiketing/rekapitulasi/detail";
import EditProfil from "pages/profil";
import TambahRekapilutasiTiketing from "pages/hasil-usaha/tiketing/rekapitulasi/create";
import KeuanganIcons from "icons/keuangan";
import JurnalUmum from "pages/keuangan/bendahara-1/jurnal-umum";
import CreateTransaction from "pages/keuangan/bendahara-1/create";
import EditTransaction from "pages/keuangan/bendahara-1/edit";
import CreateTransactionDua from "pages/keuangan/bendahara-2/create";
import EditTransactionDua from "pages/keuangan/bendahara-2/edit";
import JurnalUmumDua from "pages/keuangan/bendahara-2/jurnal-umum";

/**
 * ICONS
 */

const DashboardIcons = lazy(() => import("icons/dashboard"));
const DataMasterIcons = lazy(() => import("icons/data-master"));
const CatatanHarianIcons = lazy(() => import("icons/catatan-harian"));
const PengaturanIcons = lazy(() => import("icons/pengaturan"));
const PenggajianIcons = lazy(() => import("icons/penggajian"));

/**
 * PRIVATE ROUTE
 */

const PrivateRoute = lazy(() => import("./private"));

/**
 * AUTH
 *
 */

const LoginPages = lazy(() => import("pages/auth/login"));
const LogoutPages = lazy(() => import("pages/auth/logout"));
const ForgotPasswordPages = lazy(() => import("pages/auth/forgot-password"));
const ChangePasswordPages = lazy(() => import("pages/auth/change-password"));

/**
 * PRIVATE PAGES
 *
 */

const DashboardPages = lazy(() => import("pages/dashboard"));

/**
 * DATA MASTER
 */

// BIDANG

const ListsBidangPages = lazy(() => import("pages/data-master/bidang/lists"));
const CreateBidangPages = lazy(() => import("pages/data-master/bidang/create"));
const UpdateBidangPages = lazy(() => import("pages/data-master/bidang/update"));

// JABATAN

const ListPositionPages = lazy(() => import("pages/data-master/jabatan/lists"));
const CreatePositionPages = lazy(() =>
  import("pages/data-master/jabatan/create")
);
const UpdatePositionPages = lazy(() =>
  import("pages/data-master/jabatan/update")
);

// VENUE

const ListVenuePages = lazy(() => import("pages/data-master/venue/lists"));
const CreateVenuePages = lazy(() => import("pages/data-master/venue/create"));
const UpdateVenuePages = lazy(() => import("pages/data-master/venue/update"));
const Maps2d = lazy(() => import("pages/data-master/venue/map2d"));

const ListsImagesVenuePages = lazy(() =>
  import("pages/data-master/venue/images/lists.js")
);
const CreateImagesVenuePages = lazy(() =>
  import("pages/data-master/venue/images/create")
);

/**
 * CATATAN HARIAN
 */

const ListsCatatanHarianPages = lazy(() =>
  import("pages/catatan-harian/lists")
);
const CreateCatatanHarianPages = lazy(() =>
  import("pages/catatan-harian/create")
);
const UpdateCatatanHarianPages = lazy(() =>
  import("pages/catatan-harian/update")
);

/**
 * SETTINGS
 */

// ROLE MANAGEMENT

const ListsRoleManagementPages = lazy(() =>
  import("pages/settings/role-management/lists")
);
const CreateRoleManagementPages = lazy(() =>
  import("pages/settings/role-management/create")
);
const UpdateRoleManagementPages = lazy(() =>
  import("pages/settings/role-management/update")
);

/**
 * ERROR PAGES
 *
 */

const NotFoundPage = lazy(() => import("pages/errors/404"));

export const routes = [
  {
    exact: true,
    path: "*",
    element: <NotFoundPage />,
  },
  {
    exact: true,
    path: "/",
    element: <Navigate to="/dashboard" />,
  },
  {
    exact: true,
    path: "/masuk",
    element: <LoginPages />,
  },
  {
    exact: true,
    path: "/keluar",
    element: (
      <PrivateRoute withLayout={false}>
        <LogoutPages />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/lupa-password",
    element: <ForgotPasswordPages />,
  },
  {
    exact: true,
    path: "/ubah-password",
    element: <ChangePasswordPages />,
  },
  {
    name: "Dashboard",
    exact: true,
    permission: "view-dashboard",
    path: "/dashboard",
    icon: <DashboardIcons />,
    element: (
      <PrivateRoute>
        <DashboardPages />
      </PrivateRoute>
    ),
  },
  {
    name: "Data Master",
    exact: true,
    icon: <DataMasterIcons />,
    children: [
      {
        name: "Bidang",
        permission: "access-bidang",
        exact: true,
        path: "/data-master/daftar-bidang",
        element: (
          <PrivateRoute>
            <ListsBidangPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/data-master/tambah-bidang",
        permission: "create-bidang",
        element: (
          <PrivateRoute>
            <CreateBidangPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        permission: "edit-bidang",
        path: "/data-master/ubah-bidang/:id",
        element: (
          <PrivateRoute>
            <UpdateBidangPages />
          </PrivateRoute>
        ),
      },
      {
        name: "Jabatan",
        permission: "access-jabatan",
        exact: true,
        path: "/data-master/daftar-jabatan",
        element: (
          <PrivateRoute>
            <ListPositionPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/data-master/tambah-jabatan",
        permission: "create-jabatan",
        element: (
          <PrivateRoute>
            <CreatePositionPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/data-master/ubah-jabatan/:id",
        permission: "edit-jabatan",
        element: (
          <PrivateRoute>
            <UpdatePositionPages />
          </PrivateRoute>
        ),
      },
      // Paket penginapan
      {
        name: "Paket Penginapan",
        permission: "access-paket-penginapan",
        exact: true,
        path: "/data-master/paket-penginapan",
        element: (
          <PrivateRoute>
            <ListPaketPenginapan />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        permission: "create-paket-penginapan",
        path: "/data-master/paket-penginapan/tambah",
        element: (
          <PrivateRoute>
            <CreatePaketPenginapan />
          </PrivateRoute>
        ),
      },
      // End Paket Penginapan
      // Fasilitas
      {
        exact: true,
        permission: "access-fasilitas",
        path: "/data-master/fasilitas",
        element: (
          <PrivateRoute>
            <ListFasilitas />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        permission: "create-fasilitas",
        path: "/data-master/fasilitas/tambah",
        element: (
          <PrivateRoute>
            <CreateFasilitas />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        permission: "edit-fasilitas",
        path: "/data-master/fasilitas/:id/edit",
        element: (
          <PrivateRoute>
            <EditFasilitas />
          </PrivateRoute>
        ),
      },
      // End Fasilitas
      {
        name: "Venue",
        permission: "access-venue",
        exact: true,
        path: "/data-master/daftar-venue/",
        element: (
          <PrivateRoute>
            <ListVenuePages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/data-master/tambah-venue/",
        permission: "create-venue",
        element: (
          <PrivateRoute>
            <CreateVenuePages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/data-master/ubah-venue/:id",
        permission: "edit-venue",
        element: (
          <PrivateRoute>
            <UpdateVenuePages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        permission: "access-katalog",
        path: "/data-master/daftar-gambar-venue/:collection-:id",
        element: (
          <PrivateRoute>
            <ListsImagesVenuePages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        permission: "access-katalog",
        path: "/data-master/tambah-gambar-venue/:collection-:id",
        element: (
          <PrivateRoute>
            <CreateImagesVenuePages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/data-master/katalog-venue/:venueId",
        permission: "access-katalog",
        element: (
          <PrivateRoute>
            <ListKatalogVenue />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/data-master/katalog-venue/:venueId/tambah",
        permission: "create-katalog",
        element: (
          <PrivateRoute>
            <CreateKatalog />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        permission: "edit-katalog",
        path: "/data-master/katalog-venue/:venueId/ubah-katalog/:id",
        element: (
          <PrivateRoute>
            <UpdateKatalog />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        permission: "edit-katalog",
        path: "/data-master/katalog-venue/:venueId/images/:id",
        element: (
          <PrivateRoute>
            <ListKatalogImage />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        permission: "edit-katalog",
        path: "/data-master/katalog-venue/:venueId/images/:id/tambah",
        element: (
          <PrivateRoute>
            <CreateImageKatalogPage />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        permission: "edit-katalog",
        path: "/data-master/katalog-venue/:venueId/detail/:id",
        element: (
          <PrivateRoute>
            <ShowKatalogPage />
          </PrivateRoute>
        ),
      },
    ],
  },

  {
    exact: true,
    permission: "edit-paket-penginapan",
    path: "/data-master/paket-penginapan/:id/edit",
    element: (
      <PrivateRoute>
        <EditPaketPenginapan />
      </PrivateRoute>
    ),
  },

  {
    exact: true,
    path: "/data-master/maps2d",
    element: (
      <PrivateRoute withLayout={false}>
        <Map2d />
      </PrivateRoute>
    ),
  },
  // START RESERVASI
  {
    name: "Reservasi",
    exact: true,
    path: "/reservasi",
    permission: "access-reservasi",
    icon: <ReservasiIcon />,
    element: (
      <PrivateRoute>
        <DaftarReservasi />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/reservasi/tambah",
    permission: "create-reservasi",
    element: (
      <PrivateRoute>
        <CreateReservasi />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/reservasi/:id/edit",
    element: (
      <PrivateRoute>
        <EditReservasi />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/reservasi/biaya-pengeluaran",
    element: (
      <PrivateRoute>
        <DaftarPengeluaran />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/reservasi/biaya-pengeluaran/create",
    element: (
      <PrivateRoute>
        <CreateBiayaPengeluaran />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/reservasi/biaya-pengeluaran/:id/edit",
    element: (
      <PrivateRoute>
        <EditBiayaPengeluaran />
      </PrivateRoute>
    ),
  },
  // END RESERVASI
  {
    name: "Catatan Harian",
    permission: "access-catatan-harian",
    exact: true,
    path: "/catatan-harian/daftar-catatan-harian",
    icon: <CatatanHarianIcons />,
    element: (
      <PrivateRoute>
        <ListsCatatanHarianPages />
      </PrivateRoute>
    ),
  },

  // Laporan Hasil Usaha
  {
    name: "Hasil Usaha",
    exact: true,
    icon: <HasilUsahaIcon />,
    children: [
      {
        name: "Toilet",
        exact: true,
        children: [
          {
            name: "Laporan Pendapatan",
            exact: true,
            permission: "access-hasil-usaha-toilet",
            path: "/hasil-usaha/toilet/laporan-pendapatan",
            element: (
              <PrivateRoute>
                <LaporanPendapatanToilet />
              </PrivateRoute>
            ),
          },
          {
            name: "Rekapitulasi",
            exact: true,
            permission: "access-periode-usaha-toilet",
            path: "/hasil-usaha/toilet/rekapitulasi",
            element: (
              <PrivateRoute>
                <RekapitulasiHasilUsahaToilet />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        name: "Penginapan",
        permission: "access-periode-usaha-penginapan",
        exact: true,
        children: [
          {
            name: "Laporan Pendapatan",
            exact: true,
            path: "/hasil-usaha/penginapan/laporan-pendapatan",
            element: (
              <PrivateRoute>
                <LaporanPendapatanPenginapan />
              </PrivateRoute>
            ),
          },
          {
            name: "Rekapitulasi",
            exact: true,
            path: "/hasil-usaha/penginapan/rekapitulasi",
            permission: "access-periode-usaha-penginapan",
            element: (
              <PrivateRoute>
                <RekapitulasiUsahaPenginapan />
              </PrivateRoute>
            ),
          },
        ],
      },
      {
        name: "Tiketing",
        permission: "access-usaha-tiketing",
        exact: true,
        children: [
          {
            name: "Laporan Pendapatan",
            exact: true,
            path: "/hasil-usaha/tiketing/laporan-pendapatan",
            permission: "view-usaha-tiketing",
            element: (
              <PrivateRoute>
                <LaporanPendapatanTiket />
              </PrivateRoute>
            ),
          },
          {
            name: "Rekapitulasi",
            exact: true,
            path: "/hasil-usaha/tiketing/rekapitulasi",
            permission: "access-periode-usaha-tiketing",
            element: (
              <PrivateRoute>
                <RekapitulasiTiketingMingguan />
              </PrivateRoute>
            ),
          },
        ],
      },
    ],
  },
  {
    name: "Keuangan",
    exact: true,
    icon: <KeuanganIcons />,
    children: [
      {
        name: "Bendahara 1",
        exact: true,
        children: [
          {
            name: "Jurnal Umum",
            exact: true,
            path: "/keuangan/bendahara-1/jurnal-umum",
            permission: "access-bendahara-satu",
            element: (
              <PrivateRoute>
                <JurnalUmum />
              </PrivateRoute>
            ),
          },
          // {
          //   name: "Laporan",
          //   exact: true,
          //   path: "/keuangan/bendahara-1/laporan",
          //   permission: "access-bendahara-satu",
          //   element: <PrivateRoute></PrivateRoute>,
          // },
        ],
      },
      {
        name: "Bendahara 2",
        exact: true,
        children: [
          {
            name: "Jurnal Umum",
            exact: true,
            path: "/keuangan/bendahara-2/jurnal-umum",
            permission: "access-bendahara-dua",
            element: (
              <PrivateRoute>
                <JurnalUmumDua />
              </PrivateRoute>
            ),
          },
          // {
          //   name: "Laporan",
          //   exact: true,
          //   path: "/keuangan/bendahara-2/laporan",
          //   permission: "access-bendahara-dua",
          //   element: <PrivateRoute></PrivateRoute>,
          // },
        ],
      },
    ],
  },
  // Jurnal
  {
    exact: true,
    path: "/keuangan/bendahara-1/jurnal-umum/create",
    permission: "create-bendahara-satu",
    element: (
      <PrivateRoute>
        <CreateTransaction />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/keuangan/bendahara-1/jurnal-umum/:id/edit",
    permission: "edit-bendahara-satu",
    element: (
      <PrivateRoute>
        <EditTransaction />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/keuangan/bendahara-2/jurnal-umum/create",
    element: (
      <PrivateRoute>
        <CreateTransactionDua />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/keuangan/bendahara-2/jurnal-umum/:id/edit",
    element: (
      <PrivateRoute>
        <EditTransactionDua />
      </PrivateRoute>
    ),
  },
  // Tiketing
  {
    exact: true,
    path: "/hasil-usaha/tiketing/rekapitulasi/create",
    element: (
      <PrivateRoute>
        <TambahRekapilutasiTiketing />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/hasil-usaha/tiketing/setting-harga-tiket",
    permission: "access-harga-tiket",
    element: (
      <PrivateRoute>
        <SettingHargaTiket />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/hasil-usaha/tiketing/create",
    permission: "create-usaha-tiketing",
    element: (
      <PrivateRoute>
        <CreateLaporanPendapatanTiketing />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/hasil-usaha/tiketing/:id/edit",
    permission: "edit-usaha-tiketing",
    element: (
      <PrivateRoute>
        <EditLaporanPendapatanTiketing />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/hasil-usaha/tiketing/rekapitulasi/:id/persentase",
    element: (
      <PrivateRoute>
        <PersentaseTiketing />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/hasil-usaha/tiketing/rekapitulasi/:id/detail",
    element: (
      <PrivateRoute>
        <DetailPeriodeTiketing />
      </PrivateRoute>
    ),
  },

  // Toilet
  {
    exact: true,
    path: "/hasil-usaha/toilet/tambah",
    permission: "create-hasil-usaha-toilet",
    element: (
      <PrivateRoute>
        <TambahLaporanPendapatanToilet />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/hasil-usaha/toilet/:id/edit",
    permission: "edit-hasil-usaha-toilet",
    element: (
      <PrivateRoute>
        <EditLaporanPendapatanToilet />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    permission: "access-lokasi-toilet",
    path: "/hasil-usaha/toilet/lokasi",
    element: (
      <PrivateRoute>
        <DaftarLokasiToilet />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    permission: "create-lokasi-toilet",
    path: "/hasil-usaha/toilet/lokasi/tambah",
    element: (
      <PrivateRoute>
        <TambahLokasiToilet />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    permission: "edit-lokasi-toilet",
    path: "/hasil-usaha/toilet/lokasi/edit/:id",
    element: (
      <PrivateRoute>
        <EditLokasiToilet />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    permission: "create-periode-usaha-toilet",
    path: "/hasil-usaha/toilet/rekapitulasi/create",
    element: (
      <PrivateRoute>
        <TambahRekapilutasiToilet />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    permission: "view-periode-usaha-toilet",
    path: "/hasil-usaha/toilet/rekapitulasi/:id/detail",
    element: (
      <PrivateRoute>
        <DetailRekapitulasiUsahaToilet />
      </PrivateRoute>
    ),
  },

  // START PENGINAPAN
  {
    exact: true,
    path: "/hasil-usaha/penginapan/detail",
    element: (
      <PrivateRoute>
        <DetailLaporanPendapatanPenginapan />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/hasil-usaha/penginapan/rekapitulasi/create",
    element: (
      <PrivateRoute>
        <CreateRekapitulasiPenginapan />
      </PrivateRoute>
    ),
  },
  // END PENGINAPAN

  // Penggajian
  {
    name: "Penggajian",
    exact: true,
    path: "/penggajian",
    icon: <PenggajianIcons />,
    children: [
      {
        name: "Daftar",
        exact: true,
        path: "/penggajian",
        permission: "access-periode-gaji",
        element: (
          <PrivateRoute>
            <PenggajianList />
          </PrivateRoute>
        ),
      },
      {
        name: "Setting",
        exact: true,
        permission: "access-penggajian",
        path: "/penggajian/setting",
        element: (
          <PrivateRoute>
            <SettingPenggajian />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    exact: true,
    path: "/penggajian/lembur",
    element: (
      <PrivateRoute>
        <SettingLembur />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/penggajian/gaji-pokok",
    element: (
      <PrivateRoute>
        <SettingGajiPokok />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/penggajian/bonus-tahunan",
    element: (
      <PrivateRoute>
        <SettingBonusTahunan />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/penggajian/tunjangan",
    element: (
      <PrivateRoute>
        <TunjanganJabatan />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/penggajian/periode/:id",
    permission: "view-slip-gaji",
    element: (
      <PrivateRoute>
        <DetailPeriode />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/periode-gaji/create",
    element: (
      <PrivateRoute>
        <CreatePeriodeGaji />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    permission: "create-catatan-harian",
    path: "/catatan-harian/tambah-catatan-harian",
    element: (
      <PrivateRoute>
        <CreateCatatanHarianPages />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    permission: "edit-catatan-harian",
    path: "/catatan-harian/ubah-catatan-harian/:id",
    element: (
      <PrivateRoute>
        <UpdateCatatanHarianPages />
      </PrivateRoute>
    ),
  },
  {
    name: "Karyawan",
    exact: true,
    path: "/karyawan",
    permission: "access-karyawan",
    icon: <KaryawanIcons />,
    element: (
      <PrivateRoute>
        <ListKaryawan />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/karyawan/tambah-karyawan",
    permission: "create-karyawan",
    element: (
      <PrivateRoute>
        <CreateKaryawan />
      </PrivateRoute>
    ),
  },
  {
    exact: true,
    path: "/karyawan/ubah-karyawan/:id",
    permission: "edit-karyawan",
    element: (
      <PrivateRoute>
        <UpdateKaryawan />
      </PrivateRoute>
    ),
  },
  {
    name: "Presensi",
    permission: "access-presensi",
    exact: true,
    icon: <PresensiIcons />,
    children: [
      {
        name: "Presensi",
        exact: true,
        path: "/presensi",
        permission: "access-presensi",
        element: (
          <PrivateRoute>
            <Presensi />
          </PrivateRoute>
        ),
      },
      {
        name: "Izin",
        exact: true,
        path: "/izin",
        permission: "access-perizinan",
        element: (
          <PrivateRoute>
            <ListIzin />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    // Tambah Presensi
    exact: true,
    path: "/presensi/add",
    permission: "create-presensi-karyawan",
    element: (
      <PrivateRoute>
        <AturPresensi />
      </PrivateRoute>
    ),
  },
  {
    // Presensi Barcode
    exact: true,
    path: "/presensi/new-presence",
    element: (
      <PrivateRoute>
        <InputNIP />
      </PrivateRoute>
    ),
  },
  {
    // Tambah Perizinan
    exact: true,
    path: "/perizinan/add",
    element: (
      <PrivateRoute>
        <CreatePerizinan />
      </PrivateRoute>
    ),
  },
  {
    // Ubah Perizinan
    exact: true,
    path: "/perizinan/edit/:id",
    permission: "view-perizinan",
    element: (
      <PrivateRoute>
        <DetailPerizinan />
      </PrivateRoute>
    ),
  },
  {
    // Presensi Barcode
    exact: true,
    path: "/presensi/barcode",
    element: (
      <PrivateRoute hideSidebar={true} topRightComponent={<DateTimeDisplay />}>
        <AttendanceBarcode />
      </PrivateRoute>
    ),
  },
  {
    name: "Setting",
    exact: true,
    icon: <PengaturanIcons />,
    children: [
      {
        name: "User Management",
        exact: true,
        path: "/pengaturan/user-manajemen/daftar-user",
        permission: "access-user",
        element: (
          <PrivateRoute>
            <UserList />
          </PrivateRoute>
        ),
      },
      {
        name: "Role Management",
        exact: true,
        path: "/pengaturan/role-manajemen/daftar-role",
        permission: "access-role",
        element: (
          <PrivateRoute>
            <ListsRoleManagementPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/pengaturan/role-manajemen/tambah-role",
        permission: "create-role",
        element: (
          <PrivateRoute>
            <CreateRoleManagementPages />
          </PrivateRoute>
        ),
      },
      {
        exact: true,
        path: "/pengaturan/role-manajemen/ubah-role/:id",
        permission: "edit-role",
        element: (
          <PrivateRoute>
            <UpdateRoleManagementPages />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    exact: true,
    path: "/profile",
    element: (
      <PrivateRoute>
        <EditProfil />
      </PrivateRoute>
    ),
  },
];
