import { useState, useMemo, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTable, useSortBy, useExpanded } from "react-table";
import { Spinner, Pagination } from "react-bootstrap";
import { useDebounce } from "use-debounce";
import { useLocation, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { setTriggerTable } from "redux/actions";
import { request } from "utils/request";
import NoDataIlustration from "assets/png/no-data.png";
import _ from "lodash";

const DynamicTable = ({
  url,
  placeHolderSearch,
  columns,
  customData,
  expandColumns,
  expandData,
  loadingExpand,
  expandedID,
  newQueryParams,
  children,
  childLeft,
  loadingRender,
  withSearch,
  title,
  exportable,
  watchTableContent,
  searchableCustomData,
  onSearchCustomData,
  defaultFilter,
  filter,
  onTableDataChange,
  tableHeaderStyle,
  onSearchChange,
}) => {
  const dispatch = useDispatch();
  const { triggerTable } = useSelector((state) => state.app);
  const [tableData, setDataTable] = useState(customData ?? []);
  const [searchValue, setSearchValue] = useState("");
  const [loadingTable, setLoadingTable] = useState(false);
  const [metaTable, setMetaTable] = useState({
    page: 1,
    per_page: 10,
  });
  const navigate = useNavigate();
  const [debounceValue] = useDebounce(searchValue, 1000);

  const dataTable = useMemo(() => {
    if (!_.isEmpty(filter)) {
      return tableData.filter((item) => {
        return Object.keys(filter)
          .filter((key) => {
            return filter[key] !== null && filter[key] !== undefined;
          })
          .every((key) => {
            return item[key] === filter[key];
          });
      });
    }
    return tableData;
  }, [tableData, filter]);

  const { search: searchParams } = useLocation();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: dataTable,
      },
      useSortBy,
      useExpanded
    );

  const {
    getTableProps: getTablePropsExpand,
    getTableBodyProps: getTableBodyPropsExpand,
    headerGroups: headerGroupExpand,
    rows: rowsExpand,
    prepareRow: prepareRowExpand,
  } = useTable(
    {
      columns: expandColumns,
      data: expandData,
    },
    useSortBy
  );

  const watchPageQuery = new URLSearchParams(searchParams).get("page") ?? 1;
  const watchSearchQuery = new URLSearchParams(searchParams).get("q");
  const watchPerPageQuery =
    new URLSearchParams(searchParams).get("per_page") ?? 10;

  const isPrevNumber = parseInt(watchPageQuery) - 1;
  const isNextNumber = parseInt(watchPageQuery) + 1;

  const handleBackEvent = () => {
    navigate(-1, { replace: true });
  };

  const handlePrevTable = () =>
    navigate(
      `?page=${isPrevNumber}&per_page=${watchPerPageQuery}&q=${watchSearchQuery}`
    );

  const handleNextTable = () =>
    navigate(
      `?page=${isNextNumber}&per_page=${watchPerPageQuery}&q=${watchSearchQuery}`
    );

  const handleRequestDataTable = async () => {
    if (url) {
      setLoadingTable(true);

      const requestURL = watchSearchQuery
        ? `${url}?sort=id&order=desc&page=${watchPageQuery ?? "1"}&per_page=${
            watchPerPageQuery ?? "5"
          }${
            watchSearchQuery ? `&search=${watchSearchQuery}` : ""
          }${newQueryParams}`
        : `${url}?sort=id&order=desc&page=${watchPageQuery ?? "1"}&per_page=${
            watchPerPageQuery ?? "5"
          }${
            watchSearchQuery ? `&search=${watchSearchQuery}` : ""
          }${newQueryParams}`;

      try {
        const response = await request.get(requestURL);

        setDataTable(response.data.data ?? []);
        if (onTableDataChange) {
          onTableDataChange(response.data.data);
        }
        setMetaTable((prevMeta) => ({
          ...prevMeta,
          ...response.data.meta,
        }));
      } catch (err) {
        setDataTable([]);
      } finally {
        setLoadingTable(false);
      }
    }
  };

  const spring = useMemo(
    () => ({
      type: "spring",
      damping: 50,
      stiffness: 100,
    }),
    []
  );

  const disabledPaginationPrevPage = parseInt(watchPageQuery) - 1 <= 0;
  const disabledPaginationNextPage =
    metaTable?.current_page === metaTable?.last_page ||
    dataTable?.length < metaTable?.per_page;

  // useEffect(() => {
  //   if (isNaN(isPrevNumber) && isNaN(isNextNumber)) {
  //     navigate(-1);
  //   }
  // }, [isPrevNumber, isNextNumber]);

  useEffect(() => {
    if (debounceValue || debounceValue === "") {
      if (watchPageQuery && watchPerPageQuery) {
        navigate(`?page=${watchPageQuery}&per_page=${watchPerPageQuery}&q=`);
      }

      navigate(`?page=1&per_page=10&q=${debounceValue}`);
    }
  }, [debounceValue]);

  useEffect(() => {
    const checkQueryParam =
      watchPageQuery || watchPerPageQuery || watchSearchQuery;

    if (checkQueryParam || triggerTable || newQueryParams) {
      handleRequestDataTable();
    }

    return () => dispatch(setTriggerTable(false));
  }, [
    triggerTable,
    watchPageQuery,
    watchPerPageQuery,
    watchSearchQuery,
    newQueryParams,
  ]);

  useEffect(() => {
    window.addEventListener("popstate", handleBackEvent);

    return () => window.removeEventListener("popstate", handleBackEvent);
  }, []);

  const firstPageRows = rows.slice(0, dataTable.length);
  const firstPageRowsExpand = rowsExpand.slice(0, dataTable.length);

  useEffect(() => {
    watchTableContent(dataTable);
  }, [dataTable]);

  useEffect(() => {
    if (customData) setDataTable(customData);
  }, [customData]);

  return (
    <>
      <div className="d-flex mb-3 px-1">{children}</div>
      <div className="card">
        <div className="card-header border-bottom" style={tableHeaderStyle}>
          <div className="d-flex flex-column flex-md-row gap-3 justify-content-between">
            <div>{childLeft && childLeft}</div>
            {!customData && withSearch && (
              <div className="d-flex gap-3">
                <div className="input-group input-group-sm input-group-inline">
                  <span className="input-group-text pe-2">
                    <i className="bi bi-search"></i>
                  </span>

                  <input
                    type="search"
                    className="form-control"
                    placeholder={`Cari ${String(title).toLowerCase()}`}
                    aria-label={placeHolderSearch}
                    defaultValue={watchSearchQuery}
                    onChange={(e) => {
                      if (onSearchChange) {
                        onSearchChange(e.target.value);
                        return;
                      }
                      setSearchValue(e.target.value);
                    }}
                  />
                </div>
              </div>
            )}
            {customData && searchableCustomData && (
              <div className="d-flex gap-3">
                <div className="input-group input-group-sm input-group-inline">
                  <span className="input-group-text pe-2">
                    <i className="bi bi-search"></i>
                  </span>

                  <input
                    type="search"
                    className="form-control"
                    placeholder={`Cari ${String(title).toLowerCase()}`}
                    aria-label={placeHolderSearch}
                    defaultValue={watchSearchQuery}
                    onChange={(e) => {
                      if (onSearchChange) {
                        onSearchChange(e.target.value);
                        return;
                      }
                      setSearchValue(e.target.value);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="table-responsive">
          {loadingTable || loadingRender ? (
            <div className="py-20">
              <Spinner className="d-block mx-auto" animation="border" />
            </div>
          ) : dataTable?.length > 0 ? (
            <PerfectScrollbar>
              <table
                className="table table-hover table-nowrap"
                {...getTableProps()}
              >
                <thead className="table-light">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup?.headers?.map((column) => (
                        <motion.th
                          {...column.getHeaderProps({
                            layoutTransition: spring,
                            style: {
                              minWidth: column.minWidth,
                            },
                          })}
                          rowSpan={column.rowSpan ?? 1}
                          style={column.displayNone ? { display: "none" } : {}}
                        >
                          <div {...column.getSortByToggleProps()}>
                            {column.render("Header")}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <i className="bi bi-arrow-down-short" />
                                ) : (
                                  <i className="bi bi-arrow-up-short" />
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        </motion.th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  <AnimatePresence>
                    {firstPageRows.map((row, i) => {
                      prepareRow(row);
                      return (
                        <Fragment key={`${i}_tr`}>
                          <motion.tr
                            {...row.getRowProps({
                              layoutTransition: spring,
                              exit: { opacity: 0, maxHeight: 0 },
                            })}
                          >
                            {row.cells.map((cell) => {
                              return (
                                <motion.td
                                  {...cell.getCellProps({
                                    style: {
                                      layoutTransition: spring,
                                    },
                                  })}
                                >
                                  {cell.render("Cell")}
                                </motion.td>
                              );
                            })}
                          </motion.tr>

                          {/* EXPANDED ROWS */}

                          <tr
                            className={`collapse p-5 ${
                              parseInt(row.original.id) ===
                                parseInt(expandedID) && !customData
                                ? "show"
                                : ""
                            }`}
                          >
                            <td colSpan={3}>
                              <div className="table-responsive">
                                {loadingExpand ? (
                                  <div className="py-20">
                                    <Spinner
                                      className="d-block mx-auto"
                                      animation="border"
                                    />
                                  </div>
                                ) : expandData?.length < 0 && !customData ? (
                                  <div className="d-flex flex-column gap-16 py-16 align-items-center justify-content-center">
                                    <img
                                      width="15%"
                                      src={NoDataIlustration}
                                      alt="nodata"
                                    />

                                    <p className="text-center text-md text-dark">
                                      Opps, result not found.
                                    </p>
                                  </div>
                                ) : (
                                  <table
                                    className="table table-hover table-nowrap"
                                    {...getTablePropsExpand()}
                                  >
                                    <thead className="table-light">
                                      {headerGroupExpand.map((headerGroup) => (
                                        <tr
                                          {...headerGroup.getHeaderGroupProps()}
                                        >
                                          {headerGroup?.headers?.map(
                                            (column) => (
                                              <motion.th
                                                {...column.getHeaderProps({
                                                  layoutTransition: spring,
                                                  style: {
                                                    minWidth: column.minWidth,
                                                  },
                                                })}
                                                rowSpan={column.rowSpan ?? 1}
                                                style={
                                                  column.displayNone
                                                    ? { display: "none" }
                                                    : {}
                                                }
                                              >
                                                <div
                                                  {...column.getSortByToggleProps()}
                                                >
                                                  {column.render("Header")}
                                                  <span>
                                                    {column.isSorted ? (
                                                      column.isSortedDesc ? (
                                                        <i className="bi bi-arrow-down-short" />
                                                      ) : (
                                                        <i className="bi bi-arrow-up-short" />
                                                      )
                                                    ) : (
                                                      ""
                                                    )}
                                                  </span>
                                                </div>
                                              </motion.th>
                                            )
                                          )}
                                        </tr>
                                      ))}
                                    </thead>

                                    <tbody {...getTableBodyPropsExpand()}>
                                      <AnimatePresence>
                                        {firstPageRowsExpand.map((row, i) => {
                                          prepareRowExpand(row);

                                          return (
                                            <motion.tr
                                              {...row.getRowProps({
                                                layoutTransition: spring,
                                                exit: {
                                                  opacity: 0,
                                                  maxHeight: 0,
                                                },
                                              })}
                                            >
                                              {row.cells.map((cell) => {
                                                return (
                                                  <motion.td
                                                    {...cell.getCellProps({
                                                      style: {
                                                        layoutTransition:
                                                          spring,
                                                      },
                                                    })}
                                                  >
                                                    {cell.render("Cell")}
                                                  </motion.td>
                                                );
                                              })}
                                            </motion.tr>
                                          );
                                        })}
                                      </AnimatePresence>
                                    </tbody>
                                  </table>
                                )}
                              </div>
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })}
                  </AnimatePresence>
                </tbody>
              </table>
            </PerfectScrollbar>
          ) : (
            <div className="d-flex flex-column gap-16 py-16 align-items-center justify-content-center">
              <img width="15%" src={NoDataIlustration} alt="nodata" />

              <p className="text-center text-md text-dark">
                Opps, result not found.
              </p>
            </div>
          )}
        </div>

        {metaTable?.total && !customData ? (
          <div className="card-footer border-0">
            <div className="row align-items-center">
              <div className="col-lg d-lg-block">
                {metaTable && (
                  <nav>
                    <ul className="pagination pagination-sm pagination-spaced gap-2 align-items-center justify-content-start">
                      <li className="page-item">
                        <span className="text-muted text-sm">Showing:</span>
                      </li>
                      <li className="page-item">
                        <select
                          style={{ width: 50 }}
                          value={watchPerPageQuery}
                          className="page-link text-black"
                          onChange={(val) =>
                            navigate(
                              `?page=${watchPageQuery}&per_page=${val.target.value}&q=${watchSearchQuery}`
                            )
                          }
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={75}>75</option>
                          <option value={100}>100</option>
                        </select>
                      </li>
                      <li className="page-item">
                        <span className="text-muted text-sm">
                          of {dataTable?.length}
                        </span>
                      </li>
                    </ul>
                  </nav>
                )}
              </div>
              <div className="col-lg-8">
                <Pagination className="pagination pagination-sm pagination-spaced gap-2 align-items-center justify-content-end">
                  <li
                    className={`page-item ${
                      disabledPaginationPrevPage ? "disabled" : ""
                    }`}
                  >
                    <a
                      className="page-link border-0"
                      onClick={handlePrevTable}
                      style={{ cursor: "pointer" }}
                    >
                      Prev
                    </a>
                  </li>

                  {!disabledPaginationPrevPage && (
                    <Pagination.Item onClick={handlePrevTable}>
                      {isPrevNumber}
                    </Pagination.Item>
                  )}
                  <Pagination.Item active>{watchPageQuery}</Pagination.Item>
                  {!disabledPaginationNextPage && (
                    <Pagination.Item onClick={handleNextTable}>
                      {isNextNumber}
                    </Pagination.Item>
                  )}
                  <li
                    className={`page-item ${
                      disabledPaginationNextPage ? "disabled" : ""
                    }`}
                  >
                    <a
                      className="page-link border-0"
                      onClick={handleNextTable}
                      style={{ cursor: "pointer" }}
                    >
                      Next
                    </a>
                  </li>
                </Pagination>
              </div>
            </div>
          </div>
        ) : null}

        {/* {metaTable?.total && !customData ? (
          <div className="card-footer border-0">
            <div className="row align-items-center">
              <div className="col-lg d-lg-block">
                {metaTable && (
                  <nav>
                    <ul className="pagination pagination-sm pagination-spaced gap-2 align-items-center justify-content-start">
                      <li className="page-item">
                        <span className="text-muted text-sm">Showing:</span>
                      </li>
                      <li className="page-item">
                        <select
                          style={{ width: 50 }}
                          value={watchPerPageQuery}
                          className="page-link bg-primary text-white"
                          onChange={(val) =>
                            navigate(
                              `?page=${watchPageQuery}&per_page=${val.target.value}&q=${watchSearchQuery}`
                            )
                          }
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={75}>75</option>
                          <option value={100}>100</option>
                        </select>
                      </li>
                      <li className="page-item">
                        <span className="text-muted text-sm">
                          of {dataTable?.length}
                        </span>
                      </li>
                    </ul>
                  </nav>
                )}
              </div>
              <div className="col-lg-8">
                <Pagination className="pagination pagination-sm pagination-spaced gap-2 align-items-center justify-content-end">
                  <li
                    className={`page-item ${
                      disabledPaginationPrevPage ? "disabled" : ""
                    }`}
                  >
                    <a
                      className="page-link border-0"
                      onClick={handlePrevTable}
                      style={{ cursor: "pointer" }}
                    >
                      Prev
                    </a>
                  </li>

                  {!disabledPaginationPrevPage && (
                    <Pagination.Item onClick={handlePrevTable}>
                      {isPrevNumber}
                    </Pagination.Item>
                  )}
                  <Pagination.Item active>{watchPageQuery}</Pagination.Item>
                  {!disabledPaginationNextPage && (
                    <Pagination.Item onClick={handleNextTable}>
                      {isNextNumber}
                    </Pagination.Item>
                  )}
                  <li
                    className={`page-item ${
                      disabledPaginationNextPage ? "disabled" : ""
                    }`}
                  >
                    <a
                      className="page-link border-0"
                      onClick={handleNextTable}
                      style={{ cursor: "pointer" }}
                    >
                      Next
                    </a>
                  </li>
                </Pagination>
              </div>
            </div>
          </div>
        ) : null} */}
      </div>
    </>
  );
};

DynamicTable.defaultProps = {
  columns: [],
  expandData: [],
  expandColumns: [],
  placeHolderSearch: `Cari `,
  newQueryParams: "",
  loadingRender: false,
  withSearch: true,
  title: "",
  exportable: false,
  watchTableContent: () => {},
  searchable: true,
  searchableCustomData: false,
  onSearchCustomData: () => {},
  defaultFilter: null,
  filter: {},
  onTableDataChange: () => {},
  tableHeaderStyle: {},
  onSearchChange: null,
};

DynamicTable.propTypes = {
  children: PropTypes.node,
  placeHolderSearch: PropTypes.string,
  columns: PropTypes.array.isRequired,
  expandColumns: PropTypes.array,
  expandData: PropTypes.array,
  loadingExpand: PropTypes.bool,
  expandedID: PropTypes.number,
  newQueryParams: PropTypes.string,
  childLeft: PropTypes.node,
  loadingRender: PropTypes.bool,
  withSearch: PropTypes.bool,
  title: PropTypes.string,
  exportable: PropTypes.bool,
  watchTableContent: PropTypes.func,
  searchable: PropTypes.bool,
  searchableCustomData: PropTypes.bool,
  onSearchCustomData: PropTypes.func,
  defaultFilter: PropTypes.string,
  filter: PropTypes.object,
  onTableDataChange: PropTypes.func,
  tableHeaderStyle: PropTypes.object,
  onSearchChange: PropTypes.func,
};

export default DynamicTable;
