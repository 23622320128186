import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";

const PageHeaderBtns = ({ title, btns }) => {
  const navigate = useNavigate();

  return (
    <div>
      <Helmet title={`${title ?? ""} - Taman Tebing Breksi`} />

      <div className="border-bottom row align-items-center mb-5 py-5">
        <div className="col-sm col-12">
          <div className="d-flex">
            {/* {title?.map((el, idx) => (
                        <h1 className="h2 ls-tight">
                            <span className="d-inline-block text-primary me-1">{el}</span>
                            {idx !== title?.length - 1 && (
                            <i
                                class="bi bi-chevron-right me-1"
                                style={{ fontSize: "20px", color: "#5c60f5" }}
                            ></i>
                            )}
                        </h1>
                        ))} */}
            <h1 className="h2 ls-tight">
              <span
                style={{ letterSpacing: ".05rem" }}
                className="d-inline-block text-primary me-1"
              >
                {title}
              </span>
            </h1>
          </div>
        </div>

        <div className="col-sm-auto col-12 mt-4 mt-sm-0">
          <div className="hstack gap-2 justify-content-sm-end">
            {btns?.map((btn) => {
              if (btn.visible !== undefined && btn.visible === false)
                return <></>;
              return (
                <>
                  {btn.url ? (
                    <a
                      href={btn.url}
                      className="btn btn-sm btn-primary"
                      target="blank"
                    >
                      <span className="pe-2">
                        <i className={btn.icon}></i>
                      </span>
                      <span>{btn.name}</span>
                    </a>
                  ) : (
                    <Link
                      className={`btn btn-sm ${
                        btn?.type ? btn.type : "btn-primary"
                      }`}
                      to={btn.path}
                      target={btn.newTab ? "_blank" : null}
                    >
                      <span className="pe-2">
                        <i className={btn.icon}></i>
                      </span>
                      <span>{btn.name}</span>
                    </Link>
                  )}
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageHeaderBtns;
