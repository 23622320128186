import CreateContainer from "containers/create-container";
import { useForm } from "react-hook-form";
import FormInput from "components/form-input";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { request } from "utils/request";
import { toast } from "react-hot-toast";

const pricingKendaraan = [
    {
        name: "parkir_motor",
        label: "Motor",
    },
    {
        name: "parkir_mobil",
        label: "Mobil",
    },
    {
        name: "parkir_minibus",
        label: "Minibus",
    },
    {
        name: "parkir_bus",
        label: "Bus",
    },
]

const dispensasi = [
    {
        name: "dispensasi_sekolah",
        label: "Sekolah"
    },
    {
        name: "dispensasi_umum",
        label: "Umum"
    }
]

const CreateLaporanPendapatanTiketing = () => {
    const navigate = useNavigate()
    const [loading,setLoading] = useState(false)

    const {
        control,
        formState: { errors },
        handleSubmit
      } = useForm();

    const onSubmit = (values) => {
        setLoading(true)
        const payload = Object.keys(values)
            .reduce((acc,cur) => {
                acc[cur] = String(values[cur]).replace(' kendaraan','').replace(' orang', '').split(',').join('')
                return acc
            },{})
        request.post("/api/usaha-tiketing", payload)
            .then((res) => {
                toast.success("Berhasil menambahkan data")
                navigate("/hasil-usaha/tiketing/laporan-pendapatan")
            })
            .catch((err) => {
                toast.error("Gagal menambahkan data")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <CreateContainer
            title="Tambah Laporan Pendapatan Tiketing per Hari"
            loading={loading}
            loadingText="Menambahkan data"
            onSubmit={handleSubmit(onSubmit)}
        >
            <FormInput
                required
                label="Tanggal"
                name="tanggal"
                inputType="date"
                errors={errors}
                control={control}
                placeholder="Masukkan tanggal"
                errorMessage="Tanggal tidak boleh kosong!"
                isClearable={true}
            />
            <FormInput
                required
                maskOptions={{
                    prefix: "",
                    suffix: " orang",
                }}
                errors={errors}
                name="pengunjung_domestik"
                label="Domestik"
                control={control}
                inputType="prefix_suffix"
                placeholder="Masukkan pengunjung domestik"
                errorMessage="Jumlah pengunjung domestik tidak boleh kosong !"
            />
            <FormInput
                required
                maskOptions={{
                    prefix: "",
                    suffix: " orang",
                }}
                errors={errors}
                name="pengunjung_mancanegara"
                label="manca"
                control={control}
                inputType="prefix_suffix"
                placeholder="Masukkan pengunjung manca"
                errorMessage="Jumlah pengunjung manca tidak boleh kosong !"
            />
            <div style={{ marginBottom: "30px" }}>
                <div
                    style={{
                        marginBottom: '11px',
                        color: "#000000"
                    }}
                >
                    Parkir
                </div>
                <div
                    style={{
                        backgroundColor: '#FFFFFF',
                        borderRadius: "7px",
                        border: "1px solid #DEDFE1",
                        padding: "40px"
                    }}
                >
                    {pricingKendaraan.map((item) => {
                        return (
                            <FormInput
                                required
                                errors={errors}
                                name={item.name}
                                label={item.label}
                                control={control}
                                inputType="prefix_suffix"
                                maskOptions={{
                                    prefix: "",
                                    suffix: " kendaraan",
                                }}
                                placeholder={`Masukkan jumlah ${String(item.label).toLowerCase()}`}
                                errorMessage="Kendaraan tidak boleh kosong!"
                            />
                        )
                    })}
                </div>
            </div>
            <div style={{ marginBottom: "30px" }}>
                <div
                    style={{
                        marginBottom: '11px',
                        color: "#000000"
                    }}
                >
                    Dispensasi
                </div>
                <div
                    style={{
                        backgroundColor: '#FFFFFF',
                        borderRadius: "7px",
                        border: "1px solid #DEDFE1",
                        padding: "40px"
                    }}
                >
                    {dispensasi.map((item) => {
                        return (
                            <FormInput
                                required
                                errors={errors}
                                name={item.name}
                                label={item.label}
                                control={control}
                                inputType="prefix_suffix"
                                maskOptions={{
                                    prefix: "",
                                    suffix: " orang",
                                }}
                                placeholder="Masukkan dispensasi"
                                errorMessage="Dispensasi tidak boleh kosong!"
                            />
                        )
                    })}
                </div>
            </div>
        </CreateContainer>
    )
}

export default CreateLaporanPendapatanTiketing;