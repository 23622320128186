import {
    Modal
} from "react-bootstrap";
import { format } from 'date-fns'
import { id } from "date-fns/locale";

const ModalPresensi = ({
    show,
    onHide,
    title,
    onConfirm,
    data,
    onClickOutside
}) => {
    const date = new Date()
    return (
        <Modal show={show} onHide={onClickOutside} size="md" centered>
            <Modal.Header className="bg-primary">
                <Modal.Title className="text-white">
                    {title}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <table className="table table-sm table-borderless" style={{width: "100%"}}>
                    <tbody>
                        <tr>
                            <td>Status Presensi</td>
                            <td>: {data?.status?.label}</td>
                        </tr>
                        <tr>
                            <td>Jam Masuk</td>
                            <td>: {data?.start}</td>
                        </tr>
                        <tr>
                            <td>Jam Pulang</td>
                            <td>: {data?.end} </td>
                        </tr>
                        <tr>
                            <td>Tanggal</td>
                            <td>: {format(date,'EEEE, dd MMMM yyyy', { locale: id })}</td>
                        </tr>
                    </tbody>
                </table>
            </Modal.Body>

            <Modal.Footer>
                <div
                    style={{
                        flexGrow: 1,
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        gap: '40px'
                    }}
                >
                    <button
                        type="button"
                        className="btn btn-sm btn-neutral"
                        onClick={onHide}
                    >
                        Atur Ulang
                    </button>
                    <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={onConfirm}
                    >
                        Lakukan Presensi
                    </button>
                </div>
            </Modal.Footer>

        </Modal>
    )
}

export default ModalPresensi;