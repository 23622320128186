import DynamicTable from "components/dynamic-table";
import PageHeader from "components/page-header";
import { toRupiah } from "utils/helper";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import FormInput from "components/form-input";
import { format } from "date-fns";
import { id } from "date-fns/locale";

const LaporanPendapatanPenginapan = () => {
  const navigate = useNavigate();
  const {
    control,
    watch,
    formState: { errors },
  } = useForm();
  const filterTanggal = watch("tanggal");

  const columns = [
    {
      Header: "No",
      accessor: "nomor",
    },
    {
      Header: "Tanggal",
      accessor: "tanggal_mulai",
      Cell: ({ row }) => {
        return format(new Date(row?.original?.tanggal_mulai), "dd MMMM yyyy", {
          locale: id,
        });
      },
    },
    {
      Header: "Total masuk",
      accessor: "jumlah_harga_penginapan",
      Cell: ({ row }) => {
        return toRupiah(row?.original?.jumlah_pembayaran_total);
      },
    },
    {
      Header: "Total keluar",
      accessor: "jumlah_harga_pengeluaran",
      Cell: ({ row }) => {
        return toRupiah(row?.original?.jumlah_pengeluaran_total);
      },
    },
    {
      Header: "Total pendapatan",
      accessor: "total_pendapatan",
      Cell: ({ row }) => {
        return toRupiah(Number(row?.original?.jumlah_pendapatan_total));
      },
    },
    {
      Header: "Aksi",
      accessor: "aksi",
      Cell: ({ row }) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="button-tooltip">Detail</Tooltip>}
            >
              <button
                type="button"
                className="btn btn-sm btn-square btn-neutral text-warning-hover ms-2"
                onClick={() => {
                  navigate(
                    `/hasil-usaha/penginapan/detail?tanggal=${row.original.tanggal_mulai}`
                  );
                }}
              >
                <i className="bi bi-info-circle"></i>
              </button>
            </OverlayTrigger>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <PageHeader
        title="Laporan Pendapatan Penginapan per Hari"
        columns={columns}
      />
      <div style={{ marginBottom: "24px" }}>
        <FormInput
          name="tanggal"
          label="Tanggal"
          inputType="date"
          errors={errors}
          control={control}
          placeholder="Pilih tanggal"
          errorMessage="Waktu tidak boleh kosong!"
          isClearable={true}
          mb={0}
          containerStyle={{
            width: "402px",
          }}
          options={{
            mode: "range",
          }}
        />
      </div>
      <DynamicTable
        columns={columns}
        url="/api/reservasi"
        newQueryParams={`&per_hari=1&sort=tanggal_mulai${
          filterTanggal
            ? `&tanggal_mulai=${
                filterTanggal?.split(" - ")?.[0]
              }&tanggal_selesai=${filterTanggal?.split(" - ")?.[1]}`
            : ""
        }`}
        withSearch={false}
      />
    </>
  );
};

export default LaporanPendapatanPenginapan;
