import PageHeaderBtns from "components/page-header-btns";
import { useForm } from "react-hook-form";
import FormInput from "components/form-input";
import DynamicTable from "components/dynamic-table";
import { format } from "date-fns";
import { id } from "date-fns/locale";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import CheckPermission from "components/CheckPermission";
import useHasPermission from "hooks/useHasPermission";
import { toRupiah } from "utils/helper";

const LaporanPendapatanTiket = () => {
  const mayCreateTiketing = useHasPermission("create-usaha-tiketing");
  const navigate = useNavigate();
  const {
    control,
    watch,
    formState: { errors },
  } = useForm();
  const filterTanggal = watch("tanggal");

  const newQueryParams = useMemo(() => {
    if (!filterTanggal) return "";
    const [from, to] = String(filterTanggal).split(" - ");
    return `${from ? `&from=${from}` : ""}${to ? `&to=${to}` : ""}`;
  }, [filterTanggal]);

  const mayCreateHargaTiket = useHasPermission("create-harga-tiket");

  const btns = useMemo(() => {
    return [
      {
        name: "Setting Harga Tiket",
        path: "/hasil-usaha/tiketing/setting-harga-tiket",
        url: "",
        icon: "bi bi-gear",
        newTab: false,
        visible: mayCreateHargaTiket,
      },
      {
        name: "Tambah",
        path: "/hasil-usaha/tiketing/create",
        url: "",
        icon: "bi bi-plus-square-dotted",
        newTab: false,
        visible: mayCreateTiketing,
      },
    ];
  }, [mayCreateTiketing]);

  const columns = [
    {
      Header: "No",
      accessor: "nomor",
    },
    {
      Header: "Tanggal",
      accessor: "tanggal",
      Cell: ({ row }) => {
        const [tahun, bulan, tanggal] = String(row?.original?.tanggal).split(
          "-"
        );
        return format(
          new Date(`${bulan}-${tanggal}-${tahun}`),
          "dd MMMM yyyy",
          { locale: id }
        );
      },
    },
    {
      Header: "Keterangan",
      accessor: "keterangan",
      columns: [
        {
          Header: "Orang",
          accessor: "orang",
          columns: [
            {
              Header: "Domestik",
              accessor: "pengunjung_domestik",
            },
            {
              Header: "Manca",
              accessor: "pengunjung_mancanegara",
            },
          ],
        },
        {
          Header: "Kendaraan",
          accessor: "kendaraan",
          columns: [
            {
              Header: "Motor",
              accessor: "parkir_motor",
            },
            {
              Header: "Mobil",
              accessor: "parkir_mobil",
            },
            {
              Header: "Minibus",
              accessor: "parkir_minibus",
            },
            {
              Header: "Bus",
              accessor: "parkir_bus",
            },
          ],
        },
        {
          Header: "Dispensasi",
          accessor: "dispensasi",
          columns: [
            {
              Header: "Sekolah",
              accessor: "dispensasi_sekolah",
            },
            {
              Header: "Umum",
              accessor: "dispensasi_umum",
            },
          ],
        },
      ],
    },
    {
      Header: "Pendapatan",
      accessor: "total_pendapatan",
      Cell: ({ row }) => toRupiah(row?.original?.total_pendapatan),
    },
    {
      Header: "Aksi",
      accessor: "aksi",
      Cell: ({ row }) => {
        return (
          <>
            <CheckPermission permission="edit-usaha-tiketing">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip">Edit</Tooltip>}
              >
                <button
                  type="button"
                  className="btn btn-sm btn-square btn-neutral text-warning-hover ms-2"
                  onClick={() => {
                    navigate(`/hasil-usaha/tiketing/${row.original.id}/edit`);
                  }}
                >
                  <i className="bi bi-pencil"></i>
                </button>
              </OverlayTrigger>
            </CheckPermission>
          </>
        );
      },
    },
  ];

  return (
    <div style={{ textAlign: "center" }}>
      <PageHeaderBtns
        title="Laporan Pendapatan Tiketing per Hari"
        btns={btns}
      />
      <div style={{ marginBottom: "24px" }}>
        <FormInput
          name="tanggal"
          label="Tanggal"
          inputType="date"
          errors={errors}
          control={control}
          placeholder="Pilih tanggal"
          errorMessage="Waktu tidak boleh kosong!"
          isClearable={true}
          mb={0}
          containerStyle={{
            width: "402px",
          }}
          options={{
            mode: "range",
          }}
        />
      </div>
      <DynamicTable
        columns={columns}
        url="/api/usaha-tiketing"
        withSearch={false}
        newQueryParams={newQueryParams}
      />
    </div>
  );
};

export default LaporanPendapatanTiket;
