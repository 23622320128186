import { useEffect, useMemo, useState } from "react";
import CreateContainer from "containers/create-container";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import FormInput from "components/form-input";
import { request } from "utils/request";
import { toast } from "react-hot-toast";
import { toRupiah } from "utils/helper";

const dataVenue = [
  {
    value: 16,
    label: "Watu Tapak",
  },
  {
    value: 13,
    label: "Mahika Ayu",
  },
];

const statusBayar = [
  {
    value: 1,
    label: "Lunas",
  },
  {
    value: 2,
    label: "Downpayment",
  },
];

const EditReservasi = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();

  const initialData = useMemo(() => {
    return JSON.parse(new URLSearchParams(search).get("payload"));
  }, [search]);

  const [loading, setLoading] = useState(false);
  const [dataPaket, setDataPaket] = useState([]);
  const [dataPengeluaran, setDataPengeluaran] = useState([]);
  const [dataReservasi, setDataReservasi] = useState(initialData);

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    watch,
    getValues,
    setValue,
  } = useForm();

  const watchStatusBayar = watch("statusBayar");
  const { value: selectedStatusBayar = null } = watchStatusBayar ?? {
    value: null,
  };

  const fetchPengeluaranOptions = async () => {
    setLoading(true);
    request
      .get("/api/pengeluaran-reservasi?per_page=100")
      .then((res) => {
        setDataPengeluaran(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const fetchDataPaketPenginapan = async () => {
    setLoading(true);
    request
      .get("/api/paket-penginapan?per_page=100")
      .then((res) => {
        const paketPenginapan = res?.data?.data;
        setDataPaket(paketPenginapan);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const fetchDataReservasi = async () => {
    setLoading(true);
    request
      .get(`/api/reservasi/${id}`)
      .then((res) => {
        const reservasi = res?.data?.data;
        setDataReservasi(reservasi);
        if (reservasi.status_pembayaran === 2) {
          setValue("jumlahDp", reservasi.jumlah_dp);
        }
        setValue("paket", {
          value: reservasi.paket_penginapan_id,
          label: `${reservasi.paket_penginapan.nama}: ${toRupiah(
            reservasi.paket_penginapan.harga
          )}`,
        });
        setValue("venue", {
          value: reservasi.venue.id,
          label: reservasi.venue.nama,
        });
        setValue("statusBayar", {
          value: reservasi.status_pembayaran,
          label: reservasi.status_pembayaran === 1 ? "Lunas" : "DP",
        });
        setValue(
          "pengeluaran",
          reservasi.pengeluaran_reservasi.map((item) => ({
            value: item.id,
            label: `${item.nama} - ${toRupiah(item.harga)}`,
          }))
        );

        setValue(
          "tanggal",
          `${reservasi?.tanggal_mulai} 00:02 - ${reservasi?.tanggal_selesai} 00:03`
        );
        Object.keys(reservasi).forEach((item) => {
          if (
            (item === "id") |
            (item === "tanggal_mulai") |
            (item === "tanggal_selesai") |
            (item === "jumlah_dp")
          )
            return;
          if (
            typeof reservasi[item] === "string" ||
            typeof reservasi[item] === "number"
          ) {
            setValue(item, reservasi[item]);
            return;
          }
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const onSubmitReservation = (values) => {
    setLoading(true);
    const payload = {
      nama: values?.nama,
      tanggal_mulai: values?.tanggal?.split(" - ")[0],
      tanggal_selesai: values?.tanggal?.split(" - ")[1]
        ? values?.tanggal?.split(" - ")[1]
        : values?.tanggal?.split(" - ")[0],
      alamat: values?.alamat,
      no_hp: values?.no_hp,
      paket_penginapan_id: values?.paket?.value,
      venue_id: values?.venue?.value,
      status_pembayaran: values?.statusBayar?.value,
      jumlah_dp:
        values?.statusBayar?.value === 1
          ? 0
          : String(values?.jumlahDp)
              ?.replace("Rp ", "")
              ?.split(",")
              ?.join("") ?? "0",
      keterangan: values?.keterangan,
      pengeluaran: values?.pengeluaran?.map((item) => ({
        pengeluaran_reservasi_id: item.value,
      })),
    };
    request
      .patch(`/api/reservasi/${id}`, payload)
      .then((res) => {
        toast.success("Berhasil mengubah reservasi");
        navigate("/reservasi");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchDataReservasi();
    fetchDataPaketPenginapan();
    fetchPengeluaranOptions();
  }, []);

  useEffect(() => {
    if (initialData) {
      Object.keys(initialData).forEach((item) => {
        if (!!initialData[item]) {
          if (item === "tanggal_mulai" || item === "tanggal_selesai") return;
          setValue(item, initialData[item]);
        }
      });
    }
  }, [initialData, setValue]);

  return (
    <CreateContainer
      title="Ubah Reservasi"
      loading={loading}
      loadingText="Menambahkan reservasi..."
      onSubmit={handleSubmit(onSubmitReservation)}
    >
      <FormInput
        required={true}
        label="Tanggal"
        name="tanggal"
        inputType="date"
        errors={errors}
        control={control}
        register={register}
        placeholder="Pilih waktu"
        errorMessage="Waktu tidak boleh kosong!"
        isClearable={true}
        options={{
          mode: "range",
          minDate: new Date(dataReservasi?.tanggal_mulai + " 00:00"),
        }}
      />

      <FormInput
        required
        label="Nama"
        name="nama"
        type="text"
        errors={errors}
        register={register}
        placeholder="Masukkan Nama"
        errorMessage="Nama tidak boleh kosong !"
      />

      <FormInput
        required
        label="Alamat"
        name="alamat"
        inputType="textarea"
        errors={errors}
        register={register}
        placeholder="Masukkan Alamat Domisili"
        errorMessage="Alamat domisili tidak boleh kosong !"
      />

      <FormInput
        required
        label="Nomor Handphone"
        name="no_hp"
        inputType="phone"
        errors={errors}
        control={control}
        register={register}
        placeholder="Masukkan Nomor Handphone"
        errorMessage="Nomor Handphone tidak boleh kosong !"
        maxLength={16}
      />

      <FormInput
        name="venue"
        inputType="select"
        label="Venue"
        errors={errors}
        control={control}
        register={register}
        options={dataVenue}
        placeholder="Pilih venue"
        errorMessage="Venue tidak boleh kosong!"
      />

      <FormInput
        name="paket"
        inputType="select"
        label="Paket"
        errors={errors}
        control={control}
        register={register}
        options={dataPaket.map((paket) => ({
          value: paket.id,
          label: `${paket.nama} - ${toRupiah(paket.harga)}`,
        }))}
        placeholder="Pilih paket"
        errorMessage="Paket tidak boleh kosong!"
      />

      <FormInput
        name="statusBayar"
        inputType="select"
        label="Status Bayar"
        errors={errors}
        control={control}
        register={register}
        options={statusBayar}
        placeholder="Pilih status bayar"
        errorMessage="Status bayar tidak boleh kosong!"
      />

      {selectedStatusBayar === 2 && (
        <FormInput
          required
          errors={errors}
          name="jumlahDp"
          label="Jumlah downpayment"
          control={control}
          inputType="price"
          placeholder="Masukkan jumlah downpayment"
          errorMessage="Jumlah downpayment tidak boleh kosong!"
        />
      )}

      <FormInput
        name="pengeluaran"
        inputType="select"
        label="Keluar"
        isMulti={true}
        errors={errors}
        control={control}
        register={register}
        options={dataPengeluaran.map((keluar) => ({
          value: keluar.id,
          label: `${keluar.nama} - ${toRupiah(keluar.harga)}`,
        }))}
        required={false}
        placeholder="Pilih pengeluaran"
        labelLink={{
          title: "Tambah pengeluaran",
          onClick: () => navigate("/reservasi/biaya-pengeluaran/create"),
        }}
        errorMessage="Pengeluaran harus diisi"
        labelRight={
          <>
            <button
              onClick={() => {
                navigate(
                  `/reservasi/biaya-pengeluaran/create?redirect=/reservasi/tambah&payload=${JSON.stringify(
                    getValues()
                  )}`
                );
              }}
              type="button"
              style={{
                textDecoration: "underline",
                color: "#019443",
                backgroundColor: "transparent",
              }}
            >
              Tambah pengeluaran
            </button>
          </>
        }
      />

      <FormInput
        required
        height="150px"
        label="Keterangan"
        name="keterangan"
        inputType="textarea"
        errors={errors}
        register={register}
        placeholder="Masukkan keterangan"
        errorMessage="Keterangan tidak boleh kosong !"
      />
    </CreateContainer>
  );
};

export default EditReservasi;
