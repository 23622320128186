import CreateContainer from "containers/create-container";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import FormInput from "components/form-input";
import { JENIS_PENGINAPAN } from "utils/constant";
import { request } from "utils/request";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";

const EditPaketPenginapan = () => {
    const navigate = useNavigate()
    const [loading,setLoading] = useState(false)
    const [fasilitasOptions,setFasilitasOptions] = useState([])
    const { id } = useParams()
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        watch
    } = useForm()
    const watchData = watch('jenis')

    const onSubmitData = async (values) => {
        setLoading(true)
        const payload = {
            nama: values['nama'],
            fasilitas: values['fasilitas'].map((item) => item.value),
            jenis: values['jenis'].value,
            harga: String(values['harga']).replace('Rp ','').replaceAll(',',''),
            jumlah_orang: values['jumlah_orang'],
        }
        request.patch(`/api/paket-penginapan/${id}`, payload)
            .then((res) => {
                toast.success(`${res.data.message}`, { duration: 3000 });
                navigate("/data-master/paket-penginapan");
            })
            .catch(err => {
                toast.danger('Gagal mengubah paket penginapan')
            })
            .finally(() => setLoading(false));
    }

    const fetchPaketPenginapan = () => {
        request.get(`/api/paket-penginapan/${id}`)
            .then((res) => {
                const { data } = res.data
                setValue('nama', data.nama)
                setValue('jenis', {
                    value: data.jenis,
                    label: data.jenis_label
                })
                setValue('harga', data.harga)
                setValue('jumlah_orang', data.jumlah_orang)
                setValue('fasilitas',  data.fasilitas.map((item) => {
                    return {
                        value: item.id,
                        label: item.nama
                    }
                }))
            })
    }

    const fetchFasilitasOptions = () => {
        request.get("/api/fasilitas")
            .then((res) => {
                const { data } = res.data
                setFasilitasOptions(data.map((item) => ({
                    value: item.id,
                    label: item.nama
                })))
            })
    }

    useEffect(() => {
        fetchFasilitasOptions()
        fetchPaketPenginapan()
    },[])

    return (
        <>
        {loading ? (
            <Spinner animation="border" className="d-block mx-auto" />
          ) : (
        <CreateContainer
            loading={loading}
            title="Ubah Paket Penginapan"
            onSubmit={handleSubmit(onSubmitData)}
            loadingText="menambahkan paket penginapan..."
        >
            <FormInput
                required
                label="Nama Paket"
                name="nama"
                type="text"
                errors={errors}
                register={register}
                placeholder="masukkan nama paket"
                errorMessage="Nama paket tidak boleh kosong!"
            />

            <FormInput 
                required
                label="Jenis paket"
                name="jenis"
                options={JENIS_PENGINAPAN}
                register={register}
                inputType="select"
                errors={errors}
                control={control}
            />

            <FormInput
                required
                label="Jumlah orang"
                name="jumlah_orang"
                inputType="rekening"
                errors={errors}
                register={register}
                control={control}
                min={1}
                placeholder="Masukkan jumlah orang"
                errorMessage="Jumlah orang tidak boleh kosong !"
            />

            <FormInput
                required
                errors={errors}
                name="harga"
                label="Harga"
                control={control}
                inputType="price"
                placeholder="Masukkan harga"
                errorMessage="Harga tidak boleh kosong!"
            />

            <FormInput
                required
                label="Fasilitas"
                name="fasilitas"
                options={fasilitasOptions}
                register={register}
                inputType="select"
                errors={errors}
                control={control}
                isMulti
            />
        </CreateContainer>
          )}
        </>
    )
}

export default EditPaketPenginapan;