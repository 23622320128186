import { useMemo, useState } from "react";
import DynamicTable from "components/dynamic-table";
import PageHeaderBtns from "components/page-header-btns";
import { toRupiah } from "utils/helper";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ModalDelete from "components/modal-delete";
import { request } from "utils/request";
import { useDispatch } from "react-redux";
import { setTriggerTable } from "redux/actions";
import toast from "react-hot-toast";
import CheckPermission from "components/CheckPermission";
import useHasPermission from "hooks/useHasPermission";

const ListPaketPenginapan = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tablePick, setTablePick] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const onDeleteData = () => {
    setLoading(true);
    request
      .delete(`/api/paket-penginapan/${tablePick?.id}`)
      .then((res) => {
        toast.success(`${res.data.message}`, {
          duration: 5000,
        });
        dispatch(setTriggerTable(true));
        setDeleteModalVisible(false);
      })
      .finally(() => setLoading(false));
  };

  const tableColumns = [
    {
      Header: "NO",
      accessor: "nomor",
      width: "20px",
    },
    {
      Header: "NAMA PAKET",
      accessor: "nama",
    },
    {
      Header: "JENIS",
      accessor: "jenis_label",
    },
    {
      Header: "JUMLAH ORANG",
      accessor: "jumlah_orang",
    },
    {
      Header: "HARGA",
      accessor: "harga",
      Cell: ({ row }) => toRupiah(row.original.harga),
    },
    {
      Header: "FASILITAS",
      accessor: "fasilitas",
      Cell: ({ row }) => {
        const fasilitas = row.original.fasilitas.map((item) => item.nama);
        return fasilitas.map((item) => {
          return (
            <span className="badge bg-primary" style={{ marginLeft: "4px" }}>
              {item}
            </span>
          );
        });
      },
    },
    {
      Header: "AKSI",
      accessor: "aksi",
      Cell: ({ row }) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CheckPermission permission="edit-paket-penginapan">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip">Edit</Tooltip>}
              >
                <button
                  type="button"
                  className="btn btn-sm btn-square btn-neutral text-warning-hover ms-2"
                  onClick={() => {
                    navigate(
                      `/data-master/paket-penginapan/${row.original.id}/edit`
                    );
                  }}
                >
                  <i className="bi bi-pencil"></i>
                </button>
              </OverlayTrigger>
            </CheckPermission>
            <CheckPermission permission="delete-paket-penginapan">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip">Hapus</Tooltip>}
              >
                <button
                  type="button"
                  className="btn btn-sm btn-square btn-neutral text-warning-hover ms-2"
                  onClick={() => {
                    setDeleteModalVisible(true);
                    setTablePick(row.original);
                  }}
                >
                  <i className="bi bi-trash"></i>
                </button>
              </OverlayTrigger>
            </CheckPermission>
          </div>
        );
      },
    },
  ];

  const mayCreatePaketPenginapan = useHasPermission("create-paket-penginapan");
  const mayAccessFasilitas = useHasPermission("access-fasilitas");

  const btns = useMemo(() => {
    return [
      {
        name: "Fasilitas",
        path: "/data-master/fasilitas",
        url: "",
        icon: "bi bi-geo",
        newTab: false,
        visible: mayAccessFasilitas,
      },
      {
        name: "Tambah",
        path: "/data-master/paket-penginapan/tambah",
        url: "",
        icon: "bi bi-plus-square-dotted",
        newTab: false,
        visible: mayCreatePaketPenginapan,
      },
    ];
  }, [mayCreatePaketPenginapan, mayAccessFasilitas]);

  return (
    <>
      <PageHeaderBtns title="Daftar Paket Penginapan" btns={btns} />
      <DynamicTable
        columns={tableColumns}
        url="/api/paket-penginapan"
        title="paket..."
      />

      <ModalDelete
        loading={loading}
        show={deleteModalVisible}
        onClick={onDeleteData}
        titleAction="Hapus"
        loadingText="Menghapus paket penginapan..."
        title={`Apakah anda yakin ingin menghapus paket penginapan ${tablePick?.nama}?`}
        onHide={() => setDeleteModalVisible(false)}
      />
    </>
  );
};

export default ListPaketPenginapan;
