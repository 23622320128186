const { FormGroup, FormLabel, Form } = require("react-bootstrap")

const FormRadio = ({label, name, required, register, options, errors, errorMessage}) => {
    return (
        <>
            <FormGroup className="mb-5"> 
                <FormLabel htmlFor={name} className="text-md d-flex text-dark">
                    {label}
                </FormLabel>

                <div className="d-flex gap-5">
                {
                    options?.map((option) => (
                        <Form.Check
                            name={name}
                            id={`${name}-${option.value}`}
                            {...register(name, { required: required })}
                        >
                            <Form.Check.Input 
                                type="radio" name={name} 
                                value={option.value} 
                                {...register(name, { required: required })}
                            />
                            <Form.Check.Label
                                style={{cursor: "pointer", fontSize: "12pt", fontWeight: 400}}
                            >
                                {option.label}
                            </Form.Check.Label>
                        </Form.Check>
                    ))
                }
                </div>
                {errors?.[name] && (
                    <div style={{ color: 'red', fontSize: '13px' }}>
                        {errorMessage}
                    </div>
                )}
            </FormGroup>
        </>
    )

}

export default FormRadio;