import { useSelector } from "react-redux";
import { useMemo } from "react";

const useHasPermission = (permission) => {
    const { user } = useSelector((state) => {
        return state?.auth
    });

    const permissions = useMemo(() => {
        return user?.permissions ?? []
      },[user])

    return permissions.includes(String(permission).toLowerCase())
}

export default useHasPermission