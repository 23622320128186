import { toast } from "react-hot-toast";
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { getAuthToken, getIsUserKeepLogin, setToken } from "./auth";
import { STATUS_CODE } from "./constant";

export const request = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 100000,
});

request.interceptors.request.use((config) => {
  if (!config.headers) {
    config.headers = {};
  }

  const token = getAuthToken();

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["Accept-Language"] = "application/json";
  }

  return config;
});

request.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const errorResponse = err.response.data;
    const errorStatusCode = err.response.status;

    const isInternalError = [
      STATUS_CODE[400],
      STATUS_CODE[422],
      STATUS_CODE[409],
    ];

    if (isInternalError.includes(errorStatusCode)) {
      toast.error(errorResponse?.message?.errors || errorResponse?.message, {
        duration: 9000,
      });
    }

    if (errorStatusCode === STATUS_CODE[500]) {
      toast.error("Terjadi kesalahan dari internal, silakan coba lagi nanti !");
    }

    return Promise.reject(err);
  }
);

const refreshAuthLogic = (failedRequest) =>
  getIsUserKeepLogin()
    ? request.post("/api/refresh").then((tokenResponse) => {
        const newToken = tokenResponse.data.data.token;
        setToken(newToken);
        failedRequest.response.config.headers[
          "Authorization"
        ] = `Bearer ${newToken}`;

        return Promise.resolve();
      })
    : setToken(null);

createAuthRefreshInterceptor(request, refreshAuthLogic);
