import FormInput from "components/form-input";
import CreateContainer from "containers/create-container";
import { useRequest } from "hooks/useRequest";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { request } from "utils/request";
import { format } from "date-fns";


const UpdateKatalog = () => {
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const { venueId, id } = useParams();

    const {
        control,
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    const {
        response: responseDetailKatalog,
        loading: loadingDetailKaryawan,
        errors: errorsDetailKaryawan,
    } = useRequest(`/api/venue/${id}/katalog/${id}`, {}, id, true);

    const onSubmitData = (values) => {
        const isPayload = {
            ...values,
            _method: "PUT",
            venue_id: venueId,
            harga: values.price,
            maksimal_peserta: values.capacity,
            tanggal: format(new Date(values.date), "yyyy-MM-dd"),
        };
    
        const formData = new FormData();

        Object.entries(isPayload).forEach(([key, value]) =>
            formData.append(key, value)
        );

        setLoading(true);
        request
            .post(`/api/venue/${venueId}/katalog/${id}`,formData)
            .then((res) => {
                toast.success(`${res.data.message}`, { duration: 3000 });
                navigate(`/data-master/katalog-venue/${venueId}`);
            })
            .finally(() => { setLoading(false) })
    }

    if(responseDetailKatalog) {
        const katalog = responseDetailKatalog?.data?.data;

        setValue("nama", katalog?.nama);
        setValue("keterangan", katalog?.keterangan);
        setValue("price", katalog?.harga);
        setValue("capacity", katalog?.maksimal_peserta);
        setValue("date", katalog?.tanggal);
    }

    return (
        <>
            <CreateContainer
                loading={loading}
                title="Ubah Katalog"
                onSubmit={handleSubmit(onSubmitData)}
                loadingText="Mengubah katalog..."
            >
                <FormInput
                    required
                    label="Nama Event"
                    name="nama"
                    type="text"
                    errors={errors}
                    register={register}
                    placeholder="Masukkan Nama"
                    errorMessage="Nama tidak boleh kosong !"
                />

            <FormInput
                required
                name="date"
                errors={errors}
                control={control}
                register={register}
                label="Tanggal Acara"
                placeholder="Pilih Tanggal acara"
                inputType="date"
                errorMessage="Waktu acara harus dipilih"
            />

            <FormInput
                required
                label="Jumlah Penonton"
                name="capacity"
                type="number"
                errors={errors}
                register={register}
                placeholder="Masukkan kapasitas maksimal"
                errorMessage="kapasitas tidak boleh kosong !"
            />

                <FormInput
                    required
                    label="Harga"
                    name="price"
                    inputType="price"
                    errors={errors}
                    register={register}
                    control={control}
                    placeholder="Masukkan harga"
                    errorMessage="harga tidak boleh kosong !"
                />

                <FormInput
                    required
                    label="Keterangan"
                    name="keterangan"
                    inputType="textarea"
                    height="150px"
                    errors={errors}
                    register={register}
                    placeholder="Masukkan keterangan"
                    errorMessage="Keterangan tidak boleh kosong !"
                />
            </CreateContainer>
        </>
    );
}

export default UpdateKatalog;