import { useEffect } from "react";
import { Controller } from "react-hook-form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import CustomEditorBuildFull from "ckeditor5-build-full";
import PropTypes from "prop-types";

const TextEditor = ({
  name,
  control,
  defaultValue,
  required,
  setValue,
  ...rest
}) => {
  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue);
    }
  }, [defaultValue]);

  return (
    <Controller
      defaultValue=""
      name={name}
      control={control}
      rules={{ required: required }}
      render={({ field: { onChange } }) => {
        return (
          <CKEditor
            data={defaultValue}
            editor={CustomEditorBuildFull}
            onChange={(event, editor) => {
              const data = editor.getData();
              onChange(data);
            }}
            {...rest}
          />
        );
      }}
    />
  );
};

TextEditor.defaultProps = {
  control: () => {},
  defaultValue: "",
  required: false,
  setValue: () => {},
};

TextEditor.propTypes = {
  name: PropTypes.string,
  control: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  setValue: PropTypes.func,
};

export default TextEditor;
