const InfoIcon = ({ color = 'black' }) => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 24.375C9.98316 24.375 7.08989 23.1766 4.95666 21.0433C2.82343 18.9101 1.625 16.0168 1.625 13C1.625 9.98316 2.82343 7.08989 4.95666 4.95666C7.08989 2.82343 9.98316 1.625 13 1.625C16.0168 1.625 18.9101 2.82343 21.0433 4.95666C23.1766 7.08989 24.375 9.98316 24.375 13C24.375 16.0168 23.1766 18.9101 21.0433 21.0433C18.9101 23.1766 16.0168 24.375 13 24.375ZM13 26C16.4478 26 19.7544 24.6304 22.1924 22.1924C24.6304 19.7544 26 16.4478 26 13C26 9.55219 24.6304 6.24558 22.1924 3.80761C19.7544 1.36964 16.4478 0 13 0C9.55219 0 6.24558 1.36964 3.80761 3.80761C1.36964 6.24558 0 9.55219 0 13C0 16.4478 1.36964 19.7544 3.80761 22.1924C6.24558 24.6304 9.55219 26 13 26Z" fill={color}/>
            <path d="M13.3792 8.36956C13.7226 8.36956 14.002 8.09019 14.002 7.74679C14.002 7.4034 13.7226 7.12402 13.3792 7.12402C13.0358 7.12402 12.7564 7.4034 12.7564 7.74679C12.7564 8.09019 13.0358 8.36956 13.3792 8.36956Z" fill={color}/>
            <path d="M12.9999 6.5C12.312 6.5 11.7543 7.05765 11.7543 7.74554C11.7543 8.43342 12.312 8.99107 12.9999 8.99107C13.6878 8.99107 14.2454 8.43342 14.2454 7.74554C14.2454 7.05765 13.6878 6.5 12.9999 6.5Z" fill={color}/>
            <path d="M13 11V19.4997" stroke={color} stroke-width="2" stroke-linecap="round"/>
        </svg>
    )
}

export default InfoIcon;