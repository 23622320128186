import { useEffect, useState } from 'react'
import CreateContainer from "containers/create-container";
import FormInput from "components/form-input";
import { useForm } from "react-hook-form";
import ModalPresensi from 'components/presensi/modal-konfirmasi';
import { useLocation, useNavigate } from "react-router-dom";
import { PRESENSI_STATUS } from 'utils/constant';
import { useWatch } from 'react-hook-form';
import toast from 'react-hot-toast';
import { format } from 'date-fns';
import { request } from 'utils/request';

const AturPresensi = () => {
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [data, setData] = useState(null);
    const navigate = useNavigate()
    const { state = null } = useLocation()

    const {
        control,
        register,
        handleSubmit,
        formState: {
            errors
        },
        setValue,
      } = useForm();

    const watchStart = useWatch({
        control,
        name: 'start',
    })

    const handleSubmitPresensi = async (values) => {
        try {
            setLoading(true)
            const { status, start, end } = values
            const payload = {
                jam_masuk: `${start}:00`,
                jam_keluar: `${end}:00`,
                status: status?.value,
                tanggal: format(new Date(), 'yyyy-MM-dd')
            }
            const response = await request.post('/api/presensi', payload)
                .then((res) => res)
            if (response) {
                toast.success(`Berhasil menambahkan`, { duration: 3000 });
                setData(values)
                setModalVisible(true)
            }
        } catch (err) {
            toast.error('Gagal memasukkan presensi', { duration: 3000 })
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    const validateFields = async (values) => {
        const { status, start, end } = values
        const validDuration =
            Number(start.split(':').join('')) < Number(end.split(':').join(''))
        if (!validDuration) {
            return toast.error('Waktu mulai harus lebih awal dari waktu selesai')
        }
        if (status && start && end) {
            await handleSubmitPresensi(values)
        }
    }

    const onSave = () => {
        handleSubmit(validateFields)()
    }

    useEffect(() => {
        if (state) {
            const { status, start, end } = state.data
            setValue('status', status)
            setValue('start', start)
            setValue('end', end)
        }
    },[state])

    return (
        <>
            <CreateContainer
                loading={loading || modalVisible}
                title="Atur Presensi Karyawan"
                onSubmit={onSave}
                loadingText="Mengatur presensi..."
                autoSubmit={false}
            >
                <FormInput
                    required
                    label="Status"
                    name="status"
                    inputType="select"
                    isMulti={false}
                    errors={errors}
                    register={register}
                    control={control}
                    placeholder="Reguler, Lembur"
                    errorMessage="Status tidak boleh kosong!"
                    options={PRESENSI_STATUS}
                />

                <FormInput
                    required
                    label="Batas Jam Presensi Masuk"
                    name="start"
                    type="time"
                    errors={errors}
                    register={register}
                    control={control}
                    placeholder="09:00"
                    errorMessage="Waktu tidak boleh kosong!"
                />

                <FormInput
                    required
                    label="Mulai Presensi Pulang"
                    name="end"
                    type="time"
                    errors={errors}
                    register={register}
                    control={control}
                    placeholder="17:00"
                    errorMessage="Waktu tidak boleh kosong!"
                    minTime={`${watchStart}:00`}
                />  

            </CreateContainer>
            <ModalPresensi
                show={modalVisible}
                title="Modal Konfirmasi"
                onHide={() => setModalVisible(false)}
                onConfirm={() => {
                    navigate('/presensi/new-presence', {
                        state: {
                            data
                        }
                    })
                }}
                data={data}
            />
        </>
    )
}

export default AturPresensi;