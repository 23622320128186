import { useMemo, useState } from "react";
import DynamicTable from "components/dynamic-table";
import PageHeader from "components/page-header";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ModalDelete from "components/modal-delete";
import { request } from "utils/request";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setTriggerTable } from "redux/actions";
import PageHeaderBtns from "components/page-header-btns";
import useHasPermission from "hooks/useHasPermission";
import CheckPermission from "components/CheckPermission";

const DaftarLokasiToilet = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [tablePick, setTablePick] = useState(null);

  const tableColumns = [
    {
      Header: "NO",
      accessor: "nomor",
      width: "20px",
    },
    {
      Header: "LOKASI TOILET",
      accessor: "nama",
    },
    {
      Header: "AKSI",
      accessor: "aksi",
      Cell: ({ row }) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CheckPermission permission="edit-lokasi-toilet">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip">Edit</Tooltip>}
              >
                <button
                  type="button"
                  className="btn btn-sm btn-square btn-neutral text-warning-hover ms-2"
                  onClick={() => {
                    navigate(
                      `/hasil-usaha/toilet/lokasi/edit/${row.original.id}`
                    );
                  }}
                >
                  <i className="bi bi-pencil"></i>
                </button>
              </OverlayTrigger>
            </CheckPermission>
            <CheckPermission permission="delete-usaha-toilet">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip">Hapus</Tooltip>}
              >
                <button
                  type="button"
                  className="btn btn-sm btn-square btn-neutral text-warning-hover ms-2"
                  onClick={() => {
                    setDeleteModalVisible(true);
                    setTablePick(row.original);
                  }}
                >
                  <i className="bi bi-trash"></i>
                </button>
              </OverlayTrigger>
            </CheckPermission>
          </div>
        );
      },
    },
  ];

  const onDeleteData = () => {
    setLoading(true);
    request
      .delete(`/api/lokasi-toilet/${tablePick?.id}`)
      .then((res) => {
        toast.success(`${res.data.message}`, {
          duration: 5000,
        });
        dispatch(setTriggerTable(true));
        setDeleteModalVisible(false);
      })
      .finally(() => setLoading(false));
  };

  const mayCreateLokasiToilet = useHasPermission("create-lokasi-toilet");
  const btns = useMemo(() => {
    return [
      {
        name: "Kembali",
        path: "/hasil-usaha/toilet/laporan-pendapatan",
        url: "",
        icon: "bi bi-chevron-left",
        newTab: false,
        type: "btn-danger",
      },
      {
        name: "Tambah",
        path: "/hasil-usaha/toilet/lokasi/tambah",
        url: "",
        icon: "bi bi-plus-square-dotted",
        newTab: false,
        visible: mayCreateLokasiToilet,
      },
    ];
  }, [mayCreateLokasiToilet]);

  return (
    <>
      <PageHeaderBtns
        title="Daftar Lokasi Toilet"
        to="/hasil-usaha/toilet/lokasi/tambah"
        btns={btns}
      />
      <DynamicTable
        columns={tableColumns}
        url="/api/lokasi-toilet"
        title="lokasi toilet"
      />
      <ModalDelete
        loading={loading}
        show={deleteModalVisible}
        onClick={onDeleteData}
        titleAction="Hapus"
        loadingText="Menghapus lokasi toilet..."
        title={`Apakah anda yakin ingin menghapus lokasi toilet ${tablePick?.nama}?`}
        onHide={() => setDeleteModalVisible(false)}
      />
    </>
  );
};

export default DaftarLokasiToilet;
