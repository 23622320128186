import { Button, Modal } from "react-bootstrap";

const ModalDelete = ({
  onClick,
  show,
  onHide,
  loading,
  title,
  titleAction,
  loadingText,
  bodyText,
}) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header className="bg-danger">
        <Modal.Title className="text-white">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{bodyText}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="neutral" onClick={onHide}>
          Batal
        </Button>
        <Button variant="danger" disabled={loading} onClick={onClick}>
          {loading ? loadingText : titleAction}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDelete;
