import CheckPermission from "components/CheckPermission";
import DynamicTable from "components/dynamic-table";
import PageHeader from "components/page-header";
import useHasPermission from "hooks/useHasPermission";
import ListIcon from "icons/list-icon";
import { useNavigate } from "react-router-dom";

const SettingGaji = () => {
  const navigate = useNavigate();
  const GAJI_TYPES = {
    gajiPokok: {
      label: "Gaji Pokok",
      url: "/penggajian/gaji-pokok",
    },
    lebur: {
      label: "Lembur",
      url: "/penggajian/lembur",
    },
    tunjangan: {
      label: "Tunjangan Jabatan",
      url: "/penggajian/tunjangan",
    },
    bonusTahunan: {
      label: "Bonus Tahunan",
      url: "/penggajian/bonus-tahunan",
    },
  };

  const tableColumns = [
    {
      Header: "NO",
      accessor: "nomor",
      width: "20px",
    },
    {
      Header: "Nama",
      accessor: "nama",
      Cell: ({ row }) => <td>{row.original?.label}</td>,
    },
    {
      Header: "Aksi",
      accessor: "aksi",
      width: "10px",
      Cell: ({ row }) => {
        return (
          <CheckPermission permission="create-penggajian">
            <button
              type="button"
              onClick={() => {
                navigate(row.original?.url);
              }}
              style={{
                backgroundColor: "#FFFFFF",
                padding: "12px",
                borderRadius: "8px",
                border: "1px solid #D9D9D9",
                boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.1)",
              }}
            >
              <ListIcon />
            </button>
          </CheckPermission>
        );
      },
    },
  ];

  return (
    <>
      <PageHeader title="Setting Gaji" />
      <DynamicTable
        customData={Object.values(GAJI_TYPES).map((item, idx) => ({
          nomor: idx + 1,
          ...item,
        }))}
        columns={tableColumns}
        withSearch={true}
      />
    </>
  );
};

export default SettingGaji;
