import { useState } from "react";
import { format } from "date-fns";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { request } from "utils/request";
import FormInput from "components/form-input";
import CreateContainer from "containers/create-container";

const CreateKatalog = () => {
    const navigate = useNavigate();
    const { venueId } = useParams();
    
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [loading, setLoading] = useState(false);

    // handle post data
    const onSubmitData = (values) => {
        const isPayload = {
            ...values,
            venue_id: venueId,
            harga: String(values.price).replace('Rp ','').split(',').join(''),
            maksimal_peserta: values.capacity,
            tanggal: format(new Date(values.date), "yyyy-MM-dd"),
            keterangan: String(values.keterangan).replace(/<\/?[^>]+(>|$)/g, '')
        };

        setLoading(true);

        request
        .post(`/api/venue/${venueId}/katalog`, isPayload)
        .then((res) => {
            toast.success(`${res.data.message}`, { duration: 3000 });
            navigate(`/data-master/katalog-venue/${venueId}`);
        })
        .finally(() => setLoading(false));
    };

    return (
        <CreateContainer
            loading={loading}
            title="Tambah Katalog"
            onSubmit={handleSubmit(onSubmitData)}
            loadingText="menambahkan katalog..."
            >
            <FormInput
                required
                label="Nama Event"
                name="nama"
                type="text"
                errors={errors}
                register={register}
                placeholder="Masukkan Nama"
                errorMessage="Nama tidak boleh kosong !"
            />

            <FormInput
                required
                name="date"
                errors={errors}
                control={control}
                register={register}
                label="Tanggal Acara"
                placeholder="Pilih Tanggal acara"
                inputType="date"
                errorMessage="Waktu acara harus dipilih"
            />

            <FormInput
                required
                label="Jumlah Penonton"
                name="capacity"
                type="number"
                control={control}
                errors={errors}
                register={register}
                placeholder="Masukkan kapasitas maksimal"
                errorMessage="kapasitas tidak boleh kosong !"
            />

            <FormInput
                required
                label="Harga"
                name="price"
                inputType="price"
                control={control}
                errors={errors}
                register={register}
                placeholder="Masukkan harga"
                errorMessage="harga tidak boleh kosong !"
            />

            {/* <FormInput
                required
                label="Keterangan"
                name="keterangan"
                inputType="textarea"
                height="150px"
                control={control}
                errors={errors}
                register={register}
                placeholder="Masukkan keterangan"
                errorMessage="Keterangan tidak boleh kosong !"
            /> */}

            <FormInput
                required
                label="Keterangan"
                name="keterangan"
                inputType="quill"
                height="150px"
                control={control}
                errors={errors}
                register={register}
                placeholder="Masukkan keterangan"
                errorMessage="Keterangan tidak boleh kosong!"
            />

            
        </CreateContainer>
    );
};

export default CreateKatalog;
