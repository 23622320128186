import { useState } from "react";
import { Button } from "react-bootstrap";
import PageHeader from "components/page-header";
import DynamicTable from "components/dynamic-table";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { request } from "utils/request";
import { toast } from "react-hot-toast";
import { setTriggerTable } from "redux/actions";
import ModalDelete from "components/modal-delete";
import CheckPermission from "components/CheckPermission";

const ListFasilitas = () => {
  const [fasilitasPick, setFasilitasPick] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tableColumns = [
    {
      Header: "NO",
      accessor: "nomor",
      width: "20px",
    },
    {
      Header: "NAMA FASILITAS",
      accessor: "nama",
    },
    {
      Header: "AKSI",
      accessor: "aksi",
      Cell: ({ row }) => {
        return (
          <td
            className="text-end gap-3"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <CheckPermission permission="edit-fasilitas">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip">Edit</Tooltip>}
              >
                <button
                  type="button"
                  className="btn btn-sm btn-square btn-neutral text-warning-hover ms-2"
                  onClick={() => {
                    navigate(`/data-master/fasilitas/${row.original?.id}/edit`);
                  }}
                >
                  <i className="bi bi-pencil"></i>
                </button>
              </OverlayTrigger>
            </CheckPermission>

            <CheckPermission permission="delete-fasilitas">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip">Hapus</Tooltip>}
              >
                <button
                  type="button"
                  className="btn btn-sm btn-square btn-neutral text-danger-hover ms-2"
                  onClick={() => {
                    setDeleteModalVisible(true);
                    setFasilitasPick(row.original);
                  }}
                >
                  <i className="bi bi-trash"></i>
                </button>
              </OverlayTrigger>
            </CheckPermission>
          </td>
        );
      },
    },
  ];

  const onDeleteData = () => {
    setLoading(true);
    request
      .delete(`/api/fasilitas/${fasilitasPick?.id}`)
      .then((res) => {
        toast.success(`${res.data.message}`, {
          duration: 5000,
        });
        dispatch(setTriggerTable(true));
        setDeleteModalVisible(false);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <PageHeader
        title="Daftar Fasilitas"
        to="/data-master/fasilitas/tambah"
        additionalButton={{
          title: "Kembali",
          url: "",
          icon: "bi bi-arrow-left",
          newTab: false,
          type: "btn-danger",
          onClick: () => navigate("/data-master/paket-penginapan"),
        }}
      />

      <DynamicTable
        columns={tableColumns}
        url="/api/fasilitas"
        title="fasilitas"
      />

      <ModalDelete
        loading={loading}
        show={deleteModalVisible}
        onHide={() => setDeleteModalVisible(false)}
        onClick={onDeleteData}
        titleAction="Hapus Fasilitas"
        title={`Apakah anda yakin ingin menghapus ${fasilitasPick?.nama}?`}
        loadingText="menghapus fasilitas..."
      />
    </>
  );
};

export default ListFasilitas;
