import PageHeader from "components/page-header";
import { useState } from "react";
import { useForm } from "react-hook-form";
import FormInput from "components/form-input";
import { useNavigate } from "react-router-dom";
import { request } from "utils/request";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import FormRadio from "components/form-radio";
import { GOLONGAN_KARYAWAN } from "utils/constant";
import { Spinner } from "react-bootstrap";

const tabs = [
  {
    key: 1,
    label: "Ubah Profil",
  },
  {
    key: 2,
    label: "Ubah Kata Sandi",
  },
];

const UpdateProfileForm = () => {
  const [loading, setLoading] = useState(false);
  const [agama, setAgama] = useState({});
  const [pendidikan, setPendidikan] = useState({});
  const [bank, setBank] = useState({});
  const [userData, setUserData] = useState(null);
  const [newFoto, setNewFoto] = useState(null);

  const navigate = useNavigate();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const fetchData = async () => {
    setLoading(true);
    request
      .post("/api/user/me")
      .then((res) => {
        const karyawan = res?.data?.data?.detail;
        setUserData(karyawan);
      })
      .finally(() => setLoading(false));
  };
  const getPendidikan = async () => {
    const response = await request.get("api/pendidikan-terakhir");
    return response;
  };

  const getAgama = async () => {
    const response = await request.get("api/agama");
    return response;
  };

  const getBank = async () => {
    const response = await request.get("api/bank");
    return response;
  };

  useEffect(() => {
    getAgama().then((res) => {
      setAgama(
        res.data.data.map((agama) => ({ value: agama.id, label: agama.nama }))
      );
    });

    getPendidikan().then((res) => {
      setPendidikan(
        res.data.data.map((pendidikan) => ({
          value: pendidikan.id,
          label: String(pendidikan.nama).split(" / ").join("/"),
        }))
      );
    });

    getBank().then((res) => {
      setBank(
        res.data.data.map((bank) => ({ value: bank.id, label: bank.nama }))
      );
    });
  }, [setAgama, setPendidikan, setBank]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (userData) {
      const karyawan = userData;
      setValue("nama", karyawan?.nama);
      setValue("nik", karyawan?.nik);
      setValue("jenis_kelamin", karyawan?.jenis_kelamin);
      setValue("no_hp", karyawan?.no_hp);
      setValue("tempat_lahir", karyawan?.tempat_lahir);
      setValue("tanggal_lahir", karyawan?.tanggal_lahir);
      setValue("agama_id", {
        label: karyawan?.agama,
        value: karyawan?.agama_id,
      });
      setValue("pendidikan_terakhir_id", {
        label: String(karyawan?.pendidikan_terakhir).split(" / ").join("/"),
        value: karyawan?.pendidikan_terakhir_id,
      });
      setValue("bank_id", {
        label: karyawan?.bank,
        value: karyawan?.bank_id,
      });
      setValue("no_rekening", karyawan?.no_rekening);
      setValue("alamat_domisili", karyawan?.alamat_domisili);
      setValue("alamat_ktp", karyawan?.alamat_ktp);
      setValue("status_karyawan_id", {
        label: karyawan?.status_karyawan,
        value: karyawan?.status_karyawan_id,
      });
      setValue(
        "golongan",
        GOLONGAN_KARYAWAN.find(
          (golongan) => golongan.value === karyawan?.golongan
        )
      );
    }
  }, [userData]);

  const blobToFile = (theBlob, fileName) => {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  };

  const blobUrltoFile = async (blobUrl, fileName) => {
    return await fetch(blobUrl)
      .then((r) => r.blob())
      .then((blobFile) => {
        return blobToFile(blobFile, fileName);
      });
  };

  const handleChangePassword = async (values) => {
    const payload = {
      ...values,
      _method: "PUT",
      ...(newFoto && {
        foto: await blobUrltoFile(newFoto, "foto.jpg"),
      }),
      agama_id: values?.agama_id?.value,
      pendidikan_terakhir_id: values?.pendidikan_terakhir_id?.value,
      status_karyawan_id: values?.status_karyawan_id?.value,
      bank_id: values?.bank_id?.value,
      golongan: values?.golongan?.value,
    };

    const formData = new FormData();

    Object.entries(payload).forEach(([key, value]) =>
      formData.append(key, value)
    );

    setLoading(true);
    request
      .post(`/api/user/update`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        toast.success(`${res.data.message}`, { duration: 3000 });
        navigate("/profile");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading && !userData)
    return (
      <div style={{ marginTop: "20px" }}>
        <Spinner className="d-block mx-auto" animation="border" />
      </div>
    );

  return (
    <form autoComplete="off" onSubmit={handleSubmit(handleChangePassword)}>
      <div style={{ width: "960px", margin: "auto", marginTop: "28px" }}>
        <div
          style={{
            padding: "32px",
            borderRadius: "16px",
            backgroundColor: "white",
            border: "1px solid #DEDFE1",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "64px",
                height: "64px",
                borderRadius: "50%",
                overflow: "clip",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                alt="profile"
                src={newFoto ? newFoto : userData?.foto}
                style={{
                  backgroundSize: "cover",
                }}
              />
            </div>
            <div
              style={{
                marginLeft: "20px",
              }}
            >
              <div
                style={{
                  fontSize: "22px",
                  color: "#16192C",
                  fontWeight: "500",
                }}
              >
                {userData?.nama}
              </div>
              <div
                style={{
                  fontSize: "18px",
                  color: "#6B7B93",
                  fontWeight: "400",
                }}
              >
                {userData?.email}
              </div>
            </div>
          </div>
          <div>
            <input
              type="file"
              id="imageFile"
              accept="image/*"
              hidden
              onChange={(e) => {
                setNewFoto(URL.createObjectURL(e.target.files[0]));
              }}
            />

            <label
              for="imageFile"
              style={{
                padding: "8px 20px",
                backgroundColor: "white",
                borderRadius: "8px",
                border: "1px solid #DEDFE1",
                cursor: "pointer",
              }}
            >
              Ubah Avatar
            </label>
          </div>
        </div>
        <div
          style={{
            marginBottom: "37px",
            marginTop: "45px",
            fontSize: "24px",
            fontWeight: "500",
            color: "#16192C",
          }}
        >
          Informasi Kontak
        </div>
        <FormInput
          required
          label="Nama"
          name="nama"
          type="text"
          errors={errors}
          register={register}
          placeholder="Masukkan Nama"
          errorMessage="Nama tidak boleh kosong !"
        />

        <FormInput
          required
          label="NIK"
          name="nik"
          inputType="nik"
          errors={errors}
          register={register}
          placeholder="Masukkan NIK"
          errorMessage="NIK tidak boleh kosong !"
          control={control}
        />

        <FormRadio
          required
          label="Jenis Kelamin"
          name="jenis_kelamin"
          register={register}
          options={[
            { label: "Laki-laki", value: "L" },
            { label: "Perempuan", value: "P" },
          ]}
          errors={errors}
          errorMessage="Jenis kelamin tidak boleh kosong"
        />

        <FormInput
          required
          label="Nomor Handphone"
          name="no_hp"
          inputType="phone"
          errors={errors}
          control={control}
          register={register}
          placeholder="Masukkan Nomor Handphone"
          errorMessage="Nomor Handphone tidak boleh kosong !"
          maxLength={16}
        />

        <FormInput
          required
          label="Tempat Lahir"
          name="tempat_lahir"
          type="text"
          errors={errors}
          register={register}
          placeholder="Masukkan Tempat Lahir"
          errorMessage="Tempat lahir tidak boleh kosong !"
        />

        <FormInput
          required
          label="Tanggal Lahir"
          name="tanggal_lahir"
          type="date"
          errors={errors}
          register={register}
          placeholder="Masukkan Tempat Lahir"
          errorMessage="Tanggal lahir tidak boleh kosong !"
        />

        <FormInput
          required
          label="Agama"
          name="agama_id"
          options={agama}
          register={register}
          inputType="select"
          errors={errors}
          control={control}
          errorMessage="Agama tidak boleh kosong!"
        />

        <FormInput
          required
          label="Pendidikan Terakhir"
          name="pendidikan_terakhir_id"
          options={pendidikan}
          register={register}
          inputType="select"
          errors={errors}
          control={control}
          errorMessage="Pendidikan tidak boleh kosong!"
        />

        <FormInput
          required
          label="Bank"
          name="bank_id"
          options={bank}
          register={register}
          inputType="select"
          errors={errors}
          control={control}
          errorMessage="Bank tidak boleh kosong!"
        />

        <FormInput
          required
          label="Nomor Rekening"
          name="no_rekening"
          inputType="rekening"
          errors={errors}
          register={register}
          control={control}
          min={1}
          placeholder="Masukkan Nomor Rekening"
          errorMessage="Nomor rekening tidak boleh kosong !"
        />

        <FormInput
          required
          label="Alamat Domisili"
          name="alamat_domisili"
          inputType="textarea"
          errors={errors}
          register={register}
          placeholder="Masukkan Alamat Domisili"
          errorMessage="Alamat domisili tidak boleh kosong !"
        />

        <FormInput
          skipCapitalize
          required
          label="Alamat KTP"
          name="alamat_ktp"
          inputType="textarea"
          errors={errors}
          register={register}
          placeholder="Masukkan Alamat KTP"
          errorMessage="Alamat KTP tidak boleh kosong !"
        />
        <div style={{ marginTop: "65px" }}>
          <div className="col-12 text-end">
            <button
              type="button"
              onClick={() => {
                navigate(-1);
              }}
              className="btn btn-sm btn-neutral me-2"
              style={{ width: "100%", maxWidth: "120px", height: "40px" }}
            >
              Batal
            </button>
            <button
              type="submit"
              disabled={loading}
              className="btn btn-sm btn-primary"
              style={{
                width: "100%",
                height: "40px",
                maxWidth: loading ? "300px" : "120px",
              }}
            >
              {loading ? "Mengubah profil" : "Simpan"}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

const UpdatePasswordForm = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const handleChangePassword = (values) => {
    setLoading(true);
    request
      .post("/api/user/update-password", {
        password: values.oldPassword,
        new_password: values.newPassword,
        new_password_confirmation: values.newPasswordConfirmation,
      })
      .then((res) => {
        toast.success("Password berhasil diganti");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(handleChangePassword)}>
      <div style={{ width: "960px", margin: "auto", marginTop: "28px" }}>
        <div style={{ margin: "auto" }}>
          <FormInput
            required
            type="password"
            name="oldPassword"
            control={control}
            errors={errors}
            label="Password Lama"
            register={register}
            placeholder="Masukkan password lama"
            errorMessage="Masukkan password lama"
            inputStyles={{
              width: "960px",
            }}
          />
        </div>
        <div style={{ margin: "auto" }}>
          <FormInput
            required
            type="password"
            name="newPassword"
            errors={errors}
            control={control}
            label="Password Baru"
            register={register}
            registerProps={{
              validate: (value) => {
                if (value === watch("newPassword")) {
                  return true;
                }
                return "Password tidak sama";
              },
            }}
            placeholder="Masukkan password baru"
            errorMessage="Masukkan password baru"
            inputStyles={{
              width: "960px",
            }}
          />
        </div>
        <div style={{ margin: "auto" }}>
          <FormInput
            required
            type="password"
            name="newPasswordConfirmation"
            errors={errors}
            label="Konfirmasi Password Baru"
            register={register}
            registerProps={{
              validate: (value) => {
                if (value === watch("newPassword")) {
                  return true;
                }
                return "Password tidak sama";
              },
            }}
            placeholder="Masukkan konfirmasi password baru"
            errorMessage="Masukkan konfirmasi password baru"
            control={control}
            inputStyles={{
              width: "960px",
            }}
          />
        </div>
        <div style={{ marginTop: "65px" }}>
          <div className="col-12 text-end">
            <button
              type="button"
              onClick={() => {
                navigate(-1);
              }}
              className="btn btn-sm btn-neutral me-2"
              style={{ width: "100%", maxWidth: "120px", height: "40px" }}
            >
              Batal
            </button>
            <button
              type="submit"
              disabled={loading}
              className="btn btn-sm btn-primary"
              style={{
                width: "100%",
                height: "40px",
                maxWidth: loading ? "300px" : "120px",
              }}
            >
              {loading ? "Mengubah password" : "Simpan"}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

const EditProfil = () => {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <>
      <PageHeader title="Profil" withBorder={false} />
      <div
        style={{
          borderBottom: "1px solid #C4C4C4",
        }}
      >
        {tabs.map((item) => {
          return (
            <button
              key={item.key}
              style={{
                backgroundColor: "transparent",
                marginRight: "48px",
                padding: "4px 0px 24px 0px",
                color: activeTab === item.key ? "#019444" : "#16192C",
                borderBottom:
                  activeTab === item.key ? "1px solid #019444" : "none",
              }}
              onClick={() => setActiveTab(item.key)}
            >
              {item.label}
            </button>
          );
        })}
      </div>
      <div>{activeTab === 2 && <UpdatePasswordForm />}</div>
      <div>{activeTab === 1 && <UpdateProfileForm />}</div>
    </>
  );
};

export default EditProfil;
