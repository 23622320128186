import CreateContainer from "containers/create-container";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { request } from "utils/request";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FormInput from "components/form-input";

const EditFasilitas = () => {
    const navigate = useNavigate()
    const params = useParams()
    const { id } = params

    const [loading,setLoading] = useState(false)
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm()

    const onSubmitData = (values) => {
        setLoading(true)
        request
            .patch(`/api/fasilitas/${id}`, values)
            .then((res) => {
                toast.success(`${res.data.message}`, { duration: 3000 });
                navigate("/data-master/fasilitas");
            })
            .finally(() => setLoading(false));
    }

    const fetchData = () => {
        request
            .get(`/api/fasilitas/${id}`)
            .then((res) => {
                console.log(res)
                const { data } = res.data
                const { nama } = data
                setValue("nama", nama)
            })
    }

    useEffect(() => {
        fetchData()
    },[])

    return (
        <CreateContainer
            title="Ubah Fasilitas"
            loading={loading}
            loadingText="Mengubah fasilitas..."
            onSubmit={handleSubmit(onSubmitData)}
        >
            <FormInput
                required
                label="Nama fasilitas"
                name="nama"
                type="text"
                errors={errors}
                register={register}
                placeholder="Masukkan nama fasilitas"
                errorMessage="Nama fasilitas tidak boleh kosong!"
            />
        </CreateContainer>
    )
}

export default EditFasilitas;