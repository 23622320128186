import { useEffect, useState } from "react";
import CreateContainer from "containers/create-container";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { request } from "utils/request";
import FormInput from "components/form-input";
import { format } from "date-fns";
import { toast } from "react-hot-toast";

const EditLaporanPendapatanToilet = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [loading,setLoading] = useState(false)
    const [lokasiToilet,setLokasiToilet] = useState([])
    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
    } = useForm()

    const fetchDataPenghasilan = async () => {
        setLoading(true)
        request.get(`/api/usaha-toilet/${id}`)
        .then((res) => {
            setValue('tanggal', res?.data?.data?.tanggal)
            const lokasiToilet = res?.data?.data?.pendapatan
            setLokasiToilet(lokasiToilet)
        })
        .catch((err) => {
            console.log(err)
            toast.error('Gagal mengambil data')
        })
        .finally(() => setLoading(false))
    }

    const onSubmit = (values) => {
        setLoading(true)
        request.post(
            '/api/usaha-toilet',
            {
                tanggal: values?.tanggal,
                pendapatan: Object.keys(values)
                    .filter((key) => key !== 'tanggal')
                    .reduce((acc,cur) => {
                        const pendapatan = {
                            lokasi_toilet_id: cur,
                            pendapatan: String(values[cur])?.includes('Rp') ? values[cur]?.replace('Rp ','')?.split(',')?.join('') : values[cur]
                        }
                        return [...acc,pendapatan]
                    },[])
            }
        )
        .then((res) => {
            toast.success(res.data.message)
            navigate('/hasil-usaha/toilet/laporan-pendapatan')
        })
        .catch(err => {
            toast.danger('Gagal menambahkan pendapatan')
            console.log(err)
        })
        .finally(() => setLoading(false))
    }

    useEffect(() => {
        fetchDataPenghasilan()
    },[])

    useEffect(() => {
        lokasiToilet?.map((item) => {
            setValue(`${item.lokasi_toilet_id}`,item.pendapatan)
        })
    },[lokasiToilet])

    return (
        <>
            <CreateContainer
                loading={loading}
                title="Ubah Laporan Pendapatan Toilet per Hari"
                onSubmit={handleSubmit(onSubmit)}
                loadingText="Menambahkan laporan..."
            >
                <FormInput
                    label="Tanggal"
                    name="tanggal"
                    inputType="date"
                    defaultValue={format(new Date(), 'yyyy-MM-dd')}
                    errors={errors}
                    control={control}
                    placeholder="Pilih waktu"
                    errorMessage="Waktu tidak boleh kosong!"
                />
                <div
                    style={{
                        marginTop: '30px',
                        marginBottom: '11px'
                    }}
                    className="text-md d-flex text-dark form-label"
                >
                    Area Toilet
                </div>
                <div
                    style={{
                        padding: '40px',
                        backgroundColor: '#FFFFFF',
                        borderRadius: '8px',
                        border: '1px solid #DEDFE1',
                    }}
                >
                    {lokasiToilet.map((toilet) => {
                        return (
                            <div
                                key={toilet.lokasi_toilet_id}
                            >
                                <FormInput
                                    required
                                    errors={errors}
                                    name={String(toilet.lokasi_toilet_id)}
                                    label={toilet.lokasi.nama}
                                    control={control}
                                    inputType="price"
                                    placeholder="Masukkan jumlah pendapatan"
                                    errorMessage="Jumlah pendapatan tidak boleh kosong!"
                                />
                            </div>
                        )
                    })}
                </div>
            </CreateContainer>
        </>
    )
}

export default EditLaporanPendapatanToilet;