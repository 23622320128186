import PageHeader from "components/page-header";
import { useForm } from "react-hook-form";
import FormInput from "components/form-input";
import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { request } from "utils/request";
import toast from "react-hot-toast";
import { toTitleCase } from "utils/helper";
import { PENGGAJIAN_TIPE } from "utils/constant";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const SettingLembur = () => {
    const [positions,setPositions] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const {
        control,
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm()

    const fetchPositions = async () => {
        setLoading(true)
        request.get('/api/jabatan?per_page=100')
            .then((res) => {
                setPositions(res?.data?.data)
            })
            .catch((err) => {
                toast.error('Gagal mengambil data bidang.')
            })
            .finally(() => setLoading(false))
    }

    const handleSaveData = () => {
        const levels = [1,2,3]
        const payload =
            Object.keys(control._formValues)
                .filter((item) => !!control._formValues[item])
                .map((item) => {
                    const jabatan_id = item
                    const gaji = String(control._formValues[item]).replace('Rp ','').split(',').join('')
                    return levels.map((level) => ({
                        tipe: PENGGAJIAN_TIPE.lembur,
                        jabatan_id,
                        golongan: level,
                        total_gaji: gaji
                    }))
                })
                .reduce((acc,curr) => [...acc,...curr],[])
            setLoading(true)
            request.post('/api/penggajian',{
                penggajian: payload
            })
                .then((res) => {
                    toast.success('Berhasil menyimpan data gaji lembur.')
                })
                .catch(err => {
                    toast.error('Gagal menyimpan data.')
                })
                .finally(() => setLoading(false))
    }

    const fetchPenggajianData = async () => {
        request.get('/api/penggajian')
            .then((res) => {
                const data = res?.data?.data?.filter((item) => item.tipe === PENGGAJIAN_TIPE.lembur)
                    .reduce((acc,cur) => {
                        if (acc[cur?.jabatan_id]) return acc
                        return {
                            ...acc,
                            [cur?.jabatan_id]: cur?.total_gaji
                        }
                    },{})
                    Object.keys(data).forEach((item) => {
                        setValue(item,data[item])
                    })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        fetchPositions()
        fetchPenggajianData()
    },[])


    return (
        <>
            <PageHeader
                title="Setting Lembur"
            />
            {positions.map((item, index) => {
                return (
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '150px 1fr',
                            marginBottom: '30px',
                            gap: '60px'
                        }}
                    >
                        <div
                            style={{
                                fontWeight: '500',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {toTitleCase(item.nama)}
                        </div>
                        <div>
                            <FormInput
                                required
                                errors={errors}
                                name={String(item.id)}
                                control={control}
                                inputType="price"
                                placeholder="Lembur"
                                errorMessage="Lembur tidak boleh kosong!"
                            />
                        </div>
                    </div>
                )
            })}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: '20px'
                }}
            >
                <Button variant="danger" onClick={() => navigate('/penggajian/setting')}>
                    Kembali
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSaveData}
                >
                    {loading ? "Menambahkan setting gaji" : 'Simpan'}
                </Button>
            </div>
        </>
    )
}

export default SettingLembur;