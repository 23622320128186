import { useMemo, useState } from "react";
import DynamicTable from "components/dynamic-table";
import PageHeaderBtns from "components/page-header-btns";
import { format } from "date-fns";
import { toRupiah, toTitleCase } from "utils/helper";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ModalDelete from "components/modal-delete";
import { request } from "utils/request";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setTriggerTable } from "redux/actions";
import { id } from "date-fns/locale";
import useHasPermission from "hooks/useHasPermission";
import CheckPermission from "components/CheckPermission";

const DaftarReservasi = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [tablePick, setTablePick] = useState(null);

  const columns = [
    {
      Header: "No",
      accessor: "nomor",
    },
    {
      Header: "Tanggal",
      accessor: "tanggal",
      Cell: ({ row }) => {
        return `${format(
          new Date(row?.original?.tanggal_mulai),
          "dd MMMM yyyy",
          { locale: id }
        )} - ${format(
          new Date(row?.original?.tanggal_selesai),
          "dd MMMM yyyy",
          { locale: id }
        )}`;
      },
    },
    {
      Header: "Nama",
      accessor: "nama",
    },
    {
      Header: "Alamat",
      accessor: "alamat",
    },
    {
      Header: "Nomor HP",
      accessor: "no_hp",
    },
    {
      Header: "Paket",
      accessor: "paket_penginapan.nama",
    },
    {
      Header: "Venue",
      accessor: "venue.nama",
      Cell: ({ row }) => toTitleCase(row?.original?.venue?.nama),
    },
    {
      Header: "Status Bayar",
      accessor: "status_pembayaran",
      Cell: ({ row }) => {
        if (row?.original?.status_pembayaran === 2) return "DP";
        if (row?.original?.status_pembayaran === 1) return "Lunas";
      },
    },
    {
      Header: "Jumlah Downpayment",
      accessor: "jumlah_dp",
      Cell: ({ row }) => {
        return toRupiah(row?.original?.jumlah_dp);
      },
    },
    {
      Header: "Keluar",
      accessor: "keluar",
      Cell: ({ row }) => {
        return (
          <div style={{ display: "flex", gap: "8px" }}>
            {row?.original?.pengeluaran_reservasi?.map((item) => {
              return (
                <div
                  style={{
                    backgroundColor: "#019443",
                    color: "white",
                    padding: "2px 6px",
                    borderRadius: 4,
                  }}
                >
                  {`${item.nama}: ${toRupiah(item.harga)}`}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      Header: "Aksi",
      accessor: "aksi",
      Cell: ({ row }) => {
        return (
          <>
            <CheckPermission permission="edit-reservasi">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip">Edit</Tooltip>}
              >
                <button
                  type="button"
                  className="btn btn-sm btn-square btn-neutral text-warning-hover ms-2"
                  onClick={() => {
                    navigate(`/reservasi/${row.original.id}/edit`);
                  }}
                >
                  <i className="bi bi-pencil"></i>
                </button>
              </OverlayTrigger>
            </CheckPermission>
            <CheckPermission permission="delete-reservasi">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip">Hapus</Tooltip>}
              >
                <button
                  type="button"
                  className="btn btn-sm btn-square btn-neutral text-warning-hover ms-2"
                  onClick={() => {
                    setDeleteModalVisible(true);
                    setTablePick(row.original);
                  }}
                >
                  <i className="bi bi-trash"></i>
                </button>
              </OverlayTrigger>
            </CheckPermission>
          </>
        );
      },
    },
  ];

  const mayCreateReservasi = useHasPermission("create-reservasi");

  const btns = useMemo(() => {
    return [
      {
        name: "Biaya Pengeluaran",
        path: "/reservasi/biaya-pengeluaran",
        newTab: false,
      },
      {
        name: "Tambah",
        path: "/reservasi/tambah",
        icon: "bi bi-plus-square-dotted",
        newTab: false,
        visible: mayCreateReservasi,
      },
    ];
  }, [mayCreateReservasi]);

  const onDeleteData = () => {
    setLoading(true);
    request
      .delete(`/api/reservasi/${tablePick?.id}`)
      .then((res) => {
        toast.success(`${res.data.message}`, {
          duration: 5000,
        });
        dispatch(setTriggerTable(true));
        setDeleteModalVisible(false);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <PageHeaderBtns title="Daftar Reservasi" btns={btns} />
      <DynamicTable title="nama" columns={columns} url="/api/reservasi" />
      <ModalDelete
        loading={loading}
        show={deleteModalVisible}
        onClick={onDeleteData}
        titleAction="Hapus"
        loadingText="Menghapus reservasi..."
        title={`Apakah anda yakin ingin menghapus reservasi ${tablePick?.nama}?`}
        onHide={() => setDeleteModalVisible(false)}
      />
    </>
  );
};

export default DaftarReservasi;
