import PageHeader from "components/page-header";
import { format } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { request } from "utils/request";
import localeId from "date-fns/locale/id";
import { toRupiah } from "utils/helper";
import PageHeaderBtns from "components/page-header-btns";

const PresentasiTiketing = () => {
    const { id } = useParams()
    const [loading,setLoading] = useState(false)
    const [periodeData,setPeriodeData] = useState(null)

    const title = useMemo(() => {
        if (periodeData) {
            const [tanggalMulai,bulanMulai,tahunMulai] = String(periodeData.tanggal_mulai).split('-')
            const [tanggalSelesai,bulanSelesai,tahunSelesai] = String(periodeData.tanggal_selesai).split('-')
            const start = format(new Date(`${tahunMulai}-${bulanMulai}-${tanggalMulai}`), "dd MMMM yyyy", { locale: localeId })
            const end = format(new Date(`${tahunSelesai}-${bulanSelesai}-${tanggalSelesai}`), "dd MMMM yyyy", { locale: localeId })
            return `Pembagian Hasil Usaha Tiketing`
        }
    },[periodeData])

    const fetchData = async () => {
        setLoading(true)
        request.get(`/api/periode-usaha-tiketing/${id}`)
            .then((res) => {
                setPeriodeData(res?.data?.data)
            })
            .catch((err) => {
                toast.error('Gagal mengambil data')
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        fetchData()
    },[])

    if (periodeData) return (
        <>
            <PageHeaderBtns
                title={title}
                btns={[
                    {name: "Kembali", path: "/hasil-usaha/tiketing/rekapitulasi", url: "", icon: "bi bi-chevron-left", newTab: false, type: 'btn-danger'},
                ]}
            />
            <div
                style={{
                    backgroundColor: "#FFFFFF",
                    paddingTop: '77px',
                    paddingBottom: "74px",
                    borderRadius: "16px",
                    border: "1px solid #D9D9D9"
                }}
            >
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "80px repeat(3,1fr)",
                        borderTop: '1px solid #D9D9D9',
                        borderBottom: '3px solid #D9D9D9',
                        padding: "18px 26px",
                        backgroundColor: "#F2F2F2"
                    }}
                >
                    <div>
                        No
                    </div>
                    <div>
                        Uraian
                    </div>
                    <div>
                        Persentase
                    </div>
                    <div>
                        Total
                    </div>
                </div>
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "80px repeat(3,1fr)",
                        borderTop: '1px solid #D9D9D9',
                        borderBottom: '1px solid #D9D9D9',
                        padding: "18px 26px",
                    }}
                    className="hoverable-row"
                >
                    <div>
                        1
                    </div>
                    <div>
                        Bumdes
                    </div>
                    <div>
                        {periodeData.pembagian_hasil_usaha_tiketing.persentase_bumdes * 100}%
                    </div>
                    <div>
                        {toRupiah(periodeData.pembagian_hasil_usaha_tiketing.pembagian_bumdes)}
                    </div>
                </div>
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "80px repeat(3,1fr)",
                        borderTop: '1px solid #D9D9D9',
                        borderBottom: '1px solid #D9D9D9',
                        padding: "18px 26px"
                    }}
                >
                    <div>
                        2
                    </div>
                    <div>
                        Kas
                    </div>
                    <div>
                        {periodeData.pembagian_hasil_usaha_tiketing.persentase_kas * 100}%
                    </div>
                    <div>
                        {toRupiah(periodeData.pembagian_hasil_usaha_tiketing.pembagian_kas)}
                    </div>
                </div>
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "80px repeat(3,1fr)",
                        borderTop: '1px solid #D9D9D9',
                        borderBottom: '1px solid #D9D9D9',
                        padding: "18px 26px"
                    }}
                    className="hoverable-row"
                >
                    <div>
                        3
                    </div>
                    <div>
                        Sosial
                    </div>
                    <div>
                        {periodeData.pembagian_hasil_usaha_tiketing.persentase_sosial * 100}%
                    </div>
                    <div>
                        {toRupiah(periodeData.pembagian_hasil_usaha_tiketing.pembagian_sosial)}
                    </div>
                </div>
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "80px repeat(3,1fr)",
                        borderTop: '1px solid #D9D9D9',
                        borderBottom: '1px solid #D9D9D9',
                        padding: "18px 26px"
                    }}
                    className="hoverable-row"
                >
                    <div>
                        4
                    </div>
                    <div>
                        Gaji
                    </div>
                    <div>
                        {periodeData.pembagian_hasil_usaha_tiketing.persentase_gaji * 100}%
                    </div>
                    <div>
                        {toRupiah(periodeData.pembagian_hasil_usaha_tiketing.pembagian_gaji)}
                    </div>
                </div>
                <div
                    style={{
                        display: "grid",
                        gridTemplateColumns: "80px repeat(3,1fr)",
                        borderTop: '1px solid #D9D9D9',
                        borderBottom: '1px solid #D9D9D9',
                        padding: "18px 26px"
                    }}
                    className="hoverable-row"
                >
                    <div>
                        5
                    </div>
                    <div>
                        Konsumsi
                    </div>
                    <div>
                        {periodeData.pembagian_hasil_usaha_tiketing.persentase_konsumsi * 100}%
                    </div>
                    <div>
                        {toRupiah(periodeData.pembagian_hasil_usaha_tiketing.pembagian_konsumsi)}
                    </div>
                </div>
            </div>
        </>
    )
}

export default PresentasiTiketing;