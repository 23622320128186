import CreateContainer from "containers/create-container";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import FormInput from "components/form-input";
import { JENIS_PENGINAPAN } from "utils/constant";
import { request } from "utils/request";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const CreatePaketPenginapan = () => {
    const navigate = useNavigate()
    const [loading,setLoading] = useState(false)
    const [fasilitasOptions,setFasilitasOptions] = useState([])
    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm()

    const onSubmitData = async (values) => {
        setLoading(true)
        const payload = {
            nama: values['nama'],
            fasilitas: values['fasilitas'].map((item) => item.value),
            jenis: values['jenis'].value,
            harga: String(values['harga']).replace('Rp ','').replaceAll(',',''),
            jumlah_orang: values['jumlah_orang'],
        }
        request.post("/api/paket-penginapan", payload)
            .then((res) => {
                toast.success(`${res.data.message}`, { duration: 3000 });
                navigate("/data-master/paket-penginapan");
            })
            .catch(err => {
                toast.danger('Gagal menambahkan paket penginapan')
            })
            .finally(() => setLoading(false));
    }

    const fetchFasilitasOptions = () => {
        request.get("/api/fasilitas")
            .then((res) => {
                const { data } = res.data
                setFasilitasOptions(data.map((item) => ({
                    value: item.id,
                    label: item.nama
                })))
            })
    }
    console.log(errors)

    useEffect(() => {
        fetchFasilitasOptions()
    },[])

    return (
        <CreateContainer
            loading={loading}
            title="Tambah Paket Penginapan"
            onSubmit={handleSubmit(onSubmitData)}
            loadingText="menambahkan paket penginapan..."
        >
            <FormInput
                required
                label="Nama Paket"
                name="nama"
                type="text"
                errors={errors}
                register={register}
                placeholder="masukkan nama paket"
                errorMessage="Nama paket tidak boleh kosong!"
            />

            <FormInput 
                required
                label="Jenis paket"
                name="jenis"
                options={JENIS_PENGINAPAN}
                register={register}
                inputType="select"
                placeholder="masukkan jenis paket"
                errorMessage="masukkan jenis paket"
                errors={errors}
                control={control}
            />

            <FormInput
                required
                label="Jumlah orang"
                name="jumlah_orang"
                inputType="rekening"
                errors={errors}
                register={register}
                control={control}
                min={1}
                placeholder="Masukkan jumlah orang"
                errorMessage="Jumlah orang tidak boleh kosong !"
            />

            <FormInput
                required
                errors={errors}
                name="harga"
                label="Harga"
                control={control}
                inputType="price"
                placeholder="Masukkan harga"
                errorMessage="Harga tidak boleh kosong!"
            />

            <FormInput
                required
                label="Fasilitas"
                name="fasilitas"
                options={fasilitasOptions}
                register={register}
                inputType="select"
                placeholder="masukkan fasilitas"
                errorMessage="fasilitas tidak boleh kosong"
                errors={errors}
                control={control}
                isMulti
            />
        </CreateContainer>
    )
}

export default CreatePaketPenginapan;