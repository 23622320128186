import { useState, useEffect } from "react";
import CreateContainer from "containers/create-container";
import { request } from "utils/request";
import toast from "react-hot-toast";
import { useForm, useWatch } from "react-hook-form";
import { PERIZINAN_STATUS } from "utils/constant";
import FormInput from "components/form-input";
import { useNavigate } from "react-router-dom";

const CreatePerizinan = () => {
  const [loading, setLoading] = useState(false);
  const [karyawanOptions, setKaryawanOptions] = useState([]);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const statusValue = useWatch({
    control,
    name: "status",
  });

  const onSubmitData = (values) => {
    setLoading(true);
    const [tanggal_mulai = null, tanggal_selesai = null] =
      values.date.split(" - ");

    const isPayload = {
      ...values,
      _method: "POST",
      karyawan_id: values.karyawan.value,
      jenis_perizinan: values.status.value,
      tanggal_mulai,
      tanggal_selesai: tanggal_selesai || tanggal_mulai,
      alasan: values.reason,
      ...(values.status.value === 1 && {
        surat_izin: values?.suratIzin?.[0]?.file,
      }),
    };

    const formData = new FormData();

    Object.entries(isPayload).forEach(([key, value]) =>
      formData.append(key, value)
    );

    // Object.entries(isPayload).forEach(([key, value]) =>
    //     formData.append(key, value)
    // );

    setLoading(true);
    request
      .post(`/api/perizinan`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        toast.success(`${res.data.message}`, { duration: 3000 });
        navigate("/izin");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRequestKaryawan = async () => {
    try {
      const responseNames = await request.get("/api/karyawan");
      return setKaryawanOptions(
        responseNames.data.data.map((item) => ({
          label: item.nama,
          value: item.id,
          nama: item.nama,
        }))
      );
    } finally {
    }
  };

  useEffect(() => {
    handleRequestKaryawan();
  }, []);

  return (
    <CreateContainer
      title="Tambah Izin"
      loading={loading}
      onSubmit={handleSubmit(onSubmitData)}
      loadingText="Menambahkan izin"
    >
      <FormInput
        required
        name="karyawan"
        inputType="select"
        label="Nama Karyawan"
        errors={errors}
        control={control}
        register={register}
        options={karyawanOptions}
        placeholder="Pilih Karyawan"
      />

      <FormInput
        required
        name="status"
        errors={errors}
        control={control}
        register={register}
        label="Jenis Izin"
        placeholder="Pilih jenis izin"
        inputType="select"
        options={PERIZINAN_STATUS}
        errorMessage="Jenis izin harus dipilih"
      />

      <FormInput
        required
        name="date"
        errors={errors}
        control={control}
        register={register}
        label="Tanggal Izin"
        placeholder="Pilih tanggal izin"
        inputType="date"
        errorMessage="Tanggal izin harus dipilih"
        options={{
          mode: "range",
          minDate: "today",
        }}
      />

      <FormInput
        required
        name="reason"
        errors={errors}
        control={control}
        register={register}
        label="Alasan Izin"
        placeholder="Masukkan alasan izin"
        inputType="textarea"
        errorMessage="Alasan izin harus diisi"
      />

      {statusValue?.value === 1 && (
        <FormInput
          required
          label="Bukti Surat Izin"
          name="suratIzin"
          errors={errors}
          control={control}
          maxFiles={1}
          allowMultiple={false}
          inputType="upload_images_grid"
        />
      )}
    </CreateContainer>
  );
};

export default CreatePerizinan;
