import CreateContainer from "containers/create-container";
import { useForm } from "react-hook-form";
import FormInput from "components/form-input";
import { useState } from "react";
import { request } from "utils/request";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const CreateRekapitulasiPenginapan = () => {
    const [loading,setLoading] = useState(false)
    const navigate = useNavigate()
    const {
        control,
        formState: { errors },
        handleSubmit,
    } = useForm()

    const onSubmit = (values) => {
        setLoading(true)
        request.post('/api/periode-usaha-penginapan', {
            tanggal_mulai: values.tanggal.split(' - ')[0],
            tanggal_selesai: values.tanggal.split(' - ')[1]
        })
        .then((res) => {
            toast.success('Berhasil menambahkan data')
            navigate('/hasil-usaha/penginapan/rekapitulasi')
        })
        .catch(err => {
            console.log(err)
            toast.error('Gagal menambahkan periode')
        })
        .finally(() => setLoading(false))
    }

    return (
        <CreateContainer
            title="Tambah Rekapitulasi Pendapatan Mingguan"
            onSubmit={handleSubmit(onSubmit)}
            loading={loading}
            loadingText="Menambahkan periode"
        >
            <FormInput
                label="Periode"
                name="tanggal"
                inputType="date"
                errors={errors}
                control={control}
                placeholder="Pilih periode"
                errorMessage="Waktu tidak boleh kosong!"
                options={{
                    mode: 'range'
                }}
                isClearable={true}
            />
        </CreateContainer>
    )
}

export default CreateRekapitulasiPenginapan;