import { useState } from "react";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, Navigate } from "react-router-dom";
import { request } from "utils/request";
import FormInput from "components/form-input";
import CreateContainer from "containers/create-container";

const CreateImageKatalogPage = () => {
    const { venueId,id } = useParams();

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const handleCreateImagesVenue = (values) => {
        setLoading(true);

        const isPayload = {
            ...values,
            id: id,
            model: 'katalog',
            collection: 'katalog',
            image: values?.image?.[0]?.file,
        };

        const formData = new FormData();

        Object.entries(isPayload).forEach(([key, value]) =>
            formData.append(key, value)
        );

        request
        .post(`/api/media/upload`, formData)
        .then((res) => {
            toast.success(res.data.message);

            navigate(`/data-master/katalog-venue/${venueId}/images/${id}`);
        })
        .finally(() => setLoading(false));
    };

    if (!id || !venueId) {
        return (
            <Navigate to={`/data-master/katalog-venue/${venueId}/images/${id}`} />
        );
    }

    return (
        <>
            <CreateContainer
                loading={loading}
                title="Tambah Gambar Katalog"
                loadingText="menambahkan gambar..."
                onSubmit={handleSubmit(handleCreateImagesVenue)}
            >
                <FormInput
                    required
                    name="nama"
                    label="Nama"
                    errors={errors}
                    register={register}
                    errorMessage="Nama tidak boleh kosong !"
                />

                <FormInput
                    required
                    name="image"
                    label="Gambar"
                    errors={errors}
                    control={control}
                    maxFiles={1}
                    allowMultiple={false}
                    inputType="upload_images_grid"
                    errorMessage="Gambar tidak boleh kosong !"
                />
            </CreateContainer>
        </>
    );
};

export default CreateImageKatalogPage;
