import { useState, useEffect, useMemo } from "react";
import DynamicTable from "components/dynamic-table";
import PageHeader from "components/page-header";
import { format } from "date-fns";
import { toRupiah } from "utils/helper";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ModalDelete from "components/modal-delete";
import toast from "react-hot-toast";
import { request } from "utils/request";
import { useDispatch } from "react-redux";
import { setTriggerTable } from "redux/actions";
import { useForm } from "react-hook-form";
import FormInput from "components/form-input";
import useHasPermission from "hooks/useHasPermission";
import CheckPermission from "components/CheckPermission";

const JurnalUmumDua = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [tablePick, setTablePick] = useState(null);
  const navigate = useNavigate();

  const {
    control,
    watch,
    formState: { errors },
  } = useForm();

  const watchTanggal = watch("date");

  const onDeleteData = () => {
    setLoading(true);
    request
      .delete(`/api/jurnal-umum-dua/${tablePick?.id}`)
      .then((res) => {
        toast.success(`${res.data.message}`, {
          duration: 5000,
        });
        dispatch(setTriggerTable(true));
        setDeleteModalVisible(false);
      })
      .finally(() => setLoading(false));
  };

  const columns = [
    {
      Header: "Nomor",
      accessor: "nomor",
      Cell: ({ row, ...rest }) => {
        const lastItem = row?.index + 1 === rest?.data?.length;
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "space-between",
              flexGrow: 1,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "92.25px",
                borderRight: "1px solid #e7eaf0",
              }}
            >
              {row?.original?.nomor}
            </div>
            {lastItem && (
              <div
                style={{
                  padding: "20px",
                  backgroundColor: "#FAFAFA",
                  color: "#FAFAFA",
                  borderTop: "1px solid #e7eaf0",
                }}
              >
                0
              </div>
            )}
          </div>
        );
      },
    },
    {
      Header: "Tanggal",
      accessor: "tanggal",
      Cell: ({ row, ...rest }) => {
        const lastItem = row?.index + 1 === rest?.data?.length;
        return (
          <>
            <div
              style={{
                paddingLeft: "30px",
                paddingRight: "30px",
                height: "92.25px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {format(new Date(row.original.tanggal), "dd/MM/yyyy")}
            </div>
            {lastItem && (
              <div
                style={{
                  padding: "20px",
                  backgroundColor: "#FAFAFA",
                  color: "#303030",
                  borderTop: "1px solid #e7eaf0",
                }}
              >
                Total
              </div>
            )}
          </>
        );
      },
    },
    {
      Header: "Ref",
      accessor: "ref",
      Cell: ({ row, ...rest }) => {
        const lastItem = row?.index + 1 === rest?.data?.length;
        const debit = row?.original?.transaksi_jurnal_umum_dua?.find(
          (item) => Number(item?.debit) !== 0
        );
        const kredit = row?.original?.transaksi_jurnal_umum_dua?.find(
          (item) => Number(item?.kredit) !== 0
        );
        return (
          <>
            <div>
              <div
                style={{
                  padding: "12px 20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderLeft: "1px solid #e7eaf0",
                  borderRight: "1px solid #e7eaf0",
                  height: "100%",
                }}
              >
                {debit?.kode_akun}
                {!debit?.kode_akun && <div style={{ color: "white" }}>0</div>}
              </div>
              <div
                style={{
                  padding: "12px 20px",
                  display: "flex",
                  borderBottom: "none",
                  alignItems: "center",
                  justifyContent: "center",
                  borderLeft: "1px solid #e7eaf0",
                  borderRight: "1px solid #e7eaf0",
                  borderTop: "1px solid #e7eaf0",
                  height: "100%",
                }}
              >
                {kredit?.kode_akun}
                {!kredit?.kode_akun && <div style={{ color: "white" }}>0</div>}
              </div>
              {lastItem && (
                <div
                  style={{
                    padding: "20px",
                    backgroundColor: "#FAFAFA",
                    color: "#FAFAFA",
                    borderTop: "1px solid #e7eaf0",
                  }}
                >
                  0
                </div>
              )}
            </div>
          </>
        );
      },
    },
    {
      Header: "Akun",
      accessor: "akun",
      Cell: ({ row, ...rest }) => {
        const lastItem = row?.index + 1 === rest?.data?.length;
        const debit = row?.original?.transaksi_jurnal_umum_dua?.find(
          (item) => Number(item?.debit) !== 0
        );
        const kredit = row?.original?.transaksi_jurnal_umum_dua?.find(
          (item) => Number(item?.kredit) !== 0
        );
        return (
          <div>
            <div
              style={{
                padding: "12px 20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                borderRight: "1px solid #e7eaf0",
                height: "100%",
              }}
            >
              {debit?.nama_akun}
              {debit?.nama_akun && <div style={{ color: "white" }}>0</div>}
            </div>
            <div
              style={{
                padding: "12px 20px",
                display: "flex",
                borderBottom: "none",
                alignItems: "center",
                justifyContent: "start",
                paddingLeft: "32px",
                borderRight: "1px solid #e7eaf0",
                borderTop: "1px solid #e7eaf0",
                height: "100%",
              }}
            >
              {kredit?.nama_akun}
              {kredit?.nama_akun && <div style={{ color: "white" }}>0</div>}
            </div>
            {lastItem && (
              <div
                style={{
                  padding: "20px",
                  backgroundColor: "#FAFAFA",
                  color: "#FAFAFA",
                  borderTop: "1px solid #e7eaf0",
                  borderRight: "1px solid #e7eaf0",
                }}
              >
                0
              </div>
            )}
          </div>
        );
      },
    },
    {
      Header: "Debit",
      accessor: "debit",
      Cell: ({ row, ...rest }) => {
        const lastItem = row?.index + 1 === rest?.data?.length;
        const debit = row?.original?.transaksi_jurnal_umum_dua?.find(
          (item) => Number(item?.debit) !== 0
        );
        const kredit = row?.original?.transaksi_jurnal_umum_dua?.find(
          (item) => Number(item?.kredit) !== 0
        );
        const totalDebit = rest?.rows
          ?.map(({ original }) => {
            return original?.transaksi_jurnal_umum_dua?.find(
              (item) => Number(item?.debit) !== 0
            )?.debit;
          })
          .reduce((acc, cur) => Number(acc) + Number(cur), 0);
        return (
          <div>
            <div
              style={{
                padding: "12px 20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                borderRight: "1px solid #e7eaf0",
                height: "100%",
              }}
            >
              {Number(debit?.debit ?? 0) === 0 ? (
                <div style={{ color: "white" }}>0</div>
              ) : (
                <div>{toRupiah(debit?.debit ?? 0)}</div>
              )}
            </div>
            <div
              style={{
                padding: "12px 20px",
                display: "flex",
                borderBottom: "none",
                alignItems: "center",
                justifyContent: "start",
                borderRight: "1px solid #e7eaf0",
                borderTop: "1px solid #e7eaf0",
                height: "100%",
              }}
            >
              {Number(kredit?.debit ?? 0) === 0 ? (
                <div style={{ color: "white" }}>0</div>
              ) : (
                <div>{toRupiah(kredit?.debit ?? 0)}</div>
              )}
            </div>
            {lastItem && (
              <div
                style={{
                  padding: "20px",
                  backgroundColor: "#FAFAFA",
                  color: "#303030",
                  borderTop: "1px solid #e7eaf0",
                  borderRight: "1px solid #e7eaf0",
                }}
              >
                {toRupiah(totalDebit)}
              </div>
            )}
          </div>
        );
      },
    },
    {
      Header: "Kredit",
      accessor: "kredit",
      Cell: ({ row, ...rest }) => {
        const lastItem = row?.index + 1 === rest?.data?.length;
        const debit = row?.original?.transaksi_jurnal_umum_dua?.find(
          (item) => Number(item?.debit) !== 0
        );
        const kredit = row?.original?.transaksi_jurnal_umum_dua?.find(
          (item) => Number(item?.kredit) !== 0
        );
        const totalKredit = rest?.rows
          ?.map(({ original }) => {
            return original?.transaksi_jurnal_umum_dua?.find(
              (item) => Number(item?.kredit) !== 0
            )?.kredit;
          })
          .reduce((acc, cur) => Number(acc) + Number(cur), 0);
        return (
          <div>
            <div
              style={{
                padding: "12px 20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                borderRight: "1px solid #e7eaf0",
                height: "100%",
              }}
            >
              {Number(debit?.kredit ?? 0) === 0 ? (
                <div style={{ color: "white" }}>0</div>
              ) : (
                <div>{toRupiah(debit?.kredit ?? 0)}</div>
              )}
            </div>
            <div
              style={{
                padding: "12px 20px",
                display: "flex",
                borderBottom: "none",
                alignItems: "center",
                justifyContent: "start",
                borderRight: "1px solid #e7eaf0",
                borderTop: "1px solid #e7eaf0",
                height: "100%",
              }}
            >
              {Number(kredit?.kredit ?? 0) === 0 ? (
                <div style={{ color: "white" }}>0</div>
              ) : (
                <div>{toRupiah(kredit?.kredit ?? 0)}</div>
              )}
            </div>
            {lastItem && (
              <div
                style={{
                  padding: "20px",
                  backgroundColor: "#FAFAFA",
                  color: "#303030",
                  borderTop: "1px solid #e7eaf0",
                  borderRight: "1px solid #e7eaf0",
                }}
              >
                {toRupiah(totalKredit)}
              </div>
            )}
          </div>
        );
      },
    },
    {
      Header: "Keterangan",
      accessor: "keterangan",
      Cell: ({ row, ...rest }) => {
        const lastItem = row?.index + 1 === rest?.data?.length;

        return (
          <div>
            <div
              style={{
                padding: "12px 20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                height: "100%",
              }}
            >
              {row?.original?.transaksi_jurnal_umum_dua?.[0]?.kode_akun !==
              111 ? (
                row?.original?.transaksi_jurnal_umum_dua?.[0]?.keterangan
              ) : (
                <div style={{ color: "white" }}>0</div>
              )}
            </div>
            <div
              style={{
                padding: "12px 20px",
                display: "flex",
                borderBottom: "none",
                alignItems: "center",
                justifyContent: "start",
                borderTop: "1px solid #e7eaf0",
                height: "100%",
              }}
            >
              {row?.original?.transaksi_jurnal_umum_dua?.[1]?.kode_akun !==
              111 ? (
                row?.original?.transaksi_jurnal_umum_dua?.[1]?.keterangan
              ) : (
                <div style={{ color: "white" }}>0</div>
              )}
            </div>
            {lastItem && (
              <div
                style={{
                  padding: "20px",
                  backgroundColor: "#FAFAFA",
                  color: "#FAFAFA",
                  borderTop: "1px solid #e7eaf0",
                }}
              >
                0
              </div>
            )}
          </div>
        );
      },
    },
    {
      Header: "Aksi",
      accessor: "aksi",
      Cell: ({ row, ...rest }) => {
        const lastItem = row?.index + 1 === rest?.data?.length;
        return (
          <>
            <div
              style={{
                padding: "12px 35px",
                display: "flex",
                alignItems: "center",
                height: "92.25px",
              }}
            >
              {row?.original?.is_auto === 0 ? (
                <>
                  <CheckPermission permission="edit-bendahara-dua">
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="button-tooltip">Edit</Tooltip>}
                    >
                      <button
                        type="button"
                        className="btn btn-sm btn-square btn-neutral text-warning-hover ms-2"
                        onClick={() => {
                          navigate(
                            `/keuangan/bendahara-2/jurnal-umum/${row.original.id}/edit`
                          );
                        }}
                      >
                        <i className="bi bi-pencil"></i>
                      </button>
                    </OverlayTrigger>
                  </CheckPermission>
                  <CheckPermission permission="delete-bendahara-dua">
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="button-tooltip">Hapus</Tooltip>}
                    >
                      <button
                        type="button"
                        className="btn btn-sm btn-square btn-neutral text-warning-hover ms-2"
                        onClick={() => {
                          setDeleteModalVisible(true);
                          setTablePick(row.original);
                        }}
                      >
                        <i className="bi bi-trash"></i>
                      </button>
                    </OverlayTrigger>
                  </CheckPermission>
                </>
              ) : (
                <></>
              )}
            </div>
            {lastItem && (
              <div
                style={{
                  padding: "20px",
                  backgroundColor: "#FAFAFA",
                  color: "#FAFAFA",
                  borderTop: "1px solid #e7eaf0",
                }}
              >
                0
              </div>
            )}
          </>
        );
      },
    },
  ];

  const monthNames = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  const generateFilterOptions = () => {
    const thisYear = new Date().getFullYear();
    const lastFiveYears = [];
    for (let i = 0; i < 5; i++) {
      lastFiveYears.push(thisYear - i);
    }
    const lastFiveYearsWithMonths = lastFiveYears.map((year) => {
      const months = [];
      for (let i = 1; i <= 12; i++) {
        months.push({
          value: i,
          label: monthNames[i - 1],
        });
      }
      return {
        value: year,
        label: year,
        months,
      };
    });

    const reducedOptions = lastFiveYearsWithMonths.reduce((acc, cur) => {
      const monthsInYear = cur.months.map((month) => {
        return {
          value: `${month.value}-${cur.value}`,
          label: `${month.label} ${cur.value}`,
        };
      });
      return [...acc, ...monthsInYear.reverse()];
    }, []);
    return reducedOptions;
  };

  const additionalQuery = useMemo(() => {
    if (!watchTanggal?.value)
      return `&bulan=${
        new Date().getMonth() + 1
      }&tahun=${new Date().getFullYear()}`;
    const [bulan, tahun] = String(watchTanggal?.value).split("-");
    return `&bulan=${bulan}&tahun=${tahun}`;
  }, [watchTanggal]);

  useEffect(() => {
    setFilterOptions(generateFilterOptions());
  }, []);

  const mayCreateBendaharaDua = useHasPermission("create-bendahara-dua");

  return (
    <>
      <PageHeader
        title="Jurnal Umum Bendahara 2"
        {...(mayCreateBendaharaDua && {
          to: "/keuangan/bendahara-2/jurnal-umum/create",
        })}
      />

      <div
        style={{
          width: "400px",
        }}
      >
        <FormInput
          name="date"
          label="Waktu"
          inputType="select"
          options={filterOptions}
          errors={errors}
          control={control}
          placeholder="Pilih waktu"
          errorMessage="Waktu tidak boleh kosong!"
          isClearable={true}
          defaultValue={{
            value: `${new Date().getMonth() + 1}-${new Date().getFullYear()}`,
            label: `${
              monthNames[new Date().getMonth()]
            } ${new Date().getFullYear()}`,
          }}
        />
      </div>

      <div className="table-akuntansi">
        <DynamicTable
          url="/api/jurnal-umum-dua"
          columns={columns}
          newQueryParams={additionalQuery}
        />
      </div>

      <ModalDelete
        loading={loading}
        show={deleteModalVisible}
        onClick={onDeleteData}
        titleAction="Hapus"
        loadingText="Menghapus paket penginapan..."
        title={`Apakah anda yakin ingin menghapus transaksi ${tablePick?.id}?`}
        onHide={() => setDeleteModalVisible(false)}
      />
    </>
  );
};

export default JurnalUmumDua;
