const HasilUsahaIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0069 16.5856C14.5767 16.5856 18.2978 12.8637 18.2978 8.2928C18.2978 3.72189 14.5767 0 10.0069 0C5.43703 0 1.71599 3.72189 1.71599 8.2928C1.71599 12.8637 5.43703 16.5856 10.0069 16.5856ZM10.0069 1.21615C13.9069 1.21615 17.0819 4.39192 17.0819 8.2928C17.0819 12.1937 13.9069 15.3694 10.0069 15.3694C6.10691 15.3694 2.93186 12.1937 2.93186 8.2928C2.93186 4.39192 6.10691 1.21615 10.0069 1.21615ZM8.07983 10.2432H6.85019C6.97408 11.4043 7.96054 12.313 9.15348 12.313H9.40124V13.1666H10.6171V12.313H10.8649C12.1404 12.313 13.1819 11.2758 13.1819 9.99541C13.1819 8.95365 12.4386 8.0771 11.4109 7.90271L8.81395 7.47132C8.37807 7.39789 8.05689 7.02157 8.05689 6.581C8.05689 5.97522 8.54783 5.47958 9.15806 5.47958H10.8649C11.3879 5.47958 11.8238 5.84672 11.9385 6.33318H13.1682C13.0443 5.1721 12.0578 4.26342 10.8649 4.26342H10.6171V3.40982H9.40124V4.26342H9.15348C7.87795 4.26342 6.83643 5.3006 6.83643 6.581C6.83643 7.62276 7.57972 8.49931 8.60748 8.6737L11.2044 9.10509C11.6403 9.17852 11.9615 9.55484 11.9615 9.99541C11.9615 10.6012 11.4705 11.0968 10.8603 11.0968H9.15348C8.63042 11.0968 8.19454 10.7297 8.07983 10.2432ZM18.2748 14.7224C17.9995 15.0757 17.7013 15.4153 17.3801 15.732C18.1647 15.7962 18.7841 16.4525 18.7841 17.2556C18.7841 18.0955 18.1005 18.7838 17.2563 18.7838H2.74375C1.90411 18.7838 1.21588 18.1 1.21588 17.2556C1.21588 16.4525 1.83528 15.7962 2.61987 15.732C2.29869 15.4153 2.00046 15.0757 1.72517 14.7224C0.697408 15.1354 0 16.1267 0 17.2556C0 18.7655 1.22964 20 2.74375 20H17.2563C18.7658 20 20 18.7701 20 17.2556C20 16.1313 19.3026 15.1354 18.2748 14.7224Z"
        fill="#6B7BA1"
      />
    </svg>
  );
};

export default HasilUsahaIcon;
