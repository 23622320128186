import DynamicTable from "components/dynamic-table";
import PageHeader from "components/page-header";
import { useState } from "react";
import { request } from "utils/request";
import ModalDelete from "components/modal-delete";
import ChangeUserRole from "components/setting/user-management/modal-change-role";
import Key from "icons/key";
import UserCog from "icons/user-cog";
import { toast } from "react-hot-toast";
import { toTitleCase } from "utils/helper";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CheckPermission from "components/CheckPermission";

const UserList = () => {
  const [modalResetPasswordVisible, setModalRestPasswordVisible] =
    useState(false);
  const [changeRoleModalVisible, setChangeRoleModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataTablePick, setDataTablePick] = useState(null);

  const handleResetPassword = () => {
    if (!dataTablePick) return toast.error("Gagal mereset password!");
    setLoading(true);
    request
      .post(`/api/user/reset-password/${dataTablePick.id}`)
      .then(() => {
        toast.success("Berhasil mereset password!");
        setModalRestPasswordVisible(false);
      })
      .catch(() => {
        toast.error("Gagal mereset password!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const userColumnsTable = [
    {
      Header: "NO",
      accessor: "nomor",
      width: "20px",
    },
    {
      Header: "Nama",
      Cell: ({ row }) => {
        return <div>{toTitleCase(String(row?.original?.nama))}</div>;
      },
    },
    {
      Header: "Role",
      accessor: "roles[0].name",
      Cell: ({ row }) => (
        <div>{toTitleCase(String(row?.original?.role[0]?.name))}</div>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Aksi",
      Cell: ({ row }) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CheckPermission permission="edit-user">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip">Ubah Role</Tooltip>}
              >
                <button
                  type="button"
                  className="btn btn-sm btn-square btn-neutral text-danger-hover ms-2"
                  onClick={() => {
                    setDataTablePick(row.original);
                    setChangeRoleModalVisible(true);
                  }}
                >
                  <UserCog />
                </button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip">Reset Password</Tooltip>}
              >
                <button
                  type="button"
                  className="btn btn-sm btn-square btn-neutral text-danger-hover ms-2"
                  onClick={() => {
                    setDataTablePick(row.original);
                    setModalRestPasswordVisible(true);
                  }}
                >
                  <Key />
                </button>
              </OverlayTrigger>
            </CheckPermission>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <PageHeader title="User Management" />

      <DynamicTable title="user" columns={userColumnsTable} url="/api/user" />

      <ModalDelete
        show={modalResetPasswordVisible}
        onHide={() => setModalRestPasswordVisible(false)}
        onClick={handleResetPassword}
        title="Reset Password"
        titleAction="Reset"
        loading={loading}
        loadingText="Mereset password..."
        bodyText={`Apakah anda yakin ingin mereset password user ${dataTablePick?.email}`}
      />
      <ChangeUserRole
        show={changeRoleModalVisible}
        onHide={() => setChangeRoleModalVisible(false)}
        data={dataTablePick}
      />
    </>
  );
};

export default UserList;
