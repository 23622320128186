import { useState, useEffect } from "react";
import FormInput from "components/form-input";
import { useForm } from "react-hook-form";
import { request } from "utils/request";
import { PERIZINAN_STATUS, PRESENSI_STATUS } from "utils/constant";
import { toTitleCase } from "utils/helper";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setTriggerTable } from "redux/actions";

const FilterPerizinan = ({
    setFilterParams
}) => {
    const  {
        search
    } = useLocation()
    const {
        control,
        register,
        formState: { errors },
        watch
      } = useForm();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [karyawanOptions,setKaryawanOptions] = useState([])
    const filterFields = watch();

    const {
        tanggal = null,
        karyawan = null,
    } = filterFields;

    const handleRequestKaryawan = async () => {
        try {
            const responseNames = await request.get("/api/karyawan");
            return setKaryawanOptions(
                responseNames.data.data.map((item) => ({
                    label: toTitleCase(item.nama),
                    value: item.id,
                    nama: item.nama,
                }))
            );
          } finally {
          }
    }

    useEffect(() => {
        const tanggal_mulai = String(tanggal).split(" - ")[0] ?? null
        const tanggal_selesai = String(tanggal).split(" - ")[1] ?? null
        setFilterParams({
            ...(tanggal_mulai && {tanggal_mulai}),
            ...(tanggal_selesai && {tanggal_selesai}),
            karyawan: karyawan?.value,
        })
    },[tanggal, karyawan, setFilterParams])

    useEffect(() => {
        handleRequestKaryawan()
    },[])

    return (
        <section
            style={{
                width: '75%',
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                gap: '1rem',
            }}
        >
            <FormInput
              name="karyawan"
              inputType="select"
              label="Nama Karyawan"
              errors={errors}
              control={control}
              register={register}
              options={karyawanOptions}
              placeholder="Pilih Karyawan"
            />

            <FormInput
                label="Tanggal"
                name="tanggal"
                inputType="date"
                errors={errors}
                control={control}
                placeholder="Pilih waktu"
                errorMessage="Waktu tidak boleh kosong!"
                options={{
                    mode: 'range'
                }}
                isClearable={true}
            />
        </section>
    )
}

export default FilterPerizinan;